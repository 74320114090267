import { Flex } from '@chakra-ui/react';
import React from 'react';
import { Speech } from './components';

const ResourceToolbar: React.FC = () => {
  return (
    <Flex
      w="full"
      justify="flex-end"
      backgroundColor="white"
      px={6}
      py={2}
      borderLeft="1px solid #F0F1F3"
      borderRight="1px solid #F0F1F3"
      boxShadow="pane"
    >
      <Speech />
    </Flex>
  );
};

export default ResourceToolbar;
