import {
  Badge,
  Box,
  Flex,
  MenuDivider,
  MenuItem,
  Radio,
  Text,
  VStack,
} from '@chakra-ui/react';
import { isEmpty } from 'lodash-es';
import React, { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { routes as adminRoutes } from '@lon/admin/configs';
import { isDevMode } from '@lon/shared/constants';
import { FormMeta, WorkingLocation } from '@lon/shared/contexts';
import { ApplicationEnum } from '@lon/shared/enums';
import { useAcl, useAuth } from '@lon/shared/hooks';
import {
  RoleCodeEnum,
  useGetActiveContentGroupsWithSchoolsQuery,
  useGetSchoolsQuery,
} from '@lon/shared/requests';
import { AclApplication } from '@lon/shared/requests';
import { setLastApplicationByUserId } from '@lon/shared/utils';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { resetPlannerData, routes as suitRoutes } from '@lon/suit/configs';
import { types, utils } from './duck';
import { Skeleton } from './components';

const excludedContentApplications = [
  ApplicationEnum.ADMIN,
  ApplicationEnum.PLATFORM_ADMIN,
  ApplicationEnum.CAMPUS_LEADER_SUIT,
  ApplicationEnum.CAMPUS_CURRICULUM_LEADER_SUIT,
  ApplicationEnum.DISTRICT_CURRICULUM_LEADER_SUIT,
];

const ApplicationSwitcher: React.FC<types.ApplicationSwitcherProps> = ({
  activeItemRef,
  setHasApplications,
}) => {
  const [{ user }] = useAuth();
  const {
    application: currentApplication,
    currentSchoolId,
    setCurrentSchoolId,
  } = useContext(WorkingLocation);
  const { needFormPrompt } = useContext(FormMeta);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const badges = utils.getBadges({
    t,
    userType: user.type,
  });
  const dispatch = useDispatch();
  const aclApplications = useAcl<AclApplication[]>();

  const {
    data: activeContentGroupsWithSchools,
    loading: activeContentGroupsWithSchoolsLoading,
  } = useGetActiveContentGroupsWithSchoolsQuery();

  const value = [
    ApplicationEnum.ADMIN,
    ApplicationEnum.PLATFORM_ADMIN,
    ApplicationEnum.CAMPUS_LEADER_SUIT,
    ApplicationEnum.CAMPUS_CURRICULUM_LEADER_SUIT,
    ApplicationEnum.DISTRICT_CURRICULUM_LEADER_SUIT,
    ApplicationEnum.MATHNATION_SUIT,
  ].includes(currentApplication as ApplicationEnum)
    ? currentApplication
    : currentSchoolId;

  const skip = !user?.schoolIds;
  const { loading, data } = useGetSchoolsQuery({
    skip,
    variables: {
      id_list: user?.schoolIds,
    },
  });

  // TODO Temporary solution for MathNation 4.0
  const isAvailableMathNation =
    user?.availableSystems?.includes('MathNation4_0');

  const applications = useMemo(
    () =>
      aclApplications?.reduce<
        {
          value: string;
          label: string;
          application: ApplicationEnum;
        }[]
      >((acc, application) => {
        if (!application.name) {
          return acc;
        }
        const alreadyExist = acc.some(
          (app) => app.application === application?.name
        );

        if (alreadyExist) {
          return acc;
        }

        const hasMathnationInAcl = [ApplicationEnum.MATHNATION_SUIT].includes(
          application?.name as ApplicationEnum
        );

        if (isAvailableMathNation && hasMathnationInAcl) {
          return [
            ...acc,
            {
              value: application.name,
              label: t(`applicationSwitcher.${application.name}`),
              application: application?.name as ApplicationEnum,
            },
          ];
        } else if (!isAvailableMathNation && hasMathnationInAcl) {
          return acc;
        }

        if (
          !isEmpty(application?.schools) &&
          (application?.name === ApplicationEnum.TEACHER_SUIT ||
            application?.name === ApplicationEnum.STUDENT_SUIT)
        ) {
          const prepareSchools = application.schools.reduce<
            {
              label: string;
              value: string;
              application: ApplicationEnum;
            }[]
          >((acc, applicationSchool) => {
            const currentSchools = applicationSchool.schoolIds
              .map((schoolId) => {
                const currentSchool = data?.schools?.collection?.find(
                  (school) => school?._id === schoolId
                );

                return {
                  label: currentSchool?.globalName || '',
                  value: currentSchool?._id || '',
                  application: application?.name as ApplicationEnum,
                };
              })
              .filter(Boolean);

            return [...acc, ...currentSchools];
          }, []);

          return [...acc, ...prepareSchools];
        }
        return [
          ...acc,
          {
            value: application.name,
            label: t(`applicationSwitcher.${application.name}`),
            application: application?.name as ApplicationEnum,
          },
        ];
      }, []),
    [aclApplications, data, isAvailableMathNation]
  );

  useEffect(() => {
    setHasApplications?.(applications.length !== 0);
  }, [applications]);

  if (skip) {
    return null;
  }

  const resetReduxStore = () => {
    dispatch(resetPlannerData());
  };

  const handleChange = (value: string, schoolId?: string) => {
    const { protocol, hostname } = window.location;
    resetReduxStore();
    const selectedApplication = applications?.find(
      (application) => application.value === value
    );

    const currentApplication = selectedApplication?.application;

    if (currentApplication) {
      setLastApplicationByUserId({
        application: currentApplication,
        userId: user.userId,
      });
    }

    if (
      [ApplicationEnum.STUDENT_SUIT, ApplicationEnum.TEACHER_SUIT].includes(
        currentApplication as ApplicationEnum
      ) &&
      ![
        ApplicationEnum.ADMIN,
        ApplicationEnum.PLATFORM_ADMIN,
        ApplicationEnum.CAMPUS_LEADER_SUIT,
        ApplicationEnum.CAMPUS_CURRICULUM_LEADER_SUIT,
        ApplicationEnum.DISTRICT_CURRICULUM_LEADER_SUIT,
      ].includes(value as ApplicationEnum)
    ) {
      if (needFormPrompt.current) {
        navigate('/', {
          state: {
            afterNavigateFn: () => {
              setCurrentSchoolId(value);
            },
          },
        });
      } else {
        setCurrentSchoolId(value);
        navigate('/');
      }
    }

    switch (selectedApplication?.application) {
      case ApplicationEnum.ADMIN: {
        window.location.href = isDevMode()
          ? process.env['NX_ADMIN_URL'] || `${protocol}//${hostname}:4203/admin`
          : adminRoutes.root();
        return;
      }
      case ApplicationEnum.PLATFORM_ADMIN: {
        window.location.href = isDevMode()
          ? process.env['NX_PLATFORM_ADMIN_URL'] ||
            `${protocol}//${hostname}:4207/platform-admin`
          : '/platform-admin';
        return;
      }
      case ApplicationEnum.CAMPUS_LEADER_SUIT: {
        window.location.href = isDevMode()
          ? process.env['NX_SUIT_URL'] || `${protocol}//${hostname}:4200/suite`
          : suitRoutes.root();
        return;
      }
      case ApplicationEnum.CAMPUS_CURRICULUM_LEADER_SUIT: {
        window.location.href = isDevMode()
          ? process.env['NX_SUIT_URL'] || `${protocol}//${hostname}:4200/suite`
          : suitRoutes.root();
        return;
      }
      case ApplicationEnum.DISTRICT_CURRICULUM_LEADER_SUIT: {
        window.location.href = isDevMode()
          ? process.env['NX_SUIT_URL'] || `${protocol}//${hostname}:4200/suite`
          : suitRoutes.root();
        return;
      }
      case ApplicationEnum.TEACHER_SUIT: {
        setCurrentSchoolId(value);
        window.location.href = isDevMode()
          ? process.env['NX_SUIT_URL'] || `${protocol}//${hostname}:4200/suite`
          : suitRoutes.root();
        return;
      }
      case ApplicationEnum.STUDENT_SUIT: {
        setCurrentSchoolId(value);
        window.location.href = isDevMode()
          ? process.env['NX_SUIT_URL'] || `${protocol}//${hostname}:4200/suite`
          : suitRoutes.root();
        return;
      }
      case ApplicationEnum.MATHNATION_SUIT: {
        setCurrentSchoolId(schoolId ?? value);

        const isDashboardAccess = user.userRoles
          ? user.userRoles.some(
              ({ code }) =>
                code === RoleCodeEnum.SystemAdmin ||
                code === RoleCodeEnum.CampusLeader ||
                code === RoleCodeEnum.DistrictAdmin ||
                code === RoleCodeEnum.SchoolAdmin
            )
          : false;
        const path = `/mathnation${isDashboardAccess ? '/dashboard' : ''}`;
        const devPath =
          process.env['NX_MATHNATION_URL'] ||
          `${protocol}//${hostname}:4206${path}`;

        window.location.href = isDevMode() ? devPath : path;
        return;
      }
      default:
        return;
    }
  };

  const filterAclApplication = applications.filter(
    (x) =>
      x.application === ApplicationEnum.TEACHER_SUIT ||
      x.application === ApplicationEnum.STUDENT_SUIT
  );

  const uniqueAclApplication = [
    ...new Map(
      filterAclApplication.map((item) => [item['value'], item])
    ).values(),
  ];

  const checkSchoolHasContentGroups = (schoolId: string, isMn = false) => {
    const activeGroupsForSchool =
      activeContentGroupsWithSchools?.activeContentGroupsWithSchools?.find(
        (group) => group.schoolId === schoolId
      );

    if (!activeGroupsForSchool) {
      return false;
    }

    return activeGroupsForSchool.contentGroups?.some((activeGroup) =>
      isMn ? activeGroup.startsWith('MN-') : !activeGroup.startsWith('MN-')
    );
  };

  return (
    <>
      <Box py={2} color="primary.800">
        <Text variant="n1" px={6} mb={4}>
          {t('applicationSwitcher.yourLocation')}
        </Text>
        {loading || activeContentGroupsWithSchoolsLoading ? (
          <Skeleton amount={4} />
        ) : (
          applications?.map((application, appIndex) => {
            if (application.application === ApplicationEnum.MATHNATION_SUIT) {
              return uniqueAclApplication.map((uniqueData, index) => {
                if (!checkSchoolHasContentGroups(uniqueData.value, true)) {
                  return null;
                }

                return (
                  <MenuItem
                    key={
                      application.value +
                      application.application +
                      uniqueData.value +
                      index
                    }
                    ref={
                      currentApplication === ApplicationEnum.MATHNATION_SUIT &&
                      currentSchoolId === uniqueData.value
                        ? activeItemRef
                        : undefined
                    }
                    p={0}
                    onClick={() =>
                      handleChange(application.value, uniqueData.value)
                    }
                  >
                    <VStack
                      px={6}
                      paddingTop={2}
                      paddingBottom="0.75rem"
                      alignItems="flex-start"
                      bg={
                        currentApplication ===
                          ApplicationEnum.MATHNATION_SUIT &&
                        currentSchoolId === uniqueData.value
                          ? 'gray.25'
                          : 'transparent'
                      }
                      flex={1}
                    >
                      <Radio
                        isChecked={
                          currentApplication ===
                            ApplicationEnum.MATHNATION_SUIT &&
                          currentSchoolId === uniqueData.value
                        }
                        spacing={4}
                      >
                        <Text variant="s2">{application.label}</Text>
                        <Text variant="s2">{uniqueData.label}</Text>
                      </Radio>
                      <Flex px={8}>
                        <Badge
                          variant={
                            (badges as any)[application.application]?.variant
                          }
                          size="xs"
                        >
                          {(badges as any)[application.application]?.name}
                        </Badge>
                      </Flex>
                    </VStack>
                  </MenuItem>
                );
              });
            } else {
              if (
                !excludedContentApplications.includes(
                  application.value as ApplicationEnum
                ) &&
                !checkSchoolHasContentGroups(application.value)
              ) {
                return null;
              }

              return (
                <MenuItem
                  key={application.value + application.application + appIndex}
                  ref={value === application.value ? activeItemRef : undefined}
                  p={0}
                  onClick={() => handleChange(application.value)}
                >
                  <VStack
                    px={6}
                    paddingTop={2}
                    paddingBottom="0.75rem"
                    alignItems="flex-start"
                    bg={value === application.value ? 'gray.25' : 'transparent'}
                    flex={1}
                  >
                    <Radio isChecked={value === application.value} spacing={4}>
                      <Text variant="s2">{application?.label}</Text>
                    </Radio>
                    <Flex
                      px={8}
                      direction="column"
                      alignItems="flex-start"
                      gap={1}
                    >
                      <Badge
                        variant={
                          (badges as any)[application.application]?.variant
                        }
                        size="xs"
                      >
                        {(badges as any)[application.application]?.name}
                      </Badge>
                    </Flex>
                  </VStack>
                </MenuItem>
              );
            }
          })
        )}
      </Box>
      <MenuDivider />
    </>
  );
};

export default ApplicationSwitcher;
