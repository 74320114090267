import { GradeTag } from '../grade-tag';
import { AiOutlineMinus } from '@react-icons/all-files/ai/AiOutlineMinus';
import { HiCheck } from '@react-icons/all-files/hi/HiCheck';
import React, { FC } from 'react';
import { useGetTheme } from '@lon/shared/hooks';
import { getThemeStyleValue } from '@lon/shared/utils';
import { Props } from './duck/types';

const StudentAssignmentGrade: FC<Props> = ({
  grade = 0,
  submittedAt,
  completedAt,
  isGraded,
}) => {
  const currentTheme = useGetTheme();
  const isCompleted = !!submittedAt && !!completedAt;

  switch (true) {
    case !isCompleted || !isGraded:
      return (
        <GradeTag
          backgroundColor="primary.30"
          borderColor="primary.50"
          color="primary.800"
          w="77px"
          minH="44px"
        >
          <AiOutlineMinus fontSize="1.25rem" />
        </GradeTag>
      );
    case isCompleted && grade >= 90:
      return (
        <GradeTag
          backgroundColor={getThemeStyleValue(
            'green.light',
            'teal.500'
          )(currentTheme)}
          borderColor={getThemeStyleValue(
            'green.extraLight',
            'teal.500'
          )(currentTheme)}
          color={getThemeStyleValue(
            'olive.100',
            'secondary.1000'
          )(currentTheme)}
          w="77px"
          minH="44px"
        >
          {grade}
        </GradeTag>
      );
    case isCompleted && grade >= 80:
      return (
        <GradeTag
          backgroundColor={getThemeStyleValue(
            'warning.extraLight',
            'yellow.hc'
          )(currentTheme)}
          borderColor={getThemeStyleValue(
            'warning.light',
            'yellow.hc'
          )(currentTheme)}
          color={getThemeStyleValue(
            'warning.text',
            'secondary.1000'
          )(currentTheme)}
          w="77px"
          minH="44px"
        >
          {grade}
        </GradeTag>
      );
    case isCompleted && grade >= 70:
      return (
        <GradeTag
          backgroundColor={getThemeStyleValue(
            'orange.extraLight',
            'orange.600'
          )(currentTheme)}
          borderColor={getThemeStyleValue(
            'orange.300',
            'orange.600'
          )(currentTheme)}
          color={getThemeStyleValue(
            'orange.900',
            'secondary.1000'
          )(currentTheme)}
          w="77px"
          minH="44px"
        >
          {grade}
        </GradeTag>
      );
    case isCompleted && grade < 70:
      return (
        <GradeTag
          backgroundColor={getThemeStyleValue(
            'danger.extraLight',
            'fuchsia.400'
          )(currentTheme)}
          borderColor={getThemeStyleValue(
            'danger.1',
            'fuchsia.400'
          )(currentTheme)}
          color={getThemeStyleValue(
            'danger.text',
            'secondary.1000'
          )(currentTheme)}
          w="77px"
          minH="44px"
        >
          {grade}
        </GradeTag>
      );
    case isCompleted:
      return (
        <GradeTag
          backgroundColor="primary.30"
          borderColor="primary.50"
          color="primary.800"
          w="77px"
          minH="44px"
        >
          <HiCheck fontSize="1.25rem" />
        </GradeTag>
      );

    default:
      return (
        <GradeTag
          backgroundColor="primary.30"
          borderColor="primary.50"
          color="primary.800"
          w="77px"
          minH="44px"
        >
          <AiOutlineMinus fontSize="1.25rem" />
        </GradeTag>
      );
  }
};

export default StudentAssignmentGrade;
