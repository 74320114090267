import {
  Box,
  Link as ChakraLink,
  Flex,
  IconButton,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import clsx from 'clsx';
import { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@lon/shared/components';
import { Link } from '@lon/shared/components';
import {
  CHILD_SITE_HEADER_HEIGHT,
  SITE_HEADER_HEIGHT,
  SITE_HEADER_Z_INDEX_BASE,
  SITE_HEADER_Z_INDEX_MD,
} from '@lon/shared/constants';
import { StudentGradeContext } from '@lon/shared/contexts';
import { useAuth, useThemeStyleValue } from '@lon/shared/hooks';
import { UserNav } from '@lon/shared/modules/user-nav';
import { routes as appRoutes } from '@lon/suit/configs';
import { MainMenu } from './components';
import {
  HAMBURGER_BUTTON_ID,
  LOGO_WRAPPER_WIDTH,
  MENU_TOGGLE_BUTTON_AND_LOGO_GAP,
  SKIP_LINK_ID,
} from './duck/constants';
import styles from './Header.module.css';

const Header = () => {
  const { t } = useTranslation();
  const [auth] = useAuth();
  const {
    isOpen: isMenuOpen,
    onOpen: onMenuOpen,
    onClose: onMenuClose,
  } = useDisclosure();
  const { isStudentBelowK3 } = useContext(StudentGradeContext);
  const [isHamburgerMenu, setIsHamburgerMenu] = useState(false);
  const toggteMenuButtonRef = useRef<HTMLButtonElement>(null);
  const logoWrapperRef = useRef<HTMLDivElement>(null);
  const toggteMenuButtonLabel = isMenuOpen
    ? t('header.hideApplicationMenu')
    : t('header.showApplicationMenu');

  return (
    <Box
      as="header"
      id="mainHeader"
      className={styles.wrapper}
      boxShadow={{ base: 'none', b1281: '2xl' }}
      zIndex={{
        base: SITE_HEADER_Z_INDEX_BASE,
        md: SITE_HEADER_Z_INDEX_MD,
      }}
      bgColor={useThemeStyleValue('white', 'secondary.1000')}
      borderBottom={useThemeStyleValue('unset', {
        b1281: '1px solid var(--chakra-colors-white)',
      })}
    >
      <Flex
        className={styles.content}
        height={
          isStudentBelowK3 ? CHILD_SITE_HEADER_HEIGHT : SITE_HEADER_HEIGHT
        }
        px={8}
        position="relative"
      >
        <Flex
          flexDirection="row-reverse"
          alignItems="center"
          gap={MENU_TOGGLE_BUTTON_AND_LOGO_GAP}
        >
          <Box
            ref={logoWrapperRef}
            pt={isHamburgerMenu ? '2px' : 0}
            w={`${LOGO_WRAPPER_WIDTH}px`}
          >
            <Tooltip
              variant="dark"
              aria-hidden={true}
              label={t('header.openHome')}
            >
              <ChakraLink
                as={Link}
                to={appRoutes.home}
                className={styles.logo}
                aria-label={t('header.backToDashboard')}
                _focusVisible={{
                  boxShadow: useThemeStyleValue(
                    '0 0 0 4px var(--calendar-colors-sky-500)',
                    '0 0 0 4px var(--chakra-colors-teal-500)'
                  ),
                }}
              >
                <Icon
                  name={useThemeStyleValue(
                    isHamburgerMenu
                      ? 'stemscopes-logo-lite'
                      : 'stemscopes-logo',
                    isHamburgerMenu
                      ? 'stemscopes-logo-lite-contrast'
                      : 'stemscopes-logo-contrast'
                  )}
                  data-testid="Logo"
                  className={clsx({
                    [styles.logoImage]: !isHamburgerMenu,
                    [styles.mobileLogoImage]: isHamburgerMenu,
                  })}
                  aria-hidden={undefined}
                  role="img"
                />
              </ChakraLink>
            </Tooltip>
            <ChakraLink
              id={SKIP_LINK_ID}
              href={`${window.location.href.replace('#main', '')}#main`}
              className={styles.skipLink}
              _focusVisible={{
                transform: 'translateY(0)',
                outlineColor: 'transparent',
                outlineStyle: 'solid',
                boxShadow: useThemeStyleValue(
                  '0 0 0 4px var(--calendar-colors-sky-500)',
                  '0 0 0 4px var(--chakra-colors-teal-500)'
                ),
              }}
            >
              <Text as="span" variant="s1">
                {t('header.skipToContent')}
              </Text>
            </ChakraLink>
          </Box>
          {isHamburgerMenu && (
            <Tooltip
              variant="dark"
              aria-hidden={true}
              label={toggteMenuButtonLabel}
            >
              <IconButton
                ref={toggteMenuButtonRef}
                id={HAMBURGER_BUTTON_ID}
                variant="toggleMenuButton"
                aria-label={toggteMenuButtonLabel}
                aria-expanded={isMenuOpen}
                h="40px"
                minH="40px"
                icon={
                  <Icon
                    name={
                      isMenuOpen ? 'close-outlined' : 'hamburger-menu-outlined'
                    }
                  />
                }
                onClick={isMenuOpen ? onMenuClose : onMenuOpen}
                onBlur={(e) => {
                  const previousElementId = SKIP_LINK_ID;

                  if (isMenuOpen && e.relatedTarget?.id === previousElementId) {
                    onMenuClose();
                  }
                }}
              />
            </Tooltip>
          )}
        </Flex>
        <MainMenu
          isOpen={isMenuOpen}
          onClose={onMenuClose}
          isHamburgerMenu={isHamburgerMenu}
          setIsHamburgerMenu={setIsHamburgerMenu}
          toggteMenuButtonRef={toggteMenuButtonRef}
          logoWrapperRef={logoWrapperRef}
        />
        <UserNav
          firstName={auth?.user?.firstName}
          lastName={auth?.user?.lastName}
          hasLogout={!auth?.user?.impersonated}
          hasApplicationSwitcher
          hasLegalNotices
        />
      </Flex>
    </Box>
  );
};

export default Header;
