import { useEffect } from 'react';

export const useScrollToVideo = () => {
  useEffect(() => {
    let fullscreenChangeHandler: (() => void) | null = null;

    const handleMessage = (message: any) => {
      if (message?.data?.type === 'postPageScrollTop') {
        if (fullscreenChangeHandler) {
          window.removeEventListener(
            'fullscreenchange',
            fullscreenChangeHandler
          );
          window.removeEventListener(
            'webkitfullscreenchange',
            fullscreenChangeHandler
          );
          window.removeEventListener(
            'mozfullscreenchange',
            fullscreenChangeHandler
          );
          window.removeEventListener(
            'MSFullscreenChange',
            fullscreenChangeHandler
          );
        }

        fullscreenChangeHandler = () => {
          if (!document.fullscreenElement) {
            const content = document.querySelector(`.page-content`);
            content?.scrollTo(0, message.data.payload?.pageScrollTop || 0);
          }
        };

        window.addEventListener('fullscreenchange', fullscreenChangeHandler);
        window.addEventListener(
          'webkitfullscreenchange',
          fullscreenChangeHandler
        );
        window.addEventListener('mozfullscreenchange', fullscreenChangeHandler);
        window.addEventListener('MSFullscreenChange', fullscreenChangeHandler);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage, false);

      if (fullscreenChangeHandler) {
        window.removeEventListener('fullscreenchange', fullscreenChangeHandler);
        window.removeEventListener(
          'webkitfullscreenchange',
          fullscreenChangeHandler
        );
        window.removeEventListener(
          'mozfullscreenchange',
          fullscreenChangeHandler
        );
        window.removeEventListener(
          'MSFullscreenChange',
          fullscreenChangeHandler
        );
      }
    };
  }, []);
};
