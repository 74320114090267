import { Box, Flex } from '@chakra-ui/react';
import React, { FC, useEffect, useRef } from 'react';
import { FixedSizeList as _FixedSizeList } from 'react-window';

const FixedSizeList = _FixedSizeList as any;

const MenuList: FC<any> = (props) => {
  const listRef = useRef(null);

  useEffect(() => {
    if (props?.children?.length) {
      const idx = props.children?.findIndex(
        (option: any) =>
          option?.props?.data?.value === props.focusedOption?.value
      );

      (listRef.current as any)?.scrollToItem(idx);
    }
  }, [props.focusedOption]);

  return (
    <Box
      py={1}
      sx={{
        '& > div': {
          paddingTop: '0.25rem',
          paddingBottom: '0.25rem',
          '::-webkit-scrollbar': {
            backgroundColor: 'rgba(30, 65, 118, 0.1)',
            width: '6px',
          },
          '::-webkit-scrollbar-track': {
            width: '6px',
          },
          '::-webkit-scrollbar-thumb': {
            background: 'var(--chakra-colors-primary-200)',
            height: '135px',
          },
        },
      }}
    >
      <FixedSizeList
        height={300}
        itemCount={props.children.length}
        itemSize={40}
        width="auto"
        ref={listRef}
      >
        {({ index, style }: any) => {
          const item = props?.children[index]?.props?.data;
          const isFocused = item?.value === props?.focusedOption?.value;

          return (
            <Flex
              style={style}
              cursor="default"
              display="block"
              fontSize="1rem"
              w="full"
              userSelect="none"
              color="primary.800"
              px={3}
              py={2}
              boxSizing="border-box"
              justify="center"
              align="center"
              backgroundColor={isFocused ? 'blue.50' : 'white'}
              _hover={{
                backgroundColor: 'blue.50',
              }}
              onMouseMove={() => {
                props.selectRef.current?.setState({ focusedOption: item });
              }}
              onClick={() => {
                props.selectOption(item);
              }}
            >
              {item?.label}
            </Flex>
          );
        }}
      </FixedSizeList>
    </Box>
  );
};

export default MenuList;
