import { StudentAssignmentStatusTransformed } from '@lon/shared/enums';
import { ShareResults } from '@lon/shared/requests';

export const isAssignnmentUnavailable = (
  transformedStatus: StudentAssignmentStatusTransformed | null,
  shareResults?: string | null,
  isArchived?: boolean | null
) =>
  (transformedStatus === StudentAssignmentStatusTransformed.Graded &&
    shareResults === ShareResults.Disabled) ||
  transformedStatus === StudentAssignmentStatusTransformed.ToBeGraded ||
  (isArchived &&
    !(
      transformedStatus === StudentAssignmentStatusTransformed.Graded &&
      shareResults !== ShareResults.Disabled
    ));
