import { Asterisk } from '../../asterisk';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react';
import React from 'react';
import { inputLayoutTypes } from './duck';

const InputLayout: React.FC<inputLayoutTypes.InputLayoutProps> = ({
  id,
  label,
  input,
  errorMessage,
  isRequired,
  formLabelProps,
  variant = 'base',
  rightLabel,
  ...props
}) => {
  return (
    <FormControl
      id={id}
      isInvalid={!!errorMessage}
      isRequired={isRequired}
      variant={props.isDisabled ? 'disabled' : variant || 'base'}
      {...props}
    >
      {(rightLabel || label) && (
        <Flex
          justifyContent="space-between"
          marginBottom={2}
          alignItems="center"
        >
          {label && (
            <FormLabel
              marginBottom="0px !important"
              lineHeight="1.625rem !important"
              width="full"
              requiredIndicator={<Asterisk />}
              {...formLabelProps}
            >
              {label}
            </FormLabel>
          )}
          {rightLabel}
        </Flex>
      )}

      {input}
      <FormErrorMessage
        color={
          variant === 'dark' && !props.isDisabled ? 'orange.400' : 'danger.300'
        }
        whiteSpace="pre-wrap"
      >
        {errorMessage}
      </FormErrorMessage>
    </FormControl>
  );
};

export default InputLayout;
