import { Text } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Icon, TextInput } from '@lon/shared/components';
import { useQueryParams, useThemeStyleValue } from '@lon/shared/hooks';

const LiveSearch: React.FC<{
  labelColor?: string;
}> = ({ labelColor = 'white' }) => {
  const [{ search, pagination, ...params }, setParams] = useQueryParams();
  const { t } = useTranslation();
  const formProviderProps = useForm();

  const handleSearch = (value: string) => {
    const initialPagination = pagination && { ...pagination, page: 1 };

    setParams({
      ...params,
      search: value || undefined,
      ...(initialPagination ? { pagination: initialPagination } : {}),
    });
  };

  return (
    <>
      <FormProvider {...formProviderProps}>
        <TextInput
          w="full"
          data-testid="live-search"
          type="search"
          name="search"
          _placeholder={{
            fontStyle: 'italic',
          }}
          _placeholderShown={{ textOverflow: 'ellipsis' }}
          label={
            <Text variant="n1" color={labelColor}>
              {t('searchPanel.searchLabel')}
            </Text>
          }
          placeholder={t('searchPanel.searchPlaceholder')}
          inputLeftElementProps={{
            children: <Icon name="search-outlined" size="medium" />,
            zIndex: '2',
            color: useThemeStyleValue('primary.400', 'secondary.1000'),
            top: '2px',
          }}
          sx={{
            _focus: {
              boxShadow: useThemeStyleValue('focus.blue', 'focus.teal'),
              borderColor: useThemeStyleValue('blue.100', 'secondary.1000'),
            },
          }}
          value={search || ''}
          onChange={(event) => {
            handleSearch(event.target.value);
          }}
        />
      </FormProvider>
    </>
  );
};

export default LiveSearch;
