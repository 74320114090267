import { TFunction } from 'react-i18next';
import { ApplicationEnum } from '@lon/shared/enums';

export const getBadges = ({
  t,
  userType,
}: {
  t: TFunction;
  userType: string;
}) => ({
  [ApplicationEnum.TEACHER_SUIT]: {
    name: t('applicationSwitcher.teacher'),
    variant: 'greenBadge',
  },
  [ApplicationEnum.ADMIN]: {
    name: t(`applicationSwitcher.admin`),
    variant: 'adminBadge',
  },
  [ApplicationEnum.PLATFORM_ADMIN]: {
    name: t('applicationSwitcher.platformAdmin'),
    variant: 'adminBadge',
  },
  [ApplicationEnum.STUDENT_SUIT]: {
    name: t('applicationSwitcher.student'),
    variant: 'studentBadge',
  },
  [ApplicationEnum.CAMPUS_LEADER_SUIT]: {
    name: t('applicationSwitcher.campusLeader'),
    variant: 'studentBadge',
  },
  [ApplicationEnum.DISTRICT_CURRICULUM_LEADER_SUIT]: {
    name: t('applicationSwitcher.districtCurriculumLeader'),
    variant: 'studentBadge',
  },
  [ApplicationEnum.CAMPUS_CURRICULUM_LEADER_SUIT]: {
    name: t('applicationSwitcher.campusCurriculumLeader'),
    variant: 'studentBadge',
  },
  [ApplicationEnum.MATHNATION_SUIT]: {
    name:
      userType === 'student'
        ? t('applicationSwitcher.student')
        : t('applicationSwitcher.teacher'),
    variant: userType === 'student' ? 'studentBadge' : 'greenBadge',
  },
});
