import { t } from 'i18next';
import { array, object, string } from 'yup';

export const validationSchema = object({
  courses: array().of(
    object().shape({
      name: string().required(
        t('validationMessages.requiredField', {
          fieldName: 'Course Name',
        })
      ),
      gradeLevel: string().required(
        t('validationMessages.requiredField', {
          fieldName: 'Grade Level',
        })
      ),
      curriculumArea: string().required(
        t('validationMessages.requiredField', {
          fieldName: 'Curriculum Area',
        })
      ),
    })
  ),
});

export const getInitialData = (data?: any) => {
  if (!data?.courses?.length) {
    return {
      courses: [],
    };
  }

  return {
    courses: data?.courses,
  };
};
export const getValidation = (data?: any) => ({
  validationSchema,
  defaultValues: getInitialData(data),
});
