import { Badge, Link as ChakraLink, Divider, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { generatePath } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { getQuery } from '@lon/api';
import { Icon } from '@lon/shared/components';
import { useQueryParams } from '@lon/shared/hooks';
import { routes } from '@lon/suit/configs';
import { studentView } from '@lon/suit/constants';
import { JsonSectionStructure, JsonViewStructure } from '@lon/suit/contexts';

interface SectionCardProps extends Omit<JsonSectionStructure, 'elements'> {
  views: JsonViewStructure[];
}
const SectionCard: React.FC<SectionCardProps> = ({
  title,
  views,
  identifier: sectionId,
}) => {
  const queryParams = useQueryParams();
  const params = useParams();
  const { scopeId, projectId } = params as {
    scopeId: string;
    projectId: string;
  };

  return (
    <Flex
      direction="column"
      py={4}
      boxShadow="0px 5px 20px -2px rgba(43, 54, 70, 0.07)"
      color="primary.800"
      borderRadius="6px"
    >
      <Flex py={4} px={8} alignItems="center" gap={6}>
        <Icon name="share-results-eye" />
        <Text variant="h6">{title}</Text>
      </Flex>
      <Divider />
      {
        <Flex direction="column" px={8} gap={4}>
          {views
            ?.filter((view) => !!view?.identifier)
            ?.map((view) => (
              <Flex alignItems="center" gap={4} key={view.identifier}>
                <ChakraLink
                  as={Link}
                  variant="primary"
                  to={`${generatePath(routes.lti.scopes.activity, {
                    scopeId,
                    elementId: view?.elementId,
                    sectionId,
                    projectId,
                  })}?${
                    view?.isStudentView
                      ? getQuery({
                          ...queryParams,
                          type: studentView,
                          studentElementId: view.identifier,
                        })
                      : ''
                  }`}
                >
                  {view?.title}
                </ChakraLink>
                {view?.isStudentView && (
                  <Badge size="xs" variant="greenBadge">
                    ST
                  </Badge>
                )}
              </Flex>
            ))}
        </Flex>
      }
    </Flex>
  );
};

export default SectionCard;
