import { AssignmentFormContext } from '../../../../../../duck/context';
import { NotebookLocationSelect } from '../../../../components/notebook-location-select';
import { useContext, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormSelect } from '@lon/shared/components';
import { useGetClassForAssignmentFormQuery } from '@lon/shared/requests';
import { types } from './duck';

const ClassInput: React.FC<types.ClassInputProps> = ({
  classesOptions,
  classesLoading,
  ...props
}) => {
  const { t } = useTranslation();
  const { withOtherAssignment, isTeacherAssessment } = useContext(
    AssignmentFormContext
  );
  const selectedClasses = useWatch({ name: 'classes' });
  const classId = selectedClasses[props.index]?.classId;

  const { data: classData, loading: classLoading } =
    useGetClassForAssignmentFormQuery({
      variables: {
        classId,
      },
      skip: !classId || !withOtherAssignment || isTeacherAssessment,
    });

  const options = useMemo(() => {
    const transformedSelectedClasses = selectedClasses.map(
      ({ classId }: { classId: string }) => classId
    );
    const currentClass = classesOptions.find(({ value }) => value === classId);

    return [
      ...(currentClass ? [currentClass] : []),
      ...classesOptions.filter(
        (c) => !transformedSelectedClasses.includes(c.value)
      ),
    ];
  }, [classesOptions, selectedClasses]);

  return (
    <>
      <FormSelect
        testId={`class-${props.index}`}
        display="flex"
        lineHeight={10}
        isLoading={classesLoading}
        isRequired
        returnObject={false}
        options={options}
        menuPlacement={props.index === 0 ? 'bottom' : 'auto'}
        noOptionsMessage={() => t('createAssignmentDrawer.noClassesMessage')}
        {...(props as any)}
        name={`classes.${props.index}.classId`}
      />
      {withOtherAssignment && !isTeacherAssessment && (
        <NotebookLocationSelect
          name={`classes.${props.index}.scopeId`}
          classData={classData}
          classLoading={classLoading}
        />
      )}
    </>
  );
};

export default ClassInput;
