import {
  Box,
  Button,
  Divider,
  Flex,
  IconButton,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { AiOutlineDelete } from '@react-icons/all-files/ai/AiOutlineDelete';
import { AiOutlinePlus } from '@react-icons/all-files/ai/AiOutlinePlus';
import React, { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { types } from './duck';

export const InputsList: React.FC<types.InputsListProps> = ({
  input: Input,
  name,
  renderLabel = () => '',
  renderPlaceholder = () => '',
  mobile = false,
  defaultValue = { value: '' },
  removeButtonTitle = () => 'Remove',
  addButtonTitle = '',
  isDisabled = false,
  isInputDisabled,
  isRemoveButtonDisabled,
  noFieldMessage,
  canBeEmpty = false,
  withDivider = false,
  maxWidth,
  dividerStyles,
  addBtnTooltip = '',
  readOnly = false,
  additionalItemProps = {},
}) => {
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  useEffect(() => {
    if (fields.length === 0 && !noFieldMessage && !canBeEmpty) {
      append(defaultValue);
    }
  }, [fields]);

  return (
    <Flex gap={4} direction="column">
      {Boolean(fields.length) && (
        <Flex direction="column" gap={withDivider ? 4 : 8}>
          {fields.map((field, index) => {
            return (
              <Box key={field.id}>
                <Flex
                  width="100%"
                  {...(maxWidth && { justifyContent: 'center', px: '8' })}
                >
                  <Box
                    position="relative"
                    {...(maxWidth && { maxWidth: maxWidth })}
                    width="100%"
                  >
                    <Input
                      {...field}
                      {...additionalItemProps}
                      name={`${name}.${index}.value`}
                      index={index}
                      isDisabled={isInputDisabled}
                      placeholder={renderPlaceholder(index)}
                      label={renderLabel(index)}
                    />
                    {!readOnly && (
                      <IconButton
                        position="absolute"
                        aria-label={removeButtonTitle(index)}
                        size={mobile ? 'sm' : 'md'}
                        variant="ghost"
                        top={mobile ? -2 : 30}
                        right={mobile ? 0 : -16}
                        isDisabled={
                          isRemoveButtonDisabled
                            ? isRemoveButtonDisabled({ index })
                            : fields.length === 1
                        }
                        onClick={() => {
                          remove(index);
                          if (
                            fields.length === 1 &&
                            !noFieldMessage &&
                            !canBeEmpty
                          ) {
                            append(defaultValue);
                          }
                        }}
                      >
                        <AiOutlineDelete />
                      </IconButton>
                    )}
                  </Box>
                </Flex>
                {withDivider && (
                  <Divider orientation="horizontal" mb="0" {...dividerStyles} />
                )}
              </Box>
            );
          })}
        </Flex>
      )}
      {withDivider && fields.length === 0 && (
        <Divider orientation="horizontal" my="0" {...dividerStyles} />
      )}
      <Flex {...(maxWidth && { justifyContent: 'center', px: '8' })}>
        <Flex
          mt={2}
          justifyContent={
            !fields.length && noFieldMessage ? 'space-between' : 'flex-end'
          }
          alignItems="center"
          {...(maxWidth && { maxWidth: maxWidth })}
          width="100%"
        >
          {!fields.length && noFieldMessage && (
            <Text
              variant="s2"
              color="primary.800"
              whiteSpace="pre-wrap"
              textAlign="left"
            >
              {noFieldMessage}
            </Text>
          )}
          {!readOnly && (
            <Tooltip variant="dark" label={addBtnTooltip} aria-hidden={true}>
              <Button
                size="sm"
                variant="outline"
                leftIcon={<AiOutlinePlus />}
                isDisabled={isDisabled}
                minW="7.5rem"
                onClick={() => append(defaultValue)}
              >
                {addButtonTitle}
              </Button>
            </Tooltip>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
