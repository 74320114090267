import { paginationConstants, paginationUtils } from '../../duck';
import {
  Button,
  Drawer as ChakraDrawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  Flex,
  HStack,
  IconButton,
  NumberInput,
  NumberInputField,
  Select,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@lon/shared/components';
import { onNumberInputKeyDown, onNumberInputPaste } from '@lon/shared/utils';
import { types } from './duck';

const Drawer: React.FC<types.DrawerProps> = ({
  isOpen,
  onClose,
  currentPage,
  table,
  pageSize,
}) => {
  const { t } = useTranslation();
  const [pageNumber, setPageNumber] = useState<string | number>(currentPage);

  return (
    <ChakraDrawer
      data-testid="pagination-settings"
      isFullHeight
      isOpen={isOpen}
      placement="bottom"
      onClose={onClose}
    >
      <DrawerContent color="primary.800">
        <DrawerHeader
          display="flex"
          justifyContent="space-between"
          borderBottom="1px solid"
          borderColor="secondary.100"
        >
          <Flex align="center">
            <Text variant="n3" whiteSpace="pre-wrap">
              {t('pagination.page')}{' '}
            </Text>
            <Text variant="n1" data-testid="drawer-current-page">
              <Text as="span" className="OneLinkNoTx">
                {currentPage}
              </Text>
              &nbsp;
              <Text as="span">{t('pagination.of')}</Text>&nbsp;
              <Text as="span" className="OneLinkNoTx">
                {table.getPageCount()}
              </Text>
            </Text>
          </Flex>
          <IconButton
            aria-label={t('pagination.closePaginationSettings')}
            variant="solidDark"
            size="sm"
            onClick={onClose}
            icon={<Icon size="sm" name="close-outlined" />}
          />
        </DrawerHeader>

        <DrawerBody>
          <VStack spacing="2" mb="4" align="normal">
            <Text as="span" variant="l">
              {t('pagination.showRecords')}
            </Text>
            <Select
              iconColor="primary.200"
              borderColor="primary.200"
              borderRadius="4px"
              defaultValue={table.getState().pagination.pageSize}
              onChange={(e) => pageSize(e)}
            >
              {paginationConstants.ROW_OPTIONS.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          </VStack>
          <VStack spacing="2" align="normal">
            <Text variant="l" whiteSpace="nowrap">
              {t('pagination.goToPage')}
            </Text>
            <HStack spacing="4">
              <NumberInput
                size="md"
                w="full"
                min={1}
                max={table.getPageCount()}
                inputMode="numeric"
                onBlur={(event) => setPageNumber(event.target.value)}
                onKeyDown={onNumberInputKeyDown}
                onPaste={onNumberInputPaste}
              >
                <NumberInputField />
              </NumberInput>
              <Button
                variant="solid"
                name={t('pagination.go')}
                onClick={() =>
                  paginationUtils.validatePageNumber({
                    pageNumber: pageNumber || currentPage,
                    max: table.getPageCount(),
                    successCallback: (page: number) => {
                      table.setPageIndex(page - 1);
                      onClose();
                    },
                  })
                }
              >
                <Text textTransform="uppercase">{t('pagination.go')}</Text>
              </Button>
            </HStack>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </ChakraDrawer>
  );
};

export default Drawer;
