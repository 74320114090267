import React, { useRef } from 'react';
import { PageContent } from '@lon/shared/components';
import { isProdCluster } from '@lon/shared/constants';
import { useAuth } from '@lon/shared/hooks';
import { useQuicksightReport } from '@lon/shared/hooks';

const userReportDashboardId = `ss-user-report-${
  isProdCluster() ? 'prod' : 'dev'
}`;

const UserReport = () => {
  const dashboardRef = useRef<HTMLDivElement | null>(null);
  const [{ user }] = useAuth();

  useQuicksightReport({
    dashboardId: userReportDashboardId,
    ref: dashboardRef,
    params: {
      districtId: user.districtId,
      schools: user.schoolIds,
      userId: user.userId,
    },
    options: { height: '1140px' },
  });

  return (
    <PageContent reserveScrollbarWidth>
      <div ref={dashboardRef} />
    </PageContent>
  );
};

export default UserReport;
