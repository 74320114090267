import { Flex } from '@chakra-ui/react';
import Cookies from 'js-cookie';
import { get } from 'lodash-es';
import React, { memo, useEffect, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useQueryParams } from '@lon/shared/hooks';
import { RootState } from '@lon/suit/configs';
import { searchPanelConstants, searchPanelTypes } from './duck';
import { LiveSearch, ManualSearch } from './components';

const SearchPanel: React.FC<searchPanelTypes.SearchPanelProps> = ({
  name = '',
}) => {
  const [manualSearch, setManualSearch] = useState(false);
  const [{ search, pagination, ...params }, setParams] = useQueryParams();
  const savedSearch = useSelector(({ filters }: RootState) =>
    get(filters, `${name}.search`, '')
  );

  useLayoutEffect(() => {
    const language = Cookies.get('langPref');
    if (
      language === searchPanelConstants.SPANISH_LOCALE ||
      language === searchPanelConstants.HAITIAN_CREOLE_LOCALE
    ) {
      setManualSearch(true);
    } else {
      setManualSearch(false);
    }
  }, []);

  useEffect(() => {
    if (savedSearch) {
      setParams({
        ...params,
        search: savedSearch || undefined,
      });
    }
  }, []);

  return (
    <Flex direction="column" gap={2}>
      {manualSearch ? <ManualSearch /> : <LiveSearch />}
    </Flex>
  );
};

export default memo(SearchPanel);
