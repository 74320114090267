export const getTimeInterval = (start?: Date, end?: Date) => {
  if (start && end) {
    const time =
      start && end ? new Date(end).getTime() - new Date(start).getTime() : 0;

    let minutes = Math.floor(time / 60000);
    const hours = Math.floor(minutes / 60);
    minutes = minutes % 60;

    return `${hours ? `${hours} h ` : ' '} ${
      minutes ? (minutes < 10 ? `0${minutes}` : minutes) : '00'
    } min`;
  }

  return '-';
};
