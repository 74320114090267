import { useEffect } from 'react';
import { tableContentTypes } from './duck';
import { TBody, TFoot, THead } from './components';

const TableContent = <Data extends object>({
  table,
  onRowSelectStateChange,
  renderGroupActions,
  enableClientSidePagination,
  enableClientSideSorting,
  enableStickyColumns,
  serverSidePagination,
  serverSideSorting,
  resetRows,
  captionProps,
  headerProps,
  bodyProps,
  footerProps,
  enableVerticalBorders,
  enableRowSelection,
  hasData,
  useDefaultSortingIcon,
  innerTheadRef,
  activeRowId,
  loading,
  skeleton,
  showNoDataHeader,
  tableId,
}: tableContentTypes.TableContentProps<Data>) => {
  const selectedFlatRows = table.getSelectedRowModel().flatRows;

  useEffect(() => {
    if (onRowSelectStateChange) {
      const selectedRows = selectedFlatRows.map((row) => row.original);
      onRowSelectStateChange(selectedRows);
    }
  }, [onRowSelectStateChange, selectedFlatRows]);

  useEffect(() => {
    if (resetRows?.status) {
      table.resetRowSelection();
      resetRows.onReset();
    }
  }, [resetRows?.status]);

  return (
    <>
      {loading && skeleton}
      {(hasData || showNoDataHeader) && (
        <>
          <THead
            id={`tableHead_${tableId}`}
            table={table}
            enableClientSideSorting={enableClientSideSorting}
            serverSideSorting={serverSideSorting}
            enableStickyColumns={enableStickyColumns}
            enableVerticalBorders={enableVerticalBorders}
            useDefaultSortingIcon={useDefaultSortingIcon}
            enableRowSelection={enableRowSelection}
            innerTheadRef={innerTheadRef}
            showNoDataHeader={showNoDataHeader}
            {...headerProps}
          />
          <TBody
            id={`tableBody_${tableId}`}
            table={table}
            enableStickyColumns={enableStickyColumns}
            enableVerticalBorders={enableVerticalBorders}
            enableRowSelection={enableRowSelection}
            activeRowId={activeRowId}
            {...bodyProps}
          />
          <TFoot
            id={`tableFoot_${tableId}`}
            table={table}
            enableClientSidePagination={enableClientSidePagination}
            serverSidePagination={serverSidePagination}
            {...footerProps}
          />
        </>
      )}
    </>
  );
};

export default TableContent;
