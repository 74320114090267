import {
  Box,
  Flex,
  IconButton,
  Text,
  Tooltip,
  useMediaQuery,
} from '@chakra-ui/react';
import { IoMdMore } from '@react-icons/all-files/io/IoMdMore';
import { RiArrowLeftSLine } from '@react-icons/all-files/ri/RiArrowLeftSLine';
import { RiArrowRightSLine } from '@react-icons/all-files/ri/RiArrowRightSLine';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { sidebarSlice } from '@lon/shared/configs';
import { PAGE_HEADER_HEIGHT } from '@lon/shared/constants';
import { StudentGradeContext } from '@lon/shared/contexts';
import { useThemeStyleValue } from '@lon/shared/hooks';
import { RootState } from '@lon/suit/configs';
import { CHILD_PAGE_HEADER_HEIGHT } from '@lon/suit/constants';
import { Props } from './duck/types';

const StudentContentHeader: FC<Props> = ({
  pageTitle,
  headerElements,
  hasLeftColumnComponent = false,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isStudentBelowK3 } = React.useContext(StudentGradeContext);
  const isOpen = useSelector((state: RootState) => state.sidebar.isOpen);
  const [isUpTo1280] = useMediaQuery('(max-width: 1280px)');
  const sidebarBtnBg = useThemeStyleValue('primary.600', '#fff');

  return (
    <Flex
      zIndex={4}
      w="full"
      minH={`${
        isStudentBelowK3 ? CHILD_PAGE_HEADER_HEIGHT : PAGE_HEADER_HEIGHT
      }px`}
      px={isStudentBelowK3 ? { base: 4, b768: 6, lg: 8 } : { base: 4, md: 6 }}
      gap={4}
      backgroundColor={useThemeStyleValue('primary.800', 'secondary.1000')}
      borderBottom={useThemeStyleValue('none', '1px solid #fff')}
      align="center"
      justify="space-between"
      boxShadow="0px 2px 15px 0px #2b364659"
    >
      <Flex gap={4} align="center" overflow="hidden" h="full">
        {hasLeftColumnComponent &&
          (isUpTo1280 ? (
            <Tooltip
              variant="dark"
              aria-hidden={true}
              label={
                isOpen ? t('common.closeSidebar') : t('common.openSidebar')
              }
            >
              <IconButton
                display={{ base: 'flex', b1281: 'none' }}
                size={isStudentBelowK3 ? 'md' : 'sm'}
                variant="solid"
                sx={{
                  svg: {
                    w: isStudentBelowK3 ? '26px' : '24px',
                    h: isStudentBelowK3 ? '26px' : '24px',
                  },
                }}
                h={isStudentBelowK3 ? '44px' : '32px'}
                borderRadius="full"
                backgroundColor={sidebarBtnBg}
                aria-label={
                  isOpen ? t('common.closeSidebar') : t('common.openSidebar')
                }
                icon={<IoMdMore />}
                onClick={() => dispatch(sidebarSlice.toggleOpen())}
              />
            </Tooltip>
          ) : (
            <Tooltip
              variant="dark"
              aria-hidden={true}
              label={
                isOpen ? t('common.closeSidebar') : t('common.openSidebar')
              }
            >
              <IconButton
                display={{ base: 'none', b1281: 'flex' }}
                size={isStudentBelowK3 ? 'md' : 'sm'}
                sx={{
                  svg: {
                    w: isStudentBelowK3 ? '26px' : '24px',
                    h: isStudentBelowK3 ? '26px' : '24px',
                  },
                }}
                variant="solid"
                borderRadius="full"
                backgroundColor={sidebarBtnBg}
                aria-label={
                  isOpen ? t('common.closeSidebar') : t('common.openSidebar')
                }
                h={isStudentBelowK3 ? '44px' : '32px'}
                icon={isOpen ? <RiArrowLeftSLine /> : <RiArrowRightSLine />}
                onClick={() => dispatch(sidebarSlice.toggleOpen())}
              />
            </Tooltip>
          ))}
        <Text
          variant="h4"
          color="white"
          as="h1"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {pageTitle}
        </Text>
      </Flex>
      <Box>{headerElements}</Box>
    </Flex>
  );
};

export default StudentContentHeader;
