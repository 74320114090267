import { ScopeSkeleton } from '../../components';
import { Button, Flex, Image, Text } from '@chakra-ui/react';
import React, { useContext, useMemo, useState } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { AsyncOutlet, Icon, useToast } from '@lon/shared/components';
import { useCurrentSchoolId, useQueryParams } from '@lon/shared/hooks';
import {
  ElementViewEnum,
  useSubmitContentItemLinksMutation,
} from '@lon/shared/requests';
import { closeLtiDialog, handleError, resizeImage } from '@lon/shared/utils';
import { routes } from '@lon/suit/configs';
import { ScopeContext } from '@lon/suit/contexts';

const ScopeContainer = () => {
  const navigate = useNavigate();
  const isActivity = useMatch(routes.lti.scopes.activity);
  const [queryParams] = useQueryParams();
  const scope = useContext(ScopeContext);

  const [schoolId] = useCurrentSchoolId();
  const isStudentView = queryParams.get('type') === 'studentView';
  const viewId: string = queryParams.studentElementId || '';
  const [hasError, setHasError] = useState(false);

  const params = useParams();
  const { scopeId, sectionId, elementId, projectId } = params as {
    scopeId: string;
    sectionId: string;
    projectId: string;
    elementId: string;
  };
  const toast = useToast();

  const [submitItem] = useSubmitContentItemLinksMutation({
    variables: {
      schoolId,
      contentItems: [
        {
          scopeId,
          sectionId,
          elementId,
          projectId,
          viewId,
          elementView: isStudentView
            ? ElementViewEnum.Student
            : ElementViewEnum.Teacher,
        },
      ],
    },
  });
  const assignActivity = async () => {
    try {
      const response = await submitItem();

      if (response?.data?.submitContentItemLinks) {
        closeLtiDialog(response.data.submitContentItemLinks);
      }
    } catch (error: any) {
      handleError({ error, toast });
    }
  };

  const imageUrl = useMemo(
    () =>
      scope?.original?.image
        ? resizeImage({
            src: scope?.original?.image,
            height: 600,
            width: 800,
          })
        : '',
    [scope?.original?.image]
  );

  if (scope.loading) {
    return <ScopeSkeleton />;
  }

  return (
    <Flex direction="column">
      <Flex
        gap={8}
        p={10}
        h="220px"
        borderBottom="1px solid #F0F1F3"
        boxShadow="0px 2px 7px 0px rgba(43, 54, 70, 0.10)"
      >
        <Image
          maxW="250px"
          src={
            hasError || !imageUrl ? 'suit/assets/missing-image.png' : imageUrl
          }
          objectFit="cover"
          onError={() => {
            if (!hasError) {
              setHasError(true);
            }
          }}
          alt="scope image"
        />
        <Flex direction="column" justifyContent="space-evenly" flexGrow="1">
          <Text color="primary.800" variant="h3">
            {scope?.original?.displayName}
          </Text>

          <Flex
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
            gap={4}
          >
            <Text color="secondary.700" variant="s2">
              {scope?.original?.displayName}
            </Text>

            <Flex gap={4} flexWrap="wrap">
              {isActivity && (
                <Button
                  leftIcon={<Icon name="book-outlined" />}
                  size="sm"
                  onClick={() => navigate(-1)}
                >
                  View Sections
                </Button>
              )}
              <Button
                leftIcon={<Icon name="arr-double-left-outlined" />}
                size="sm"
                onClick={() => navigate(routes.lti.scopes.root)}
              >
                All Scopes
              </Button>
              {isActivity && isStudentView && (
                <Button
                  leftIcon={<Icon name="subscription-outlined" />}
                  size="sm"
                  variant="solid"
                  onClick={assignActivity}
                >
                  Assign Activity
                </Button>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <AsyncOutlet />
    </Flex>
  );
};

export default ScopeContainer;
