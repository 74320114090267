import { CollapsibleSidebar } from '@lon/suit/components';
import { Filters } from './components';

const Sidebar = () => {
  return (
    <CollapsibleSidebar>
      <Filters />
    </CollapsibleSidebar>
  );
};

export default Sidebar;
