import { t } from 'i18next';
import { object, ref, string } from 'yup';
import { specialCharacters } from '@lon/shared/constants';
import { getValidationMessages } from '@lon/shared/utils';


export const getPasswordValidation = (isAdmin: boolean) => {
  const min = isAdmin ? 16 : 12;

  return string()
    .required(getValidationMessages().required)
    .matches(/^(?!.* )/, getValidationMessages().noSpaces)
    .min(min, getValidationMessages(min).passwordLength)
    .matches(/\d+/, getValidationMessages().atLeastOneDigit)
    .matches(/[a-z]+/, getValidationMessages().atLeastOneLowerCase)
    .matches(
      specialCharacters,
      getValidationMessages().atLeastOneSpecialCharacter
    )
    .matches(/[A-Z]+/, getValidationMessages().atLeastOneUpperCase);
};

export const validationSchema = object({
  currentPassword: string()
    .trim()
    .required(
      t('validationMessages.requiredField', {
        fieldName: 'Current Password',
      })
    )
    .default(''),
  newPassword: getPasswordValidation(true).default(''),
  repeatedPassword: string()
    .trim()
    .required(
      t('validationMessages.requiredField', {
        fieldName: 'Confirm Password',
      })
    )
    .oneOf(
      [ref('newPassword'), null],
      getValidationMessages().notMatchingPasswords
    )
    .default(''),
});

export const getValidation = () => ({
  validationSchema,
  defaultValues: validationSchema.cast({}),
});
