import { formErrorAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(formErrorAnatomy.keys);

const baseStyle = definePartsStyle({
  text: {
    mt: 1,
    color: 'danger.300',
  },
});
const dark = definePartsStyle({
  text: {
    mt: 1,
    color: 'orange.400',
  },
});
export const FormError = defineMultiStyleConfig({
  baseStyle,
  variants: { dark },
});
