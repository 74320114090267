import { ScopesList } from '../../../teacher/scopes/components/all-scopes/components';
import {
  Box,
  Tab as ChakraTab,
  Flex,
  TabList,
  Tabs,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { Icon } from '@lon/shared/components';
import { sxLightScrollBar } from '@lon/shared/constants';
import { LiveSearch } from '@lon/suit/components';
import { routes } from '@lon/suit/configs';

const tabs = [
  { title: 'Scopes', icon: 'read-outlined' },
  { title: 'Assignment Packages', icon: 'book-outlined', isDisabled: true },
];

const Tab: React.FC<{ title: string; icon: string; isDisabled?: boolean }> = ({
  title,
  icon,
  isDisabled = false,
}) => (
  <ChakraTab gap={3} isDisabled={isDisabled}>
    <Icon name={icon} />
    <Text
      variant="bm"
      whiteSpace="nowrap"
      overflowX="hidden"
      textOverflow="ellipsis"
      display={{ base: 'none', md: 'inline-flex' }}
    >
      {title}
    </Text>
  </ChakraTab>
);

const Scopes = () => {
  return (
    <Box>
      <Tabs
        display="flex"
        flexDir="column"
        height="full"
        align="center"
        variant="drawer"
      >
        <Flex px={10} boxShadow="0px 2px 7px rgba(43, 54, 70, 0.1)">
          <TabList
            width={{ sm: '700px' }}
            gap={10}
            justifyContent={{
              base: 'space-around',
              md: 'flex-start',
            }}
          >
            {tabs.map((tab) => (
              <Tab key={tab.icon} {...tab} />
            ))}
          </TabList>
        </Flex>
      </Tabs>
      <Flex
        px={10}
        direction="column"
        sx={sxLightScrollBar}
        overflow="auto"
        height="calc(100vh - 74px)"
      >
        <Box my={8}>
          <LiveSearch labelColor="primary.800" />
        </Box>
        <ScopesList
          type="tile"
          gridGap={6}
          backgroundColor="white"
          itemPath={routes.lti.scopes.show}
        />
      </Flex>
    </Box>
  );
};

export default Scopes;
