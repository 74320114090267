import { TABLET_TAB_PANEL_PADDING } from '../../../../duck/constants';
import { TabProps } from '../../duck/types';
import { Box, Button, TabPanel, Text, Tooltip } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Icon, InputsList } from '@lon/shared/components';
import { useMediaQuery } from '@lon/shared/hooks';
import { useGetSchoolsQuery } from '@lon/shared/requests';
import { SchoolInput } from './components';

const Schools: React.FC<TabProps> = ({ isDistrictAssessment }) => {
  const { t } = useTranslation();
  const [isTablet] = useMediaQuery('(max-width: 1024px)');
  const { setValue } = useFormContext();
  const selectedSchools = useWatch({ name: 'schools' });
  const { data: schoolsData, loading: schoolsLoading } = useGetSchoolsQuery({
    skip: !isDistrictAssessment,
  });

  const schoolsOptions =
    schoolsData?.schools?.collection
      ?.map((school) => ({
        label: school?.name || '',
        value: school?._id || '',
      }))
      .sort((a, b) => {
        const first = a.label;
        const second = b.label;
        // TODO: update hardcoded 'en' to Spanish when change to Spanish is implemented
        return first.localeCompare(second, 'en', { numeric: true });
      }) || [];

  const renderSchoolsInput = useCallback(
    (props: any) => (
      <SchoolInput
        schoolsOptions={schoolsOptions}
        schoolsLoading={schoolsLoading}
        {...props}
      />
    ),
    [schoolsData]
  );

  const handleAddAllSchools = () => {
    setValue(
      'schools',
      schoolsOptions.map((school) => ({
        schoolId: school.value,
        schoolGradeLevels: [],
      }))
    );
  };

  return (
    <TabPanel pb="6" {...(isTablet && { px: TABLET_TAB_PANEL_PADDING })}>
      <Text
        variant="s3"
        color="primary.400"
        whiteSpace="pre-wrap"
        textAlign="left"
        pb="4"
        {...(!isTablet && { maxWidth: '700px' })}
      >
        {t('createAssignmentDrawer.assessmentsSchoolsIntro')}
      </Text>
      {!selectedSchools.length && (
        <Box position="absolute" bottom={6} {...(!isTablet && { left: 150 })}>
          <Tooltip
            variant="dark"
            label={t('createAssignmentDrawer.addAllSchoolsBtnTooltip')}
            aria-hidden={true}
          >
            <Button
              size="sm"
              variant="ghost"
              leftIcon={<Icon name="plus" />}
              isDisabled={schoolsLoading}
              onClick={handleAddAllSchools}
            >
              {t('createAssignmentDrawer.addAllSchoolsBtn')}
            </Button>
          </Tooltip>
        </Box>
      )}
      <InputsList
        canBeEmpty
        withDivider
        name="schools"
        defaultValue={{ schoolId: '', schoolGradeLevels: [] }}
        mobile={isTablet}
        input={renderSchoolsInput}
        addButtonTitle={t('createAssignmentDrawer.addSchoolBtn')}
        addBtnTooltip={t('createAssignmentDrawer.addSchoolBtnTooltip')}
        renderLabel={(index) =>
          t('createAssignmentDrawer.school', { count: index + 1 })
        }
        renderPlaceholder={() => t('createAssignmentDrawer.schoolPlaceholder')}
        isRemoveButtonDisabled={() => false}
        {...(isTablet
          ? {
              dividerStyles: {
                ml: `-${TABLET_TAB_PANEL_PADDING}`,
                w: `calc(100% + 2 * ${TABLET_TAB_PANEL_PADDING})`,
              },
            }
          : { maxWidth: '700px' })}
      />
    </TabPanel>
  );
};

export default Schools;
