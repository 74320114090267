import React from 'react';

const postVideoScrollPosition = () => {
  const content = document.querySelector(`.page-content`);

  if (content?.scrollTop !== 0) {
    window.parent.postMessage(
      {
        type: 'postPageScrollTop',
        payload: {
          pageScrollTop: content?.scrollTop,
        },
      },
      '*'
    );
  }
};

export const addFullscreenScrollPost = (
  event: React.SyntheticEvent<HTMLIFrameElement, Event>
) => {
  const videoElements = (
    event.target as HTMLIFrameElement
  ).contentWindow?.document.querySelectorAll('.plyr');

  if (videoElements?.length) {
    videoElements.forEach((video) => {
      const videoWrapper = video.querySelector('.plyr__video-wrapper');
      const fullScreenButton = video.querySelector(`[data-plyr="fullscreen"]`);

      videoWrapper?.addEventListener('dblclick', postVideoScrollPosition);
      fullScreenButton?.addEventListener('click', postVideoScrollPosition);
    });
  }
};
