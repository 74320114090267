import { ApplicationEnum } from '@lon/shared/enums';
import { NavigationTypes } from './duck';

const getNavigationLinks: NavigationTypes.GetNavigationLinks = ({
  links,
  t,
  hasPermission,
  application,
}) => {
  if (hasPermission && application === ApplicationEnum.CAMPUS_LEADER_SUIT) {
    return links.campusLeaderLinks(t);
  } else if (
    hasPermission &&
    application === ApplicationEnum.CAMPUS_CURRICULUM_LEADER_SUIT
  ) {
    return links.campusLeaderLinks(t);
  } else if (
    hasPermission &&
    application === ApplicationEnum.DISTRICT_CURRICULUM_LEADER_SUIT
  ) {
    return links.campusLeaderLinks(t);
  } else if (hasPermission && application === ApplicationEnum.TEACHER_SUIT) {
    return links.teacherLinks(t);
  } else if (hasPermission && application === ApplicationEnum.STUDENT_SUIT) {
    return links.studentLinks(t);
  }

  return [];
};

export default getNavigationLinks;
