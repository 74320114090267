import { Box } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isDevMode } from '@lon/shared/constants';
import { useAuth } from '@lon/shared/hooks';
import { useResetAssignmentStatusMutation } from '@lon/shared/requests';
import { clearQueryCache } from '@lon/shared/utils';
import { StudentContentProps, useFetchContent } from './duck';

const StudentContent: React.FC<StudentContentProps> = ({
  refetch = () => {},
  contentUrl = '',
  learnosityReferenceId,
  redirectPath = '',
  onLoad,
  isContentWithoutLearnosity,
}) => {
  const [auth] = useAuth();
  const iframeRef = useFetchContent({ contentUrl, refetch });
  const [resetAssignmentStatus] = useResetAssignmentStatusMutation();
  const navigate = useNavigate();

  const onCloseRedirectHandler = () => {
    const body = iframeRef.current?.contentWindow?.document.body;
    const observer = new MutationObserver(() => {
      const closeButton = body?.querySelector(
        '.test-dialog-quit'
      ) as HTMLElement;
      if (closeButton) {
        closeButton.addEventListener('click', () => {
          if (redirectPath) {
            navigate(redirectPath);
          } else {
            window.location.reload();
          }
          observer.disconnect();
        });
      }
    });
    observer.observe(body as Node, { childList: true, subtree: true });
  };

  const resetAssignmentStatusHandler = () => {
    const body = iframeRef.current?.contentWindow?.document.body;
    const observer = new MutationObserver(() => {
      const submitButton = body?.querySelector(
        '.test-dialog-save-submit'
      ) as HTMLElement;

      if (submitButton) {
        submitButton.addEventListener('click', async () => {
          await resetAssignmentStatus({
            variables: {
              activityParams: {
                activityId: learnosityReferenceId || '',
                studentId: auth.user.userId,
              },
            },
          });

          clearQueryCache([
            'studentStudentAssignments',
            'districtStudentAssignments',
          ]);
          observer.disconnect();
        });
      }
    });
    observer.observe(body as Node, { childList: true, subtree: true });
  };

  useEffect(() => {
    const handleMessage = (message: any) => {
      if (
        iframeRef?.current &&
        message?.data?.type === 'resizeContent' &&
        message.data.payload.height
      ) {
        // TODO Remove extra 20px when the best solution is found
        iframeRef.current.height = message.data.payload.height + 20;
      }

      if (
        !isDevMode() &&
        iframeRef?.current &&
        message?.data?.type === 'dynamicWidthHeightCalculation' &&
        message?.data?.elemHeight &&
        message?.data?.dataPath
      ) {
        const tabTableFrame: HTMLIFrameElement | undefined | null =
          iframeRef.current.contentWindow?.document.querySelector(
            `[data-path="${message.data.dataPath}"]`
          );

        if (tabTableFrame) {
          const urlParams = new URLSearchParams(window.parent.location.search);
          const ttsEnabled = urlParams.get('tts') === 'ON';

          tabTableFrame.scrolling = 'no';
          tabTableFrame.height = ttsEnabled
            ? message.data.elemHeight + 53
            : message.data.elemHeight;
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage, false);
    };
  }, []);

  return (
    <Box h="full">
      <Box
        as="iframe"
        ref={iframeRef}
        data-reference-id={learnosityReferenceId}
        title="Assignment"
        scrolling="no"
        w="full"
        minH="full"
        bgColor="white"
        onLoad={() => {
          onCloseRedirectHandler();
          learnosityReferenceId && resetAssignmentStatusHandler();
          onLoad && onLoad();
        }}
      />
    </Box>
  );
};

export default StudentContent;
