// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ThemeEnum } from '@lon/shared/enums';
import { Accordion } from './Accordion';
import { getAlert } from './Alert';
import { Badge } from './Badge';
import { Breadcrumb } from './Breadcrumb';
import { getButton } from './Button';
import { Card } from './Card';
import { getCheckbox } from './Checkbox';
import { Divider } from './Divider';
import { Drawer } from './Drawer';
import { Form } from './Form';
import { FormError } from './FormError';
import { Heading } from './Heading';
import { IconButton } from './IconButton';
import { getInput } from './Input';
import { Link } from './Link';
import { Menu } from './Menu';
import { Modal } from './Modal';
import { NewButton } from './NewButton';
import { NumberInput } from './NumberInput';
import { getPopover } from './Popover';
import { Progress } from './Progress';
import { Radio } from './Radio';
import { Select } from './Select';
import { getSwitch } from './Switch';
import { getTabs } from './Tabs';
import { getTag } from './Tag';
import { TagKids } from './TagKids';
import { Text } from './Text';
import { Textarea } from './Textarea';
import { getTooltip } from './Tooltip';

export const getComponents = (theme: ThemeEnum, isKinder?: boolean) => {
  return {
    Alert: getAlert(theme),
    Button: getButton(theme),
    Input: getInput(theme),
    Badge,
    Form,
    FormError,
    Checkbox: getCheckbox(theme),
    Tag: getTag(theme),
    Tabs: getTabs(theme),
    Breadcrumb,
    Modal,
    Text,
    Heading,
    Menu,
    NumberInput,
    Select,
    IconButton,
    Link,
    Drawer,
    Tooltip: getTooltip(theme),
    Radio,
    Divider,
    Accordion,
    Popover: getPopover(theme),
    Progress,
    Card,
    Textarea,
    Switch: getSwitch(theme),
    NewButton,
    ...(isKinder && { Tag: TagKids }),
  };
};
