import { UseToastOptions } from '@chakra-ui/react';
import React from 'react';
import { ERROR_CODES } from '@lon/shared/constants';
import {
  ApplicationEnum,
  DistrictPermission,
  ProfileTypeEnum,
  UserPurpose,
} from '@lon/shared/enums';
import {
  AclApplication,
  CalendarTypeEnum,
  EntryTypeEnum,
  GetLookUpRolesQuery,
  GradeLevelEnum,
  StudentAssignmentStatus as StudentAssignmentStatusEnum,
  ViewModeEnum,
} from '@lon/shared/requests';

export type SpeedName = 'slow' | 'regular' | 'fast';

export interface AclPermission extends AclApplication {
  permissionName?: DistrictPermission;
}

export interface BaseProps {
  children?: React.ReactNode;
  isCentered?: boolean;
}

export type CalendarsType = Array<{
  __typename?: 'Calendar';
  _id: string;
  pk: string;
  name?: string | null;
  isDefault?: boolean | null;
  calendarId?: string | null;
  calendarTemplateId?: string | null;
  version?: number | null;
  editable?: boolean | null;
  calendarType?: CalendarTypeEnum | null;
  userId?: string | null;
  creator?: string | null;
  calendarEntries?: {
    __typename?: 'EntryPageConnection';
    collection?: Array<{
      __typename?: 'Entry';
      id: string;
      _id: string;
      eventId?: string | null;
      eventTemplateId?: string | null;
      name: string;
      type: EntryTypeEnum;
      startDate: string;
      endDate?: string | null;
      notes?: string | null;
      editable?: boolean | null;
      version?: number | null;
    } | null> | null;
  } | null;
  color?: string;
  foregroundColor?: string;
} | null> | null;

export type CalendarListType = Array<{
  __typename?: 'userCalendars';
  id: string;
  _id: string;
  name: string;
  calendarId: string;
  calendarType: string;
  isDefault: boolean;
  isSelected?: boolean | null;
  editable: boolean;
  userId: string;
  version?: number | null;
  creator?: string;
  userSelection: {
    __typename?: 'UserSelection';
    id: string;
    pk: string;
    sk: string;
    calendarSettingsPopup?: boolean | null;
    selectedCalendar?: any | null;
    viewMode?: ViewModeEnum | null;
  };
  color?: string;
  foregroundColor?: string;
} | null> | null;

export interface LearnosityAnswer {
  response_id: string;
  question_type: string;
  dt_score_updated: Date;
  automarkable: boolean;
  attempted: boolean;
  score: number;
  max_score: number;
  question_reference: string;
  item_reference: string;
  response: {
    value: [string];
    type: string;
    apiVersion: string;
    revision: number;
  };
}
export interface ToastProps extends UseToastOptions {
  firstLink?: string;
  secondLink?: string;
  firstLinkText?: string;
  secondLinkText?: string;
  variant:
    | 'success'
    | 'error'
    | 'info'
    | 'error-dark'
    | 'error-light'
    | 'success-dark'
    | 'success-light'
    | 'warning-dark'
    | 'warning-light'
    | 'info-dark'
    | 'info-light';
}

export type ToastFn = (state: ToastProps) => void;

export type ErrorCode = keyof typeof ERROR_CODES;

export type ErrorParams = [] | { [key: string]: any };

export enum Permisions {
  PERMISSION_UNSPECIFIED = 0,
  PERMISSION_PLATFORM_ADMIN = 1,
  PERMISSION_PASSWORD_RECOVERY = 2,
  PERMISSION_PASSWORD_CHANGE = 3,
  PERMISSION_ADMIN_SUIT = 4,
}

export type Permission = keyof typeof Permisions;

export interface User {
  firstName: string;
  availableSystems: string[];
  purpose: UserPurpose;
  lastName: string;
  username: string;
  schoolIds: string[];
  districtId: string;
  profileType: string;
  state: string;
  email: string;
  districtUuid: string;
  permissions?: Permission[];
  type: string;
  userId: string;
  gradeLevel: GradeLevelEnum;
  userRoles: GetLookUpRolesQuery['lookupRoles'];
  impersonated: boolean;
}

export interface BaseUser {
  firstName: string;
  lastName: string;
  username: string;
  schoolIds: string[];
  districtId: string;
  profileType: string;
  state: string;
  email: string;
  districtUuid: string;
  permissions?: Permission[];
  type: string;
  userId: string;
}

export interface MultiSelectOption {
  label: string;
  value: string | boolean;
}

export type ArrElement<ArrType> = ArrType extends readonly (infer ElementType)[]
  ? ElementType
  : never;

export enum IconVariantType {
  Share,
  RightArrow,
  Hidden,
  SquareGrid,
}

export interface CalculateStatusObject {
  type: StudentAssignmentStatusEnum | string;
  grade?: number | string | null;
  returnedAt?: Date;
}

export enum ViewTypes {
  Tile = 'tile',
  List = 'list',
}

export interface DeviceSizes {
  base: number;
  sm: number;
  md: number;
}

export interface Mark {
  response_id: string;
  question_type: string;
  dt_score_updated: Date;
  automarkable: boolean;
  attempted: boolean;
  score: number;
  max_score: number;
  question_reference: string;
  item_reference: string;
  response: {
    value: string[];
    type: string;
    apiVersion: string;
    revision: number;
  };
}

export interface Pagination {
  pageIndex: number;
  pageSize: number;
}

export interface WorkingLocationContext {
  application?: Extract<
    ApplicationEnum,
    | ApplicationEnum.STUDENT_SUIT
    | ApplicationEnum.TEACHER_SUIT
    | ApplicationEnum.CAMPUS_LEADER_SUIT
    | ApplicationEnum.CAMPUS_CURRICULUM_LEADER_SUIT
    | ApplicationEnum.DISTRICT_CURRICULUM_LEADER_SUIT
    | ApplicationEnum.PLATFORM_ADMIN
    | ApplicationEnum.ADMIN
    | ApplicationEnum.MATHNATION_SUIT
  >;
  currentSchoolId?: string;
  setCurrentSchoolId: (schoolId: string) => void;
  permissions: Permisions[];
  schoolIds: string[];
  userType?: string;
}

export type FilterTypes = {
  min_time_started?: string;
  max_time_started?: string;
  users?: {
    id: string;
    name: string;
  }[];
} | null;

export interface VoicePreferences {
  englishVoice: string;
  spanishVoice: string;
}

export enum AuthError {
  Locked = 'ACCOUNT_LOCKED',
  InvalidCode = 'INVALID_CODE',
  ExpiredCode = 'EXPIRED_CODE',
}

export interface Sidebar {
  isOpen: boolean;
}

export interface Access {
  coding?: boolean;
  setupWizard?: boolean;
}

export interface Auth {
  isLoggedIn: boolean;
  isAuthenticating: boolean;
  profileType?: ProfileTypeEnum;
  user: User;
  access?: Access;
}

export type ApplicationSettingsReducer = Record<string, any>;

export type SetAuth = (newAuth: Auth) => void;

export interface AccessibilitySettings {
  accTheme?: string;
  accTypeSize?: string;
  accSpeechSpeed?: SpeedName;
  accSpeechHighlightWords?: boolean;
  accTypeFace?: string;
}

export type InitScienceBotType = {
  text?: string | null | undefined;
  __typename?: 'ScienceBot' | undefined;
  id: string;
};

export enum FilterInputValueEnum {
  Object = 'OBJECT',
  ObjectArray = 'OBJECT_ARRAY',
}

export interface Standard {
  breadcrumb: string;
  uuid: string;
  title: string;
  HumanCodingScheme: string;
}

export type GqlResponse<Data, PropertyName extends string> = {
  [key in PropertyName]: Data;
};

export interface Answer {
  automarkable: boolean;
  maxScore: number | null;
  score: number | null;
  responseId: string;
}

export * from './localStorageTypes';
