import { checkIsMathnation } from '@lon/shared/constants';
import { ApplicationEnum, ProfileTypeEnum } from '@lon/shared/enums';

export const getCurrentSuite = (
  profileType?: ProfileTypeEnum
): Extract<
  ApplicationEnum,
  | ApplicationEnum.STUDENT_SUIT
  | ApplicationEnum.TEACHER_SUIT
  | ApplicationEnum.CAMPUS_LEADER_SUIT
  | ApplicationEnum.MATHNATION_SUIT
  | ApplicationEnum.DISTRICT_CURRICULUM_LEADER_SUIT
  | ApplicationEnum.CAMPUS_CURRICULUM_LEADER_SUIT
> => {
  switch (true) {
    case profileType === ProfileTypeEnum.CAMPUS_LEADER:
      return ApplicationEnum.CAMPUS_LEADER_SUIT;
    case profileType === ProfileTypeEnum.CAMPUS_CURRICULUM_LEADER:
      return ApplicationEnum.CAMPUS_CURRICULUM_LEADER_SUIT;
    case profileType === ProfileTypeEnum.DISTRICT_CURRICULUM_LEADER:
      return ApplicationEnum.DISTRICT_CURRICULUM_LEADER_SUIT;
    case profileType === ProfileTypeEnum.TEACHER:
      return checkIsMathnation()
        ? ApplicationEnum.MATHNATION_SUIT
        : ApplicationEnum.TEACHER_SUIT;
    case profileType === ProfileTypeEnum.STUDENT:
      return checkIsMathnation()
        ? ApplicationEnum.MATHNATION_SUIT
        : ApplicationEnum.STUDENT_SUIT;
    default:
      return ApplicationEnum.CAMPUS_LEADER_SUIT;
  }
};
