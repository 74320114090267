import { Box, Flex, Tag, Text, VStack } from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Teachers as TeachersNames } from './duck/mock';

const Teachers: React.FC<{
  isStudentBelowK3: boolean;
}> = ({ isStudentBelowK3 }) => {
  const { t } = useTranslation();

  return (
    <Box py={6} px={{ base: 6, lg: '150px' }}>
      <VStack align="left" w="full">
        <Text
          variant="h6"
          fontWeight={isStudentBelowK3 ? 600 : 400}
          lineHeight={isStudentBelowK3 ? '1.5rem' : '1.625rem'}
        >
          {t('userSettings.credentials.teachers')?.toUpperCase()}
        </Text>
        <Text variant="s2" color="primary.400" whiteSpace="pre-line">
          {t('userSettings.credentials.teacherDescription')}
        </Text>
      </VStack>
      <Flex wrap="wrap" gap={2} mt={6}>
        {TeachersNames.map((name) => (
          <Tag
            key={name}
            minH="22px"
            px={3}
            fontWeight={400}
            bg="primary.30"
            color="primary.700"
            borderRadius="0.75rem"
            cursor="default"
          >
            <Box pl={2}>{name}</Box>
          </Tag>
        ))}
      </Flex>
    </Box>
  );
};

export default Teachers;
