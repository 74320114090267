const shadows = {
  base: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  xs: '0px 5px 20px -2px rgba(43, 54, 70, 0.07)',
  sm: '0px 2px 5px rgba(43, 54, 70, 0.2)',
  md: 'inset 0px 0px 0px 2px #61BAF4',
  lg: 'inset 0px 0px 0px 4px #61BAF4',
  '2xl': '0px 0px 20px rgba(0, 0, 0, 0.15)',
  '3xl': '4px 0px 7px rgba(43, 54, 70, 0.35)',
  pane: '0px 5px 20px -2px rgba(43, 54, 70, 0.07)',
  drawer: '0px 3px 20px 15px rgba(43, 54, 70, 0.20)',
  focus: {
    base: '0px 0px 0px 3px #E0F7FF',
    blue: '0px 0px 0px 4px #4ECDFE',
    teal: '0px 0px 0px 4px #72E9FC',
  },
};

export default shadows;
