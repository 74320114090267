import { sanitize } from 'dompurify';
import { t } from 'i18next';
import { isEqual } from 'lodash-es';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { InferType, array, object, string } from 'yup';
import { MultiSelectOption } from '@lon/shared/types';
import {
  OptimizedSubgroupForComparison,
  ShowSubgroupsToastProps,
  Subgroup,
  TeachersById,
} from './types';

export const validationSchema = object({
  subgroups: array().of(
    object().shape({
      name: string()
        .trim()
        .required(t('subgroups.titleRequired'))
        .test(
          'is-unique-subgroup-name',
          '"Title" should be unique within the class',
          function (value) {
            if (!value) return true;
            return (
              // @ts-ignore
              this?.from[1].value.subgroups.filter((subgroup) => {
                return subgroup.name === value;
              }).length <= 1
            );
          }
        )
        .default(''),
      teachers: array()
        .of(
          object().shape({
            value: string(),
            label: string(),
          })
        )
        .default([]),
    })
  ),
});

export const getValidation = (
  teachersById: TeachersById,
  subgroups?: Subgroup[] | null
) => {
  if (!subgroups?.length) {
    return {
      validationSchema,
      defaultValues: {
        subgroups: [
          {
            name: '',
            teachers: [] as MultiSelectOption[],
          },
        ],
      },
    };
  }

  return {
    validationSchema,
    defaultValues: {
      subgroups: subgroups.map((subgroup) => ({
        ...subgroup,
        teachers:
          subgroup?.teachers?.collection?.map((teacher) => ({
            value: teachersById[teacher?._id || '']?.teacher._id,
            label: `${teachersById[teacher?._id || '']?.teacher?.firstName} ${
              teachersById[teacher?._id || '']?.teacher?.lastName
            }`,
          })) || [],
      })),
    },
  };
};

export type FormValues = ReturnType<typeof getValidation>['defaultValues'];

const optimizeSubgroupForComparison = (
  subgroups?: Subgroup[]
): OptimizedSubgroupForComparison[] | undefined =>
  subgroups?.map((subgroup) => ({
    id: subgroup?._id,
    name: subgroup?.name,
    teachers: subgroup?.teachers?.collection?.map((teacher) => teacher?._id),
  }));

export const showSubgroupsToast = ({
  toast,
  className,
  notOptimizedUpdatedSubgroups,
  notOptimizedInitialSubgroups,
}: ShowSubgroupsToastProps) => {
  const updatedSubgroups = optimizeSubgroupForComparison(
    notOptimizedUpdatedSubgroups
  );
  const initialSubgroups = optimizeSubgroupForComparison(
    notOptimizedInitialSubgroups
  );

  const removedSubgroups = initialSubgroups?.reduce((acc, current) => {
    const removed = !updatedSubgroups?.find(
      (subgroup) => subgroup.id === current.id
    );

    if (removed) {
      return [...acc, current];
    } else {
      return acc;
    }
  }, [] as OptimizedSubgroupForComparison[]);
  const addedSubgroups = updatedSubgroups?.reduce((acc, current) => {
    const added = !initialSubgroups?.find(
      (subgroup) => subgroup.id === current.id
    );

    if (added) {
      return [...acc, current];
    } else {
      return acc;
    }
  }, [] as OptimizedSubgroupForComparison[]);
  const modifiedSubgroups = initialSubgroups?.reduce((acc, current) => {
    const subgroup = updatedSubgroups?.find(
      (subgroupUpdated) => subgroupUpdated.id === current.id
    );

    if (subgroup) {
      const isModified = !isEqual(current, subgroup);
      if (isModified) {
        return [...acc, subgroup];
      }
    }
    return acc;
  }, [] as OptimizedSubgroupForComparison[]);

  !!addedSubgroups?.length &&
    toast({
      position: 'bottom',
      duration: 5000,
      variant: 'success-light',
      title: t('subgroups.addedTitle', {
        count: addedSubgroups?.length,
      }) as string,
      description: sanitize(
        t('subgroups.addedDescription', {
          count: addedSubgroups?.length,
          names: addedSubgroups.map((subgroup) => subgroup.name).join(', '),
          className,
          interpolation: {
            escapeValue: false,
          },
        })
      ),
      isClosable: true,
    });

  !!modifiedSubgroups?.length &&
    toast({
      position: 'bottom',
      duration: 5000,
      variant: 'success-light',
      title: t('subgroups.modifiedTitle', {
        count: modifiedSubgroups?.length,
      }) as string,
      description: sanitize(
        t('subgroups.modifiedDescription', {
          count: modifiedSubgroups?.length,
          names: modifiedSubgroups.map((subgroup) => subgroup.name).join(', '),
          className,
          interpolation: {
            escapeValue: false,
          },
        })
      ),
      isClosable: true,
    });

  !!removedSubgroups?.length &&
    toast({
      position: 'bottom',
      duration: 5000,
      variant: 'success-light',
      title: t('subgroups.removedTitle', {
        count: removedSubgroups?.length,
      }) as string,
      description: sanitize(
        t('subgroups.removedDescription', {
          count: removedSubgroups?.length,
          names: removedSubgroups.map((subgroup) => subgroup.name).join(', '),
          className,
          interpolation: {
            escapeValue: false,
          },
        })
      ),
      isClosable: true,
    });
};

export type SubgroupsFormValues = InferType<typeof validationSchema>;

export const checkUniqueness = (
  fields: FieldValues,
  formProviderProps: UseFormReturn<any>
) => {
  let hasErrors = false;

  fields?.subgroups?.forEach((subgroup: Record<string, any>, index: number) => {
    if (
      (fields?.subgroups?.filter(
        (item: Record<string, any>) =>
          item?.name?.trim() === subgroup?.name?.trim()
      )?.length || 0) > 1
    ) {
      hasErrors = true;
      formProviderProps.setError(`subgroups.${index}.name`, {
        type: 'validation',
        message: t('subgroups.titleUniq'),
      });
    }
  });

  return hasErrors;
};
