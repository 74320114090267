import { Box, Flex, Text } from '@chakra-ui/react';
import { AiOutlineCheckCircle } from '@react-icons/all-files/ai/AiOutlineCheckCircle';
import { AiOutlineCloseCircle } from '@react-icons/all-files/ai/AiOutlineCloseCircle';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { passwordConstants, passwordTypes } from './duck';

const PasswordRequirement: React.FC<passwordTypes.PasswordRequirementProps> = ({
  requirement,
  type,
}) => {
  const { t } = useTranslation();
  return (
    <Flex
      gap="13px"
      alignItems="center"
      p={1}
      bg={passwordConstants?.colorSchema[type]?.background}
      color={passwordConstants?.colorSchema[type]?.text}
    >
      <Box>
        {type === 'error' ? (
          <AiOutlineCloseCircle fontSize="18px" />
        ) : (
          <AiOutlineCheckCircle fontSize="18px" />
        )}
      </Box>
      <Text variant="n3">
        {t(`passwordRequirements.${requirement}`, {
          type: 'Reviewer',
        })}
      </Text>
    </Flex>
  );
};

export default PasswordRequirement;
