import { Standard } from '@lon/shared/types';
import { HideElement, Scope, TransformedElements } from '@lon/suit/types';

export const filterStandards = (
  taxonomy?: Standard[],
  standardsJson?: Record<string, string>,
  state?: string
) =>
  taxonomy?.filter(
    (standard: { uuid: string }) =>
      standardsJson?.[standard.uuid] === state?.toLowerCase()
  );

export const transformElements = (
  scope?: Scope,
  standardsJson?: Record<string, string>,
  state?: string
): TransformedElements =>
  scope
    ? scope?.sections
        ?.filter((section) => !(section.metadata.hide === HideElement.Yes))
        .map((section) => {
          const processedElements = section.elements.map((item) => ({
            ...item,
            metadata: {
              ...(item?.metadata || {}),
              sectionTitle: section?.metadata?.sectionTitle,
              scopeDisplayName: scope?.displayName,
            },
            teacherView: {
              ...item.teacherView,
              taxonomy: filterStandards(
                item?.teacherView?.taxonomy,
                standardsJson,
                state
              ),
            },
            studentView: item.studentView.length
              ? item.studentView.map((item) => ({
                  ...item,
                  taxonomy: filterStandards(
                    item?.taxonomy,
                    standardsJson,
                    state
                  ),
                }))
              : item.studentView,
          }));
          return {
            ...section,
            elements: processedElements,
          };
        })
        ?.reduce(
          (acc, section) => ({
            ...acc,
            home: {
              [scope.home?.landingPage.identifier || '']: {
                sectionId: 'home',
                ...scope.home?.landingPage,
                teacherView:
                  typeof scope.home?.landingPage?.teacherView === 'object' &&
                  Object.keys(scope.home?.landingPage?.teacherView).length
                    ? scope.home?.landingPage?.teacherView
                    : scope.home?.landingPage?.elementFiles?.find(
                        (file) => file.href && !file.metadata?.fileType
                      ),
              },
              ...scope.home?.sideBar.reduce(
                (acc, element) => ({
                  ...acc,
                  [element.identifier]: {
                    ...element,
                    sectionId: 'home',
                  },
                }),
                {}
              ),
            },
            [section.identifier]: section.elements.reduce(
              (acc, element) => ({
                ...acc,
                [element.identifier]: {
                  ...element,
                  sectionId: section.identifier,
                  sectionTitle:
                    section?.metadata?.sectionTitle || section?.title,
                },
              }),
              {}
            ),
          }),
          {}
        ) || {}
    : {};
