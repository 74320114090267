import { PasswordInput } from '../../form-units';
import { Icon } from '../../icon';
import { PasswordRequirement } from '../../password-requirements';
import Spinner from '../../spinner';
import { useToast } from '../../use-toast';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash-es';
import React, { ElementRef, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  useCreatePasswordChangeMutation,
  useCreatePasswordRecoveryMutation,
} from '@lon/shared/requests';
import {
  OPENED_MODAL,
  generatePassword,
  refetchFallenQueries,
} from '@lon/shared/utils';
import { updatePasswordTypes, updatePasswordUtils } from './duck';

const UpdatePassword: React.FC<updatePasswordTypes.UpdatePasswordForm> = ({
  isLogin,
  data,
  loading,
  params,
  username,
  districtActivation,
}) => {
  const { t } = useTranslation();
  const [createPasswordChange, { loading: createPasswordChangeLoading }] =
    useCreatePasswordChangeMutation();
  const toast = useToast();

  const passwordRef = useRef<ElementRef<typeof PasswordInput>>(null);
  const { validationSchema, defaultValues } = updatePasswordUtils.getValidation(
    true,
    isLogin
  );
  const navigate = useNavigate();
  const [passwordErrors, setPasswordErrors] = useState<number[] | null>(null);
  const formProviderProps = useForm({
    resolver: yupResolver(validationSchema(true, isLogin)),
    defaultValues,
    mode: 'onSubmit',
  });

  const formValuePassword = useWatch({
    name: 'newPassword',
    control: formProviderProps.control,
  });

  useEffect(() => {
    if (isLogin && data?.passwordRecoveryToken?.expired) {
      navigate('/login', {
        state: {
          errorTitle: t('errorMessage.passwordExpiredTitle'),
          errorBody: t('errorMessage.invalidResetPasswordBody'),
        },
      });
    }
    if (isLogin && data && !data?.passwordRecoveryToken) {
      navigate('/login', {
        state: {
          errorTitle: t('errorMessage.invalidResetPasswordTitle'),
          errorBody: t('errorMessage.invalidResetPasswordBody'),
        },
      });
    }
  }, [data]);

  useEffect(() => {
    const passwordErrors = updatePasswordUtils.validateNewPassword(
      formValuePassword,
      16
    );
    formValuePassword && setPasswordErrors(passwordErrors);
  }, [formValuePassword]);

  const handleClose = () => {
    OPENED_MODAL(null);
    toast({
      title: t('updatePassword.successMessage'),
      variant: 'success-light',
      isClosable: true,
    });
    refetchFallenQueries();
  };

  const isDirty =
    formProviderProps.formState.isDirty ||
    !isEmpty(formProviderProps.formState.dirtyFields);

  const isFormEnabled = (): boolean => {
    const fieldsAmount = isLogin ? 2 : 3;
    return (
      Object.keys(formProviderProps.formState.dirtyFields)?.filter((v) => v)
        ?.length === fieldsAmount
    );
  };

  const handleClickGenerate = () => {
    formProviderProps.setValue('newPassword', generatePassword(16), {
      shouldDirty: true,
    });

    if (passwordRef.current?.showPassword) {
      passwordRef.current?.showPassword();
    }
    formProviderProps.trigger('newPassword');
  };

  const [
    executeCreatePasswordRecovery,
    { loading: createPasswordRecoveryLoading },
  ] = useCreatePasswordRecoveryMutation();

  const submitHandler = async (values: updatePasswordTypes.FormValues<any>) => {
    !isLogin
      ? updatePasswordUtils.handleSubmit(
          createPasswordChange,
          handleClose,
          toast,
          values
        )
      : updatePasswordUtils.handleLoginPasswordReset(
          values,
          params,
          executeCreatePasswordRecovery,
          toast,
          navigate,
          districtActivation
        );
  };

  return (
    <FormProvider {...formProviderProps}>
      {loading && isLogin ? (
        <Flex justify="center" w="100%" h="100%">
          <Spinner variant="dark" />
        </Flex>
      ) : (
        <Flex
          as="form"
          maxW={{ xs: '320px', sm: '480px', md: '768px', lg: '100%' }}
          noValidate
          onSubmit={formProviderProps.handleSubmit(submitHandler)}
          display="flex"
          flexDirection="column"
        >
          {!isLogin && (
            <Box pb={8}>
              <Text>
                {updatePasswordUtils.savedNewRole ? (
                  <Trans
                    i18nKey={t('updatePassword.roleChangedHeader', {
                      username,
                    })}
                  />
                ) : (
                  <Trans i18nKey={t('updatePassword.passwordExpiredHeader')} />
                )}
              </Text>
            </Box>
          )}
          <Flex
            flexDir={{ xs: 'column', lg: 'row' }}
            gap={8}
            alignItems={{ xs: 'center', lg: 'unset' }}
            justify="space-between"
          >
            <Box w={{ xs: '320px', sm: '454px' }} p={6} bg="secondary.50">
              <Text variant="h6" pb="1.25rem">
                {t('updatePassword.newPasswordRequirements')}
              </Text>
              {[1, 8, 3, 4, 5, 9].map((requirement) => (
                <PasswordRequirement
                  key={requirement}
                  requirement={requirement}
                  type={
                    !formValuePassword
                      ? 'default'
                      : passwordErrors?.includes(requirement)
                      ? 'error'
                      : 'success'
                  }
                />
              ))}
              <Text mt={3} variant="n3">
                {t('updatePassword.newPasswordNote')}
              </Text>
            </Box>
            <Flex
              w={{ xs: '312px', sm: '454px' }}
              flexDir="column"
              justify="space-between"
            >
              <Box mt={4}>
                {!isLogin && (
                  <PasswordInput
                    autoComplete="off"
                    label={t('updatePassword.currentPasswordLabel')}
                    formLabelProps={{
                      color: 'primary.800 !important',
                    }}
                    name="currentPassword"
                    isRequired
                    placeholder={t('updatePassword.currentPasswordPlaceholder')}
                    inputLayoutProps={{ mt: '4' }}
                  />
                )}
                <Box position="relative">
                  <PasswordInput
                    autoComplete="off"
                    ref={passwordRef}
                    label={t('updatePassword.newPasswordLabel')}
                    formLabelProps={{
                      color: 'primary.800 !important',
                    }}
                    name="newPassword"
                    isRequired
                    placeholder={t('updatePassword.newPasswordPlaceholder')}
                    inputLayoutProps={{ mt: '4' }}
                    onChange={(e) => {
                      const newPassword = e.target.value;
                      formProviderProps.setValue('newPassword', newPassword, {
                        shouldDirty: true,
                      });
                    }}
                  />
                  <Button
                    size="sm"
                    variant="ghost"
                    position="absolute"
                    top="-4px"
                    right={0}
                    p={0}
                    textDecoration="underline"
                    onClick={handleClickGenerate}
                  >
                    {t('updatePassword.generate')}
                  </Button>
                </Box>
                <PasswordInput
                  autoComplete="off"
                  label={t('updatePassword.confirmPasswordLabel')}
                  formLabelProps={{
                    color: 'primary.800 !important',
                  }}
                  name="repeatedPassword"
                  isRequired
                  placeholder={t('updatePassword.confirmPasswordPlaceholder')}
                  inputLayoutProps={{ mt: '5', mb: '5' }}
                />
              </Box>
              <Flex flexDir="row" alignItems="center" justify="space-between">
                <Button
                  variant="outline"
                  isDisabled={!isDirty}
                  onClick={() => formProviderProps.reset(defaultValues)}
                >
                  {t('updatePassword.clearAllButton')}
                </Button>
                <Button
                  leftIcon={<Icon name="check-outlined" />}
                  variant="solid"
                  data-testid="submit"
                  isLoading={
                    formProviderProps.formState.isSubmitting ||
                    createPasswordChangeLoading ||
                    createPasswordRecoveryLoading
                  }
                  type="submit"
                  isDisabled={!isFormEnabled()}
                >
                  {t('updatePassword.updateButton')}
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      )}
    </FormProvider>
  );
};

export default UpdatePassword;
