import { DistrictAssignmentContext } from '../../../../duck/context';
import { Box, Button, Flex, Text, Tooltip } from '@chakra-ui/react';
import format from 'date-fns/format';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Message, useToast } from '@lon/shared/components';
import { useStartDistrictAssignmentMutation } from '@lon/shared/requests';
import { getMessageData } from './duck';

const StartAssessment: React.FC = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const { districtAssignments, districtAssignment, refetch } = useContext(
    DistrictAssignmentContext
  );
  const isStartDateNotReached =
    new Date() < new Date(districtAssignment?.startDate);
  const isStartDateToday =
    new Date().setHours(0, 0, 0, 0) ===
    new Date(districtAssignment?.startDate).setHours(0, 0, 0, 0);
  const isStartDateMissed =
    !isStartDateToday &&
    new Date().setHours(0, 0, 0, 0) >
      new Date(districtAssignment?.startDate).setHours(0, 0, 0, 0);
  const isDueDateMissed =
    new Date().setHours(0, 0, 0, 0) >
    new Date(districtAssignment?.endDate).setHours(0, 0, 0, 0);
  const { variant, title, body, startButtonVariant } = getMessageData({
    isStartDateToday,
    isStartDateMissed,
    isDueDateMissed,
  });
  const [startDistrictAssignment, { loading }] =
    useStartDistrictAssignmentMutation();

  const filteredDistrictAssignments = districtAssignments?.filter(
    (districtAssignment) => districtAssignment?.studentAssignments?.length
  );

  const startAssessment = () => {
    Promise.all(
      filteredDistrictAssignments?.map((districtAssessment) => {
        return startDistrictAssignment({
          variables: {
            params: {
              assignmentId: districtAssessment?.assignmentId || '',
              availableDate: format(Date.now(), 'yyyy-MM-dd'),
            },
          },
        });
      })
    )
      .then(() => {
        refetch().catch(() => {});
        toast({
          title: t('assessmentDetails.startAssessment.successToastTitle'),
          description: t('assessmentDetails.startAssessment.successToastBody'),
          variant: 'success-light',
          status: 'success',
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: t('systemMessages.038'),
          status: 'error',
          variant: 'error-light',
          isClosable: true,
          duration: null,
        });
      });
  };

  return (
    <Box p="8" w={{ base: 'full', md: '50%' }}>
      <Message
        variant={variant}
        alertDescriptionProps={{
          display: 'block',
          padding: 0,
        }}
        title={
          title ? (
            <Text variant="s1" fontStyle="italic">
              {title}
            </Text>
          ) : null
        }
        message={
          <Flex direction="column" gap="4">
            <Text variant="s3">{body}</Text>
            <Tooltip
              isDisabled={
                isStartDateNotReached || !filteredDistrictAssignments.length
              }
              variant="dark"
              label={t(
                'assessmentDetails.startAssessment.startAssessmentButtonTooltip'
              )}
            >
              <Button
                isLoading={loading}
                isDisabled={
                  isStartDateNotReached || !filteredDistrictAssignments.length
                }
                variant={startButtonVariant}
                size="sm"
                alignSelf="flex-start"
                top="unset !important"
                right="unset !important"
                leftIcon={<Icon name="releaseAssessment" />}
                onClick={startAssessment}
              >
                {t('assessmentDetails.startAssessment.startAssessmentButton')}
              </Button>
            </Tooltip>
          </Flex>
        }
      />
    </Box>
  );
};

export default StartAssessment;
