import { Box, Button, Flex, VStack } from '@chakra-ui/react';
import { HiPlus } from '@react-icons/all-files/hi/HiPlus';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Icon } from '@lon/shared/components';
import { MenuGroupItem } from '@lon/shared/components';
import { DistrictPermission } from '@lon/shared/enums';
import { useGetTheme, usePermission } from '@lon/shared/hooks';
import { getThemeStyleValue } from '@lon/shared/utils';
import { CollapsibleSidebar } from '@lon/suit/components';
import { sidebarLinks, sidebarTypes, sidebarUtils } from './duck';

const Sidebar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currentTheme = useGetTheme();
  const [activeTab, setActiveTab] = useState<number>();
  const [canCreateClass, canCreateStudent] = usePermission([
    {
      permission: DistrictPermission.TEACHER_SUIT,
      module: 'classes',
      operation: 'create',
    },
    {
      permission: DistrictPermission.TEACHER_SUIT,
      module: 'students',
      operation: 'create',
    },
  ]);

  const handleTabOpen = (i: number, path?: string) => {
    activeTab === i ? setActiveTab(undefined) : setActiveTab(i);
    path && navigate(path);
  };

  const renderLink = ({
    title,
    path,
    id,
    icon,
    pathsToHighlight,
  }: sidebarTypes.SidebarLink) => {
    const isActive = sidebarUtils.isActive(pathname, pathsToHighlight);
    return (
      <Box as="li" key={id} role="none">
        <Button
          w="full"
          as={Link}
          to={path}
          variant="sidebarButton"
          position="relative"
          justifyContent="flex-start"
          fontWeight={400}
          leftIcon={<Icon name={icon || 'user-group'} size="small" />}
          iconSpacing="3"
          role="tab"
          id={`${title.toLowerCase()}-tab`}
          aria-controls={`${title.toLowerCase()}-list`}
          aria-selected={isActive && !pathname.includes('create')}
          isActive={isActive}
          className={isActive ? 'active' : ''}
          css={
            isActive
              ? {
                  backgroundColor: getThemeStyleValue(
                    'var(--chakra-colors-primary-50) !important',
                    'var(--chakra-colors-teal-500) !important'
                  )(currentTheme),
                  color: getThemeStyleValue(
                    'var(--chakra-colors-primary-800) !important',
                    'var(--chakra-colors-secondary-1000) !important'
                  )(currentTheme),
                  '&:hover': {
                    backgroundColor: getThemeStyleValue(
                      'var(--chakra-colors-primary-50) !important',
                      'var(--chakra-colors-teal-600) !important'
                    )(currentTheme),
                  },
                }
              : {}
          }
        >
          {title}
        </Button>
      </Box>
    );
  };

  return (
    <CollapsibleSidebar>
      <Flex direction="column" gap={10} p={6} pr={3.5}>
        <Box as="nav">
          <Flex
            gap={4}
            mb={4}
            direction="column"
            as="ul"
            listStyleType="none"
            role="tablist"
            aria-orientation="vertical"
            aria-label={t('classes.classesNavigation')}
          >
            {sidebarLinks.firstSection.map(renderLink)}
          </Flex>
          <VStack as="ul" role="menubar" spacing="1rem" listStyleType="none">
            {sidebarLinks.reportSection.map((item, index) => (
              <MenuGroupItem
                key={item.title}
                index={index}
                activeTab={activeTab}
                handleTabOpen={handleTabOpen}
                selectedPath={pathname}
                mode="light"
                subMenuItemProps={{
                  whiteSpace: 'normal',
                  textAlign: 'left',
                  paddingTop: 2,
                  paddingBottom: 2,
                }}
                {...item}
              />
            ))}
          </VStack>
        </Box>

        <Flex
          gap={4}
          direction="column"
          role="tablist"
          aria-orientation="vertical"
          aria-label={t('classes.classesActions')}
        >
          {sidebarLinks
            .getSecondSection(canCreateClass, canCreateStudent)
            .map(({ id, path, title }) => (
              <Button
                w="full"
                key={id}
                as={NavLink}
                to={path}
                variant={getThemeStyleValue(
                  'outlineDark',
                  'outline'
                )(currentTheme)}
                justifyContent="flex-start"
                fontWeight={400}
                leftIcon={<HiPlus />}
                iconSpacing="3"
                draggable="false"
                role="tab"
                id={`${title.toLowerCase().replace(' ', '-')}-tab`}
                aria-controls={title.toLowerCase().replace(' ', '-')}
                aria-selected={path === pathname}
              >
                {title}
              </Button>
            ))}
        </Flex>
      </Flex>
    </CollapsibleSidebar>
  );
};

export default Sidebar;
