import { AssignmentHeader, Footer } from '../../components';
import { Box, Button, Flex } from '@chakra-ui/react';
import { TeacherPages } from '@suit/pages';
import React, { useContext } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { getQuery } from '@lon/api';
import { Icon } from '@lon/shared/components';
import { sxLightScrollBar } from '@lon/shared/constants';
import { useQueryParams } from '@lon/shared/hooks';
import { routes } from '@lon/suit/configs';
import { useCurrentElement } from '@lon/suit/hooks';
import { ScopeContext } from '@lon/suit/contexts';
import { JsonElementStructure } from '@lon/suit/contexts';

const PreviewAssignment = () => {
  const { loading } = useContext(ScopeContext);
  // @ts-ignore
  const element = useCurrentElement() as JsonElementStructure;
  const [queryParams] = useQueryParams();
  const navigate = useNavigate();
  const toolConsumerCode = (queryParams.get('tool_consumer_code') ||
    '') as string;
  const assignmentId = (queryParams.get('sa_pk') || '') as string;

  return (
    <Flex height="100vh" direction="column">
      <AssignmentHeader
        loading={loading}
        elementName={element?.metadata?.elementName}
        sectionName={element?.metadata?.sectionTitle}
        scopeName={element?.metadata?.scopeDisplayName}
        action={
          toolConsumerCode === 'schoology' && (
            <Button
              size="sm"
              variant="solid"
              alignSelf="center"
              leftIcon={<Icon name="subscription-outlined" />}
              onClick={() => {
                navigate(
                  `${generatePath(routes.lti.showAssignment, {
                    assignmentId,
                  })}?${getQuery({
                    tool_consumer_code: toolConsumerCode,
                  })}`
                );
              }}
            >
              Assignment Grades
            </Button>
          )
        }
      />
      <Box flexGrow="1" overflow="auto" sx={sxLightScrollBar}>
        <TeacherPages.Element hasToolbar={false} />
      </Box>
      <Footer />
    </Flex>
  );
};

export default PreviewAssignment;
