import { Button, Text } from '@chakra-ui/react';
import { sanitize } from 'dompurify';
import { FC, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Icon, NoContent } from '@lon/shared/components';
import { useMediaQuery } from '@lon/shared/hooks';
import { ClassContext } from '@lon/suit/contexts';
import { types } from './duck';

const NoSubgroups: FC<types.NoSubgroupsProps> = ({ openForm, className }) => {
  const { t } = useTranslation();
  const { isMainTeacher } = useContext(ClassContext);
  const [isMobile] = useMediaQuery('(max-width: 767px)');

  return (
    <NoContent
      title={
        <Trans
          i18nKey={sanitize(
            t(
              `subgroups.${
                isMainTeacher ? 'noSubgroups' : 'noSubgroupsAdditionalView'
              }`,
              {
                className,
                interpolation: {
                  escapeValue: false,
                },
              }
            )
          )}
        />
      }
      description={
        <Trans
          i18nKey={
            isMainTeacher
              ? t('subgroups.addSubgroups')
              : t('subgroups.noSubgroupsAdditionalViewBody')
          }
        />
      }
      actions={
        isMainTeacher ? (
          <Button
            variant="solid"
            mb="24"
            leftIcon={<Icon name="plus" />}
            onClick={openForm}
            {...(!isMobile && { mt: 10 })}
          >
            <Text as="span" isTruncated>
              {t('subgroups.createBtn')}
            </Text>
          </Button>
        ) : (
          <></>
        )
      }
    />
  );
};

export default NoSubgroups;
