import { Box, Button, DrawerFooter, Flex, HStack, TabPanel, useDisclosure } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { isEmpty } from 'lodash-es';
import * as React from 'react';
import { useImperativeHandle, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ConfirmModal, Icon, useToast } from '@lon/shared/components';
import { WorkingLocation } from '@lon/shared/contexts';
import { ApplicationEnum } from '@lon/shared/enums';
import { useGradeLevel } from '@lon/shared/hooks';
import { UserTypeEnum, useCreatePasswordChangeMutation } from '@lon/shared/requests';
import { handleError } from '@lon/shared/utils';
import { RootState } from '@lon/suit/configs';
import ResetPassword from './components/reset-password/ResetPassword';
import { constants, types, utils } from './duck';
import { Administrators, ShowPassword, Teachers, UserInfo } from './components';

const AccessibilitySettings = React.forwardRef<
  {
    closeSettings: () => void;
    changeTab: (index: number) => void;
  },
  types.Props
>(({ setIsPreferencesOpen, setIsDirtyForm, setCurrentTab }, externalRef) => {
  const { user } = useSelector((state: RootState) => state.auth);
  const { isStudentBelowK3 } = useGradeLevel();
  const { t } = useTranslation();
  const toast = useToast();
  const [changeTab, setChangeTab] = useState<number | null>(null);
  const [createPasswordChange, { loading }] = useCreatePasswordChangeMutation();
  const { application } = React.useContext(WorkingLocation);
  const { validationSchema, defaultValues } = utils.getValidation();
  const isStudent = user.type === UserTypeEnum.Student;
  const isRenderAdministrators =
    application !== ApplicationEnum.ADMIN && !isStudent;
  const isRenderTeachers = application !== ApplicationEnum.ADMIN && isStudent;
  const isRenderResetPassword = !isStudent;
  const isRenderShowPassword = isStudent;

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: 'onBlur',
  });

  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();

  const fields = form.watch();

  const onSubmit = form.handleSubmit(async (values) => {
    createPasswordChange({
      variables: {
        input: {
          currentPassword: values.currentPassword,
          newPassword: values.newPassword,
          repeatedPassword: values.repeatedPassword,
        },
      },
    })
      .then(() => {
        if (typeof changeTab === 'number') {
          setCurrentTab(changeTab);
        } else {
          setIsPreferencesOpen(false);
        }

        toast({
          title: t('userSettings.credentials.success'),
          variant: 'success-light',
          isClosable: true,
          duration: 5000,
        });
      })
      .catch((error) => {
        if (error.message === constants.INVALID_PASSWORD) {
          toast({
            title: t('updatePassword.invalidPasswordTitle'),
            description: t('updatePassword.invalidCurrentPasswordDescription'),
            variant: 'error-light',
            isClosable: true,
            duration: 5000,
          });
        } else {
          handleError({ error, toast });
        }
      });
  });

  React.useEffect(() => {
    setIsDirtyForm(!isEmpty(form.formState.dirtyFields));
  }, [fields]);

  const handleCloseSettings = () => {
    form.trigger();
    setChangeTab(null);

    if (!isEmpty(form.formState.dirtyFields)) {
      onConfirmOpen();
    } else {
      setIsPreferencesOpen(false);
    }
  };

  const handleChangeTab = (index: number) => {
    form.trigger();
    setChangeTab(index);

    if (!isEmpty(form.formState.dirtyFields)) {
      onConfirmOpen();
    } else {
      setCurrentTab(index);
    }
  };

  useImperativeHandle(
    externalRef,
    () => ({
      closeSettings: handleCloseSettings,
      changeTab: handleChangeTab,
    }),
    []
  );

  const closeForm = () => {
    if (form.formState.isDirty || !isEmpty(form.formState.dirtyFields)) {
      onConfirmOpen();
    } else {
      onClose();
    }
  };

  const onClose = () => {
    if (typeof changeTab === 'number') {
      setCurrentTab(changeTab);
    } else {
      setIsPreferencesOpen(false);
    }

    form.reset({});
    onConfirmClose();
  };

  return (
    <TabPanel as="form" p={0} noValidate onSubmit={onSubmit} w="full">
      <FormProvider {...form}>
        <Flex
          display="flex"
          direction="column"
          color="primary.800"
          h="calc(100vh - 9.25rem)"
        >
          <Box overflow="auto" h="calc(100vh - 9.25rem)">
            <UserInfo isStudentBelowK3={isStudentBelowK3} />
            {isRenderResetPassword && <ResetPassword />}
            {isRenderShowPassword && (
              <ShowPassword isStudentBelowK3={isStudentBelowK3} />
            )}
            {isRenderAdministrators && <Administrators />}
            {isRenderTeachers && (
              <Teachers isStudentBelowK3={isStudentBelowK3} />
            )}
          </Box>
          <DrawerFooter
            h="5.75rem"
            borderTopWidth="1px"
            justifyContent="flex-start"
            py={0}
            flex="none"
          >
            <HStack justify="space-between" width="100%">
              <Button
                leftIcon={<Icon name="arr-left-outlined" />}
                onClick={closeForm}
              >
                {t('userSettings.credentials.cancel')}
              </Button>
              <Button
                leftIcon={<Icon name="check-outlined" />}
                variant="solid"
                type="submit"
                isDisabled={loading || !form.formState.isDirty}
              >
                {t('userSettings.credentials.save')}
              </Button>
            </HStack>
          </DrawerFooter>
        </Flex>
      </FormProvider>
      <ConfirmModal
        primaryHeading={t('userSettings.credentials.modal.title')}
        subHeading={t('userSettings.credentials.modal.description')}
        dontSaveBtnText={t('userSettings.credentials.modal.cancel')}
        submitButtonText={t('userSettings.credentials.modal.save')}
        hasDontSaveBtn
        hasSaveBtn={form.formState.isValid}
        isModalOpen={isConfirmOpen}
        handleSave={() => {
          onSubmit();
          onConfirmClose();
          onClose();
        }}
        handleDontSave={onClose}
        handleCancel={onConfirmClose}
        isLoading={loading}
        dontSaveBtnProps={{
          variant: 'solidDark',
          leftIcon: <Icon name="delete-outlined" size="lg" />,
          px: 4,
        }}
        submitBtnProps={{
          leftIcon: <Icon name="check-outlined" size="md" />,
          px: 4,
        }}
        modalSize={{ base: 'full', sm: '500px' }}
      />
    </TabPanel>
  );
});

export default AccessibilitySettings;
