import UnauthorizedPageWrapper from '../unauthorized-page-wrapper';
import { Button, Flex, Icon, Image, Text } from '@chakra-ui/react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ApplicationEnum } from '@lon/shared/enums';
import { homeOutlined, redoOutlined } from '@lon/shared/icons';
import { types } from './duck';

const imageByType = {
  [ApplicationEnum.ADMIN]: 'admin/assets/errors/error-admin.png',
  [ApplicationEnum.PLATFORM_ADMIN]:
    'platform-admin/assets/errors/error-admin.png',
  [ApplicationEnum.STUDENT_SUIT]: 'suit/assets/images/error-student.png',
  [ApplicationEnum.TEACHER_SUIT]: 'suit/assets/images/error.png',
  [ApplicationEnum.CAMPUS_LEADER_SUIT]: 'suit/assets/images/error.png',
  [ApplicationEnum.CAMPUS_CURRICULUM_LEADER_SUIT]:
    'suit/assets/images/error.png',
  [ApplicationEnum.DISTRICT_CURRICULUM_LEADER_SUIT]:
    'suit/assets/images/error.png',
  [ApplicationEnum.LOGIN]: 'login/assets/error.png',
};

const SyncError: React.FC<types.SyncErrorProps> = ({ application }) => {
  const { t } = useTranslation();

  return (
    <UnauthorizedPageWrapper>
      <Flex
        direction="column"
        flexGrow={1}
        justifyContent="center"
        alignItems="center"
        bgColor="white"
        borderRadius="md"
        px={6}
        py={10}
        height="auto"
        gap={6}
      >
        <Image src={imageByType[application]} />
        <Flex
          direction="column"
          gap={4}
          alignItems="center"
          color="primary.800"
        >
          <Text
            variant="h5"
            as="h1"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
          >
            <Trans i18nKey="errorMessage.syncError" />
          </Text>
          <Text variant="n3" as="h2" textAlign="center">
            <Trans i18nKey={'errorMessage.syncErrorDescription'} />
          </Text>

          <Flex gap={4}>
            <Button
              as={Link}
              to="/"
              title={t('errorMessage.goToHome')}
              variant="outline"
              leftIcon={<Icon as={homeOutlined} />}
            >
              <Text as="span" isTruncated>
                {t('errorMessage.goToHome')}
              </Text>
            </Button>
            <Button
              title={t('errorMessage.goToHome')}
              variant="solid"
              onClick={() => window.location.reload()}
              leftIcon={<Icon as={redoOutlined} />}
            >
              <Text as="span" isTruncated>
                {t('errorMessage.refresh')}
              </Text>
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </UnauthorizedPageWrapper>
  );
};

export default SyncError;
