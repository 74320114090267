import { Menu } from '../../../menu';
import {
  AssignToStudents,
  Comment,
  ContentMenu,
  FontLarger,
  FontSmaller,
  Highlighting,
  LanguageSwitcher,
  Planner,
  Print,
  ShowAnswers,
  Speech,
  Standards,
  ViewFiles,
  ViewType,
} from '../../../toolbar-actions';
import { uniqueId } from 'lodash-es';
import { useContext, useEffect, useState } from 'react';
import { isReviewSite } from '@lon/shared/constants';
import { WorkingLocation } from '@lon/shared/contexts';
import { ApplicationEnum } from '@lon/shared/enums';
import { useAuth, useQueryParams } from '@lon/shared/hooks';
import { RoleCodeEnum } from '@lon/shared/requests';
import { teacherView } from '@lon/suit/constants';
import { ReceivedMessage, ViewType as View } from '@lon/suit/types';
import { actionsTypes } from './duck';

// TODO: remove when functionality will be ready
const show = false;

export const Actions: React.FC<actionsTypes.ActionsProps> = ({
  standards,
  printMethod,
  iframeRef,
  hasStudentView,
}) => {
  const [params] = useQueryParams();
  const viewType: View = params.type || teacherView;
  const [isContent, setIsContent] = useState<boolean>(false);
  const [{ user }] = useAuth();
  const { application } = useContext(WorkingLocation);

  const isCampusLeaderSuit = application === ApplicationEnum.CAMPUS_LEADER_SUIT;
  const isLeader = [
    RoleCodeEnum.CampusLeader,
    RoleCodeEnum.CampusCurriculumLeader,
    RoleCodeEnum.DistrictCurriculumLeader,
  ].some((item) => user?.userRoles?.find((role) => item === role.code));
  const isCampusLeader = user?.userRoles?.find(
    (role) => RoleCodeEnum.CampusLeader === role.code
  );

  const isAssignButtonHidden = isCampusLeaderSuit && isLeader;
  const isAddToPlanHidden = isCampusLeaderSuit && isCampusLeader;

  useEffect(() => {
    const handleMessage = (message: any) => {
      if (message?.data?.type === ReceivedMessage.Resize) {
        if (message?.data?.payload?.content?.length) {
          setIsContent(true);
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage, false);
    };
  }, []);

  return (
    <Menu
      key={uniqueId()}
      popup
      hoverable={false}
      skippedKeyboardButtons={{
        ARROW_DOWN: true,
      }}
      gap="2"
      w="full"
      justifyContent={{ base: 'space-between', md: 'end' }}
    >
      {!isReviewSite() && show && <FontLarger />}
      {!isReviewSite() && show && <FontSmaller />}
      {!isReviewSite() && show && <Highlighting />}
      {!isReviewSite() && show && <Comment />}
      {!isReviewSite() && <Speech />}
      {!isReviewSite() && show && <LanguageSwitcher />}
      {!isReviewSite() && !isAddToPlanHidden && <Planner />}
      {standards?.length && <Standards standards={standards} />}
      {hasStudentView && <ViewType />}
      {!isAssignButtonHidden && <AssignToStudents viewType={viewType} />}
      {!isReviewSite() && viewType !== teacherView && <ShowAnswers />}
      {viewType === teacherView && <ViewFiles />}
      {!isReviewSite() && <Print printMethod={printMethod} />}
      {isContent && <ContentMenu iframeRef={iframeRef} />}
    </Menu>
  );
};

export default Actions;
