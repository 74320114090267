import { hasFocusableElement } from '../table-content/duck/utils';
import { Box, Flex, Link, Text } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';

const setAnchor = (id: string) => {
  const currentUrl = window.location.href;

  const urlParts = currentUrl.split('#');

  const baseUrl = urlParts[0];

  window.location.href = id ? `${baseUrl}#${id}` : baseUrl;
};

const SkipNavTo = ({
  tableId,
  isMultipleTableOnThePage,
}: {
  tableId: string;
  isMultipleTableOnThePage?: boolean;
}) => {
  const { t } = useTranslation();
  const [hasFocusableContentInCaption, setCaptionFlag] = useState(false);
  const [hasFocusableContentInHead, setHeadFlag] = useState(false);
  const [hasFocusableContentInBody, setBodyFlag] = useState(false);
  const [hasFocusableContentInFoot, setFootFlag] = useState(false);
  const linkRefs = [
    useRef<HTMLAnchorElement>(null),
    useRef<HTMLAnchorElement>(null),
    useRef<HTMLAnchorElement>(null),
    useRef<HTMLAnchorElement>(null),
  ];
  const [focusedIndex, setFocusedIndex] = useState(0);
  const containerRef = useRef(null);
  const [skipNavTop, setSkipNavTop] = useState('-999px');

  useHotkeys(isMultipleTableOnThePage ? 'alt+3' : 'alt+2', () => {
    setSkipNavTop('0');
    (containerRef.current as any)?.focus();
  });

  useHotkeys('tab', () => {
    setSkipNavTop('-999px');
  });

  useHotkeys('esc', () => {
    (containerRef.current as any)?.blur();
    setSkipNavTop('-999px');
  });

  useEffect(() => {
    const filters = document.getElementById('tableFilters');
    const actions = document.getElementById('tableActions');
    if (filters || actions) {
      setCaptionFlag(
        hasFocusableElement((filters || actions) as HTMLElement, true)
      );
    }
  });

  useEffect(() => {
    const body = document.getElementById(`tableBody_${tableId}`);
    if (body) {
      setBodyFlag(hasFocusableElement(body, false));
    }
  });

  useEffect(() => {
    const head = document.getElementById(`tableHead_${tableId}`);
    if (head) {
      setHeadFlag(hasFocusableElement(head, false));
    }
  });

  useEffect(() => {
    const foot = document.getElementById(`tableFoot_${tableId}`);
    if (foot) {
      setFootFlag(hasFocusableElement(foot, true));
    }
  });

  useEffect(() => {
    if (linkRefs[focusedIndex].current) {
      (linkRefs[focusedIndex] as any)?.current.focus();
    }
  }, [focusedIndex]);

  const handleClickAnchor = (id: string) => {
    setAnchor(id);
    setSkipNavTop('-999px');
  };

  const sections = [
    { id: `tableFilters_${tableId}`, label: t('table.skipToCaption') },
    { id: `tableHead_${tableId}`, label: t('table.skipToSorting') },
    { id: `tableBody_${tableId}`, label: t('table.skipToRecords') },
    { id: `tableFoot_${tableId}`, label: t('table.skipToPagination') },
  ];

  const conditions = [
    hasFocusableContentInCaption,
    hasFocusableContentInHead,
    hasFocusableContentInBody,
    hasFocusableContentInFoot,
  ];

  const removeSectionAtIndex = (index: number) => sections.splice(index, 1);

  conditions.forEach((condition, index) => {
    if (!condition) {
      removeSectionAtIndex(index);
    }
  });

  const handleArrowKey = (direction: 'up' | 'down') => {
    const optionsCount = [
      hasFocusableContentInCaption,
      hasFocusableContentInHead,
      hasFocusableContentInBody,
      hasFocusableContentInFoot,
    ].filter(Boolean).length;

    if (optionsCount === 0) return;
    setSkipNavTop('0');

    setFocusedIndex((prevIndex) => {
      if (direction === 'up') {
        return (prevIndex - 1 + optionsCount) % optionsCount;
      } else {
        return (prevIndex + 1) % optionsCount;
      }
    });
  };

  const handleEnterOrSpace = () => {
    const selectedOptionId = sections[focusedIndex];

    if (selectedOptionId) {
      handleClickAnchor(selectedOptionId.id);
    }
    setSkipNavTop('-999px');
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        handleArrowKey('down');
        break;
      case 'ArrowUp':
        e.preventDefault();
        handleArrowKey('up');
        break;
      case 'Enter':
      case 'Space':
      case ' ':
        handleEnterOrSpace();
        break;
      case 'Escape': {
        (containerRef.current as any)?.blur();
        setSkipNavTop('-999px');
        break;
      }

      default:
        break;
    }
  };

  if (
    !hasFocusableContentInBody &&
    !hasFocusableContentInCaption &&
    !hasFocusableContentInHead &&
    !hasFocusableContentInFoot
  )
    return null;

  return (
    <Flex
      id="skipTo"
      tabIndex={0}
      direction="column"
      position="absolute"
      top={skipNavTop}
      overflowX="auto"
      left={4}
      bg="white"
      zIndex={3}
      _focus={{ top: 1 }}
      border="1px solid"
      ref={containerRef}
      onKeyDown={handleKeyDown}
    >
      <Box
        px={6}
        pt={4}
        pb={3}
        borderBottom="1px solid"
        borderColor="secondary.200"
      >
        <Text color="primary.500" variant="s2" maxW="252px" mb={2}>
          {t('table.skipListTitle')}
        </Text>
        <Flex color="primary.800">
          <Text
            color="primary.500"
            variant="s2"
            lineHeight="0.875rem"
            backgroundColor="secondary.100"
            px={2}
            py="0.375rem"
          >
            {'ALT'}
          </Text>
          <Text color="primary.500" variant="s2" px={2}>
            {'+'}
          </Text>
          <Text
            color="primary.500"
            variant="s2"
            lineHeight="0.875rem"
            backgroundColor="secondary.100"
            px={2}
            py="0.375rem"
          >
            {isMultipleTableOnThePage ? 3 : 2}
          </Text>
        </Flex>
      </Box>
      {sections?.map((v: any, index: number) => (
        <Link
          key={v.id}
          mt={index ? 2 : 0}
          py={2}
          px={6}
          ref={linkRefs[index]}
          tabIndex={0}
          onClick={() => handleClickAnchor(v.id)}
        >
          <Text variant="s2">{v.label}</Text>
        </Link>
      ))}
    </Flex>
  );
};

export default SkipNavTo;
