import { Flex, HStack } from '@chakra-ui/react';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { PageContent } from '@lon/shared/components';
import { WorkingLocation } from '@lon/shared/contexts';
import { Reports } from '@lon/shared/enums';
import { useAuth, useGetTheme } from '@lon/shared/hooks';
import {
  StudentAssignmentStatus,
  useGetClassesForDaDetailsQuery,
  useGetLeaderDistrictAssignmentsQuery,
  useGetUsersQuery,
} from '@lon/shared/requests';
import { getGradesFromAnswers, isCompletedAssignment } from '@lon/shared/utils';
import { BackButton } from '@lon/suit/components';
import { routes } from '@lon/suit/configs';
import { getAssignmentData, getColumns, useAreas } from './duck';
import { DistrictAssignmentContext } from './duck/context';
import { StudentAssignment } from './duck/types';
import {
  AssignmentGrades,
  AssignmentNotStarted,
  Description,
  LearnosityReport,
  NoContent,
  PageActions,
} from './components';
import { EmptyMessage } from './components';

const DistrictAssessmentDetails: React.FC = () => {
  const { t } = useTranslation();
  const [{ user }] = useAuth();
  const { daId = '' } = useParams();
  const currentTheme = useGetTheme();
  const { currentSchoolId: schoolId = '' } = useContext(WorkingLocation);
  const [showAnswersColumn, setShowAnswersColumn] = useState(false);
  const { areas, updateAreas, resetAreas } = useAreas({
    assignmentGrades: false,
    learningOutComesReport: false,
    responseAnalysisReport: false,
  });

  const {
    data: districtAssignmentsData,
    loading: districtAssignmentsLoading,
    refetch,
  } = useGetLeaderDistrictAssignmentsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      filter: {
        districtId: user.districtId,
        daId,
      },
    },
    skip: !user.districtId,
  });

  const {
    districtAssignment,
    districtAssignments,
    studentAssignments,
    districtAssignmentClasses,
    districtAssignmentStudents,
  } = useMemo(() => {
    const districtAssignmentsList =
      districtAssignmentsData?.districtAssignmentsForLeader?.collection || [];
    const districtAssignment =
      districtAssignmentsList.find(
        (districtAssessment) => districtAssessment.studentAssignments?.length
      ) || districtAssignmentsList[0];
    const studentAssignmentsList = districtAssignmentsList
      .flatMap((districtAssessment) => districtAssessment?.studentAssignments)
      .filter(Boolean) as StudentAssignment[];

    return {
      districtAssignment,
      districtAssignments: districtAssignmentsList,
      studentAssignments: studentAssignmentsList,
      districtAssignmentClasses: [
        ...new Set(
          studentAssignmentsList
            ?.map((studentAssignment) => studentAssignment?.classId)
            .filter(Boolean) as string[]
        ),
      ],
      districtAssignmentStudents: [
        ...new Set(
          studentAssignmentsList
            ?.map((studentAssignment) => studentAssignment?.studentId)
            .filter(Boolean) as string[]
        ),
      ],
    };
  }, [districtAssignmentsData]);

  const isAssessmentStarted = !!districtAssignment?.availableDate;

  const { data: studentsData, loading: studentsLoading } = useGetUsersQuery({
    variables: {
      id_list: districtAssignmentStudents,
      itemsPerPage: 9999,
    },
    skip: !districtAssignmentStudents.length,
  });

  const { data: classesData, loading: classesLoading } =
    useGetClassesForDaDetailsQuery({
      variables: {
        teachersId: user.userId,
        schoolId: schoolId,
        id_list: districtAssignmentClasses,
      },
      skip: !schoolId || !user?.userId || !districtAssignmentClasses.length,
    });

  const loading =
    districtAssignmentsLoading || studentsLoading || classesLoading;

  const preparedDistrictAssignmentData = useMemo(
    () =>
      getAssignmentData({
        studentAssignments,
        students: studentsData?.users?.collection,
        classes: classesData?.classes?.collection,
      }) || [],
    [studentAssignments, studentsData, classesData]
  );

  const columns = useMemo(
    () => getColumns(currentTheme, showAnswersColumn),
    [currentTheme, showAnswersColumn]
  );

  const progress = useMemo(() => {
    const preparedStudentAssignments = studentAssignments?.map((assignment) => {
      const answers =
        typeof assignment.answers === 'string'
          ? JSON.parse(assignment.answers)
          : assignment.answers;
      const { grade } = getGradesFromAnswers(answers);

      return {
        ...assignment,
        grade,
      };
    });
    const amount = preparedStudentAssignments?.length || 0;

    const percentCompleted = amount
      ? Math.ceil(
          ((
            preparedStudentAssignments?.filter((item) =>
              isCompletedAssignment(item?.status as StudentAssignmentStatus)
            ) || []
          ).length /
            amount) *
            100
        )
      : 0;

    const gradedAssignments =
      preparedStudentAssignments?.filter(
        (item) =>
          item.status === StudentAssignmentStatus.Completed &&
          typeof item.grade === 'number'
      ) || [];

    const percentGraded = amount
      ? Math.ceil((gradedAssignments.length / amount) * 100)
      : 0;

    const percentAverage =
      Math.ceil(
        gradedAssignments.reduce((acc, val) => {
          return acc + Number(val.grade);
        }, 0) / gradedAssignments.length
      ) || 0;

    return { percentAverage, percentCompleted, percentGraded };
  }, [studentAssignments]);

  return (
    <PageContent
      pageTitle={t('assessmentDetails.title')}
      wrapperProps={{
        role: 'tabpanel',
      }}
      headerElements={
        <HStack gap={4}>
          <BackButton
            to={routes.teachers.reports.districtAssessment}
            label={t('assessmentDetails.backButton')}
            tooltipLabel={t('assessmentDetails.backButtonTooltip')}
          />
          <PageActions />
        </HStack>
      }
    >
      <DistrictAssignmentContext.Provider
        value={{
          isArchived: false,
          isLoading: loading,
          showAnswersColumn: showAnswersColumn,
          districtAssignment,
          districtAssignments,
          preparedDistrictAssignmentData,
          setShowAnswersColumn: setShowAnswersColumn,
          resetAreas,
          updateAreas,
          refetch,
        }}
      >
        {!districtAssignments?.length && !loading ? (
          <NoContent />
        ) : (
          <Flex direction="column" gap={6} pb={6}>
            <Description progress={progress} />
            {areas.assignmentGrades && <AssignmentGrades columns={columns} />}
            {areas.responseAnalysisReport &&
              (isAssessmentStarted ? (
                progress && Number(progress.percentCompleted) === 0 ? (
                  <EmptyMessage type={Reports.RESPONSE_ANALYSIS} />
                ) : (
                  <LearnosityReport
                    data={
                      districtAssignment ? preparedDistrictAssignmentData : []
                    }
                    type={Reports.RESPONSE_ANALYSIS}
                  />
                )
              ) : (
                <AssignmentNotStarted type={Reports.RESPONSE_ANALYSIS} />
              ))}
            {areas.learningOutComesReport &&
              (isAssessmentStarted ? (
                progress && Number(progress.percentCompleted) === 0 ? (
                  <EmptyMessage type={Reports.LEARNING_OUTCOMES} />
                ) : (
                  <LearnosityReport
                    data={
                      districtAssignment ? preparedDistrictAssignmentData : []
                    }
                    type={Reports.LEARNING_OUTCOMES}
                  />
                )
              ) : (
                <AssignmentNotStarted type={Reports.LEARNING_OUTCOMES} />
              ))}
          </Flex>
        )}
      </DistrictAssignmentContext.Provider>
    </PageContent>
  );
};

export default DistrictAssessmentDetails;
