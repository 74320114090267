import {
  ArchiveClass,
  GeneralSettings,
  ManageStudents,
  Students,
  Subgroups,
} from '../components';
import { MenuDivider } from '@chakra-ui/react';

export const getActionItems = (archived: boolean) => {
  const activeClassActions = {
    classRoaster: [
      {
        key: 'students',
        Component: Students,
      },
      {
        key: 'manage-students',
        Component: ManageStudents,
      },
      {
        key: 'manage-subgroups',
        Component: Subgroups,
      },
      {
        key: 'class-roaster-divider',
        Component: MenuDivider,
      },
    ],
    classSettings: [
      {
        key: 'general-settings',
        Component: GeneralSettings,
      },
      // TODO uncomment when pages will be implemented
      // {
      //   key: 'manage-additional-teachers',
      //   Component: ManageAdditionalTeachers,
      // },
      // TODO uncomment when pages will be implemented
      // {
      //   key: 'manage-student-resources',
      //   Component: StudentResources,
      // },
      {
        key: 'archive-class-divider',
        Component: MenuDivider,
      },
      {
        key: 'archive-class',
        Component: ArchiveClass,
      },
    ],
  };

  const archivedClassActions = {
    classRoaster: [
      {
        key: 'students',
        Component: Students,
      },
      {
        key: 'view-subgroups',
        Component: Subgroups,
      },
      {
        key: 'class-roaster-divider',
        Component: MenuDivider,
      },
    ],
    classSettings: [
      {
        key: 'general-settings',
        Component: GeneralSettings,
      },
      // TODO uncomment when pages will be implemented
      // {
      //   key: 'view-student-resources',
      //   Component: StudentResources,
      // },
      {
        key: 'archive-class-divider',
        Component: MenuDivider,
      },
      {
        key: 'archive-class',
        Component: ArchiveClass,
      },
    ],
  };

  return archived ? archivedClassActions : activeClassActions;
};
