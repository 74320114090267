import { useToast } from '@chakra-ui/react';
import $ from 'jquery';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import {
  AssignmentLearnosityRenderingType,
  AssignmentLearnosityState,
  AssignmentLearnosityType,
  SignAssignmentServices,
  useSaveGradingSessionMutation,
  useSignRequestForGradingMutation,
} from '@lon/shared/requests';
import { routes } from '@lon/suit/configs';

type UseInitLearnosityGrading = () => {
  loading: boolean;
  isItemAttempted: boolean;
  savingGrades: boolean;
};
const useInitLearnosityGrading: UseInitLearnosityGrading = () => {
  const { t } = useTranslation();
  const { studentId, sessionId } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState(true);
  const [signRequest] = useSignRequestForGradingMutation();

  const [savingGrades, setSavingGrades] = React.useState(false);
  const [isItemAttempted, setIsItemAttempted] = React.useState(false);
  const [saveGrades] = useSaveGradingSessionMutation();

  const toast = useToast();
  const showGeneralError = () => {
    toast({
      title: t('systemMessages.038'),
      variant: 'error-light',
      isClosable: true,
    });
  };

  React.useEffect(() => {
    let mounted = true;

    const scoringObjects: Record<string, any> = {};

    signRequest({
      variables: {
        params: {
          reportSignedRequestInput: {
            service: SignAssignmentServices.Reports,
            reports: JSON.stringify([
              {
                id: 'report-1',
                type: 'session-detail-by-item',
                user_id: studentId,
                session_id: sessionId,
              },
            ]),
            studentId: studentId as string,
            sessionId,
          },
        },
      },
    })
      .then((response) => {
        if (!mounted) {
          return;
        }

        const reportSignedRequest = response?.data?.signRequest
          ?.signedRequest as string;

        if (!reportSignedRequest) {
          return;
        }

        // @ts-ignore
        const reportsApp = window.LearnosityReports.init(
          JSON.parse(reportSignedRequest),
          {
            readyListener() {
              const report1 = reportsApp.getReport('report-1');
              // @ts-ignore
              report1.on('ready:itemsApi', function (itemsApp) {
                if (!mounted) {
                  return;
                }

                $('.lrn_widget')
                  .wrap('<div class="row"></div>')
                  .wrap('<div class="col-md-6"></div>');

                // @ts-ignore
                itemsApp.getQuestions(function (questions) {
                  const items: { id: string; reference: string }[] = [];

                  if (!mounted) {
                    return;
                  }

                  // @ts-ignore
                  Object.values(questions).forEach((element, index) => {
                    // @ts-ignore
                    if (element.metadata.rubric_reference !== undefined) {
                      // @ts-ignore
                      const scoringItemId = element.metadata.rubric_reference;
                      const feedbackItemId =
                        // @ts-ignore
                        element.metadata.rubric_reference + '_' + Math.random();
                      $(
                        '<span class="learnosity-item" data-reference="' +
                          feedbackItemId +
                          '">'
                      )
                        // @ts-ignore
                        .appendTo($('#' + element.response_id).closest('.row'))
                        .wrap(
                          '<div class="col-md-6" style="padding: 0 20px;"></div>'
                        );

                      items.push({
                        reference: scoringItemId,
                        id: feedbackItemId,
                      });
                      scoringObjects[feedbackItemId] = element;
                    }
                  });

                  if (!mounted || !items.length) {
                    return;
                  }

                  signRequest({
                    variables: {
                      params: {
                        itemSignedRequestInput: {
                          service: SignAssignmentServices.Items,
                          items,
                          state: AssignmentLearnosityState.Initial,
                          type: AssignmentLearnosityType.Feedback,
                          renderingType:
                            AssignmentLearnosityRenderingType.Inline,
                          name: 'Teacher Manual Grading',
                          /* Just a hardcoded value */
                          referenceId: '123',
                        },
                      },
                    },
                  })
                    .then((response) => {
                      if (!mounted) {
                        return;
                      }

                      const itemsSignedRequest = response?.data?.signRequest
                        ?.signedRequest as string;

                      if (!itemsSignedRequest) {
                        return;
                      }
                      const itemsAppTeacherScoring =
                        // @ts-ignore
                        window.LearnosityItems.init(
                          JSON.parse(itemsSignedRequest),
                          {
                            readyListener() {
                              setLoading(false);

                              Object.keys(
                                itemsAppTeacherScoring.questions()
                              ).forEach((questionId) => {
                                itemsAppTeacherScoring
                                  .question(questionId)
                                  .on('changed', () => {
                                    setIsItemAttempted(true);
                                  });
                              });

                              const saveButton =
                                document.querySelector('#save-scores');

                              if (saveButton) {
                                saveButton.addEventListener('click', () => {
                                  const teacherItems =
                                    // @ts-ignore
                                    itemsAppTeacherScoring.getItems();
                                  const teachersResponses =
                                    // @ts-ignore
                                    itemsAppTeacherScoring.getResponses();
                                  const responses = [] as any[];

                                  $.each(
                                    itemsAppTeacherScoring.attemptedItems(),
                                    function (index, ref) {
                                      const response_id =
                                        teacherItems[ref].response_ids[0];
                                      const newResponseObject =
                                        scoringObjects[ref];

                                      responses.push({
                                        response_id:
                                          newResponseObject.response_id,
                                        score:
                                          teachersResponses[response_id].value,
                                      });
                                    }
                                  );

                                  setSavingGrades(true);
                                  saveGrades({
                                    variables: {
                                      sessionsParams: [
                                        {
                                          sessionId: sessionId as string,
                                          userId: studentId as string,
                                          responses: responses,
                                        },
                                      ],
                                    },
                                  })
                                    .then((response) => {
                                      const isUpdated =
                                        response?.data?.saveGradingSession
                                          ?.success;
                                      if (isUpdated) {
                                        // Learnosity does not update grades on their side immediately.
                                        setTimeout(() => {
                                          toast({
                                            title: t(
                                              'manualGrading.successSave'
                                            ),
                                            duration: 7000,
                                            variant: 'success-light',
                                          });
                                          navigate(
                                            generatePath(
                                              routes.classes.studentAssignments,
                                              { studentId: studentId }
                                            )
                                          );
                                        }, 7000);
                                      } else {
                                        setSavingGrades(false);

                                        if (!mounted) {
                                          return;
                                        }

                                        showGeneralError();
                                      }
                                    })
                                    .catch(() => {
                                      setSavingGrades(false);

                                      if (!mounted) {
                                        return;
                                      }

                                      showGeneralError();
                                    });
                                });
                              }
                            },
                            errorListener() {
                              if (!mounted) {
                                return;
                              }

                              showGeneralError();
                            },
                          }
                        );
                    })
                    .catch(() => {
                      if (!mounted) {
                        return;
                      }

                      showGeneralError();
                    });
                });
              });
            },
            errorListener() {
              if (!mounted) {
                return;
              }

              showGeneralError();
            },
          }
        );
      })
      .catch(() => {
        if (!mounted) {
          return;
        }

        showGeneralError();
      });

    return () => {
      mounted = false;
    };
  }, []);

  return {
    loading,
    isItemAttempted,
    savingGrades,
  };
};

export default useInitLearnosityGrading;
