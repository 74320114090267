import { Icon } from '../../icon';
import { InputLayout } from '../input-layout';
import {
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { get } from 'lodash-es';
import React, { useImperativeHandle } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { passwordInputTypes } from './duck';

const PasswordInput = React.forwardRef<
  {
    showPassword: () => void;
  },
  passwordInputTypes.PasswordInputProps
>(
  (
    {
      label,
      name,
      autoComplete = 'new-password',
      defaultValue = '',
      isRequired,
      inputLayoutProps,
      formLabelProps,
      isDisabled,
      variant,
      ...rest
    },
    externalRef
  ) => {
    const [show, setShow] = React.useState(false);
    const { control } = useFormContext();

    const handleClick = () => setShow(!show);

    useImperativeHandle(
      externalRef,
      () => ({
        showPassword: () => setShow(true),
      }),
      []
    );

    const {
      field: { onBlur, onChange, name: fieldName, ref, value },
      formState: { errors },
    } = useController({ name, defaultValue, control });

    return (
      <>
        <InputLayout
          id={fieldName}
          label={label}
          isRequired={isRequired}
          errorMessage={
            get(errors, `${fieldName}.message`) as unknown as string
          }
          formLabelProps={formLabelProps}
          isDisabled={isDisabled}
          variant={variant}
          input={
            <InputGroup>
              <Input
                ref={ref}
                type={show ? 'text' : 'password'}
                name={fieldName}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                autoComplete={autoComplete}
                variant={variant}
                {...rest}
              />
              <InputRightElement zIndex={1} height="full">
                <IconButton
                  minHeight="30px"
                  minWidth="30px"
                  height="30px"
                  paddingX={3}
                  paddingY={1.5}
                  variant="iconButton"
                  borderRadius="full"
                  onClick={handleClick}
                  aria-label="Show/Hide password"
                  color="primary.200"
                  icon={
                    <Icon
                      name={show ? 'eyeCrossedOutlined' : 'eyeOutlined'}
                      width="20px"
                      height="18px"
                    />
                  }
                />
              </InputRightElement>
            </InputGroup>
          }
          {...inputLayoutProps}
        />
      </>
    );
  }
);

export default PasswordInput;
