import { InputLayout } from '../form-units/input-layout';
import { Icon } from '../icon';
import { NewLink } from '../link';
import {
  Box,
  Divider,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import { get, isArray } from 'lodash-es';
import React, { useRef } from 'react';
import DatePicker, {
  CalendarContainer,
  CalendarContainerProps,
} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useController, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { KEYBOARD } from '@lon/shared/constants';
import { datePickerTypes, utils } from './duck';
import './ReactDatePickerStyle.css';

const ReactDatePicker: React.FC<datePickerTypes.DatePickerProps> = ({
  name = '',
  label,
  title,
  defaultValue = '',
  isDisabled = false,
  inputLayoutProps,
  placeholder,
  dateFormat,
  valueFormat,
  withTime = false,
  minDate,
  selectsRange = false,
  inline = true,
  showIcon = true,
  className,
  isRequired,
  highlightDates,
  totalDays,
  inputSizeProps,
  showCurrentDatePicker = false,
  isDisabledStable = false,
  ...props
}) => {
  const { control, setValue } = useFormContext();
  const { t } = useTranslation();

  const datePickerRef = useRef<any>(null);

  const {
    field: { name: fieldName, value, ref },
    formState: { errors },
  } = useController({ name, defaultValue, control });

  const dateFormatter = (date: Date | null) => {
    if (valueFormat && date) {
      return format(date, valueFormat);
    }
    return date;
  };
  const dateParser = (
    date: string | null | number | [string | null | number]
  ) => {
    if (
      valueFormat &&
      date &&
      (typeof date === 'string' || typeof date === 'number')
    ) {
      return parse(String(date), valueFormat, new Date());
    } else if (typeof date === 'object' && date?.[0] && valueFormat) {
      return parse(String(date[0]), valueFormat, new Date());
    }
    return date;
  };

  const calendarContainer = ({
    className,
    children,
  }: CalendarContainerProps) => (
    // @ts-ignore
    <CalendarContainer className={className}>
      {title ? (
        <>
          <Box bgColor="white" px="4">
            <Text variant="n3" textAlign="left" py="2.5">
              {title}
            </Text>
          </Box>
          <Divider m="0" />
        </>
      ) : (
        <></>
      )}
      <Box position="relative">{children as React.ReactNode}</Box>
      {totalDays ? (
        <Flex direction="column" w="full">
          <Divider m="0" />
          <Text variant="s2" py="1.5" textAlign="center">
            <Trans
              i18nKey="reactDatePicker.totalDays"
              values={{
                count: totalDays,
              }}
            >
              {totalDays} Days
            </Trans>
          </Text>
        </Flex>
      ) : (
        <></>
      )}
      {highlightDates ? (
        <Flex direction="column" w="full">
          <Divider mt="0" mb="6" />
          <Flex direction="column" bgColor="white" px="4">
            {Object.entries(highlightDates).map(([key, value]) => (
              <Flex align="center" mb="2">
                <Box as="span" className={`info-circle ${key}`} />
                <Text variant="s2" textAlign="left">
                  {value.info}
                </Text>
              </Flex>
            ))}
          </Flex>
        </Flex>
      ) : (
        <></>
      )}
    </CalendarContainer>
  );

  const CustomInput = React.forwardRef(
    ({ value, onClick, placeholder, onChange }: any, r: any) => (
      <>
        <Input
          ref={(el) => {
            ref(el);

            if (r) {
              r(el);
            }
          }}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onKeyDown={(ev: any) => {
            if (
              ev.keyCode === KEYBOARD.ENTER &&
              !datePickerRef?.current?.isCalendarOpen()
            ) {
              onClick();
            } else if (
              (ev.keyCode === KEYBOARD.ESC || ev.keyCode === KEYBOARD.ENTER) &&
              datePickerRef?.current?.isCalendarOpen()
            ) {
              ev?.preventDefault();
              datePickerRef?.current?.setOpen(false);
            }
          }}
          onClick={onClick}
          type="text"
          autoComplete="off"
          {...(inputSizeProps?.inputSize && { size: inputSizeProps.inputSize })}
          {...(showIcon && { paddingEnd: '10' })}
        />
        {showIcon && (
          <InputRightElement
            className="datepicker-calendar-icon"
            color={'secondary.400'}
            height="auto"
            top="0"
            bottom="0"
            cursor="pointer"
            children={
              <Icon
                name="calendar-outlined"
                {...(inputSizeProps?.iconSize && {
                  size: inputSizeProps.iconSize,
                })}
              />
            }
            onClick={onClick}
          />
        )}
      </>
    )
  );

  const selectCurrentDate = () => {
    const currentYear = new Date();
    setValue(name, dateFormatter(currentYear), {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const currentDatePicker = showCurrentDatePicker ? (
    <NewLink
      textDecoration="underline"
      cursor="pointer"
      onClick={selectCurrentDate}
      variant="primary"
      isDisabled={
        dateFormat === 'yyyy'
          ? value?.toString() === new Date().getFullYear()?.toString()
          : new Date(value)?.toISOString().split('T')[0] ===
            new Date().toISOString().split('T')[0]
      }
    >
      <Text variant="s2">
        {props.showYearPicker
          ? t('reactDatePicker.currentYear')
          : t('reactDatePicker.today')}
      </Text>
    </NewLink>
  ) : null;

  return (
    <InputLayout
      isDisabled={isDisabled}
      id={fieldName}
      label={label || ''}
      errorMessage={get(errors, `${fieldName}.message`) as unknown as string}
      isRequired={isRequired && !isDisabledStable}
      rightLabel={!isDisabledStable && currentDatePicker}
      input={
        isDisabledStable ? (
          <Flex align="center" gap="2.5" height="44px" color="primary.800">
            <Box
              as={Icon}
              color="primary.200"
              name="calendar-outlined"
              {...(inputSizeProps?.iconSize && {
                size: inputSizeProps.iconSize,
              })}
            />
            {format(
              value,
              dateFormat
                ? dateFormat
                : withTime
                ? 'dd/MM/yyyy, HH:mm'
                : 'dd/MM/yyyy'
            )}
          </Flex>
        ) : (
          <Controller
            control={control}
            name={name}
            render={({ field }) => (
              <InputGroup isolation="auto" className={className}>
                {/* @ts-ignore */}
                <DatePicker
                  customInput={<CustomInput ref={field.ref} />}
                  ref={datePickerRef}
                  placeholderText={
                    placeholder ? placeholder : t('reactDatePicker.placeholder')
                  }
                  selected={
                    !selectsRange
                      ? value
                        ? dateParser(value)
                        : value
                      : dateParser(value?.[0])
                  }
                  timeInputLabel={t('reactDatePicker.timeInputLabel')}
                  dateFormat={
                    dateFormat
                      ? dateFormat
                      : withTime
                      ? 'dd/MM/yyyy, HH:mm'
                      : 'dd/MM/yyyy'
                  }
                  showTimeInput={withTime}
                  minDate={minDate}
                  selectsRange={selectsRange}
                  disabled={isDisabled}
                  startDate={
                    selectsRange
                      ? value?.[0]
                        ? dateParser(value?.[0])
                        : value?.[0]
                      : null
                  }
                  endDate={
                    selectsRange
                      ? value?.[1]
                        ? dateParser(value?.[1])
                        : value?.[1]
                      : null
                  }
                  onChange={(date) => {
                    if (isArray(date)) {
                      field.onChange([
                        date[0] ? dateFormatter(date[0]) : date[0],
                        date[1] ? dateFormatter(date[1]) : date[1],
                      ]);
                    } else {
                      field.onChange(dateFormatter(date));
                    }
                  }}
                  calendarContainer={calendarContainer}
                  highlightDates={utils.getProcessedHighlightDates(
                    highlightDates
                  )}
                  {...props}
                />
              </InputGroup>
            )}
          />
        )
      }
      color={isDisabled && 'blue.400'}
      {...inputLayoutProps}
    />
  );
};

export default ReactDatePicker;
