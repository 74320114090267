import { Icon } from '../icon';
import {
  Button,
  TabProps as ChakraTabProps,
  Text,
  useMultiStyleConfig,
  useTab,
} from '@chakra-ui/react';
import React from 'react';
import { useThemeStyleValue } from '@lon/shared/hooks';

interface TabProps extends ChakraTabProps {
  size?: 'sm' | 'md';
  darkschema?: boolean;
  iconProps?: {
    height?: string;
    width?: string;
  };
}

const sizes = {
  fontSize: {
    sm: 's2',
    md: 'n3',
  },
  icon: {
    sm: 'sm',
    md: 'xl',
  },
  button: {
    sm: 'sm',
    md: 'md',
  },
};

const CustomTab = React.forwardRef<HTMLElement, TabProps>(
  ({ iconProps, ...rest }, ref) => {
    const { size = 'md', isDisabled, darkschema } = rest;
    const tabProps = useTab({ ...rest, ref });
    const isSelected = tabProps['aria-selected'];
    const styles = useMultiStyleConfig('Tabs', {
      ...tabProps,
      variant: 'drawerInternal',
    });
    const color = useThemeStyleValue(
      isSelected && darkschema
        ? 'white !important'
        : isDisabled
        ? 'secondary.300'
        : 'primary.800',
      'white'
    );

    return (
      <Button
        __css={styles.tab}
        overflowX="hidden"
        title={typeof tabProps.children === 'string' ? tabProps.children : ''}
        {...tabProps}
        leftIcon={
          isSelected ? (
            <Icon
              name="check-circle-outlined"
              size={sizes.icon[size] as any}
              {...iconProps}
            />
          ) : undefined
        }
        size={sizes.button[size]}
        cursor={isDisabled ? 'not-allowed' : 'pointer'}
        bgColor={isSelected && darkschema ? 'primary.800 !important' : 'none'}
        px={4}
        color={color}
        dangerouslySetInnerHTML={
          tabProps.dangerouslySetInnerHTML as
            | {
                __html: string;
              }
            | undefined
        }
      >
        <Text
          variant={sizes.fontSize[size]}
          whiteSpace="nowrap"
          overflowX="hidden"
          textOverflow="ellipsis"
        >
          {tabProps.children}
        </Text>
      </Button>
    );
  }
);

export default CustomTab;
