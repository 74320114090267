import useAuth from '../use-auth';
import { useCallback, useEffect, useState } from 'react';

const useCurrentSchoolId = (): [string, (schoolId: string) => void] => {
  const [{ user }] = useAuth();
  const [currentSchoolId, setCurrentSchoolId] = useState<string>('');

  useEffect(() => {
    if (user?.userId && !currentSchoolId) {
      const lastSchoolIdsByUserId =
        JSON.parse(localStorage.getItem('lastSchoolIdsByUserId') || '{}') || {};

      if (
        lastSchoolIdsByUserId[user.userId] &&
        user.schoolIds.includes(lastSchoolIdsByUserId[user.userId])
      ) {
        setCurrentSchoolId(lastSchoolIdsByUserId[user.userId]);
      } else {
        setCurrentSchoolId(user.schoolIds[0]);
      }
    }
  }, [user?.userId]);

  const updateCurrentSchoolId = useCallback(
    (schoolId: string) => {
      setCurrentSchoolId(schoolId);

      const lastSchoolIdsByUserId =
        JSON.parse(localStorage.getItem('lastSchoolIdsByUserId') || '{}') || {};

      window.localStorage.setItem(
        'lastSchoolIdsByUserId',
        JSON.stringify({
          ...lastSchoolIdsByUserId,
          ...(user.userId && { [user.userId]: schoolId }),
        })
      );
    },
    [user?.userId]
  );

  return [currentSchoolId, updateCurrentSchoolId] as [
    string,
    (schoolId: string) => void
  ];
};

export default useCurrentSchoolId;
