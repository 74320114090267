import { Column } from '@tanstack/react-table';
import { Icon } from '../icon';
import styles from './SortingIcon.module.css';

const SortingIcon: React.FC<{ column: Column<Record<any, any>> }> = ({
  column,
}) => {
  return column.getCanSort() ? (
    column.getIsSorted() ? (
      column.getIsSorted() === 'desc' ? (
        <Icon name="sorting-desc" className={styles.icon} />
      ) : (
        <Icon name="sorting-asc" className={styles.icon} />
      )
    ) : (
      <Icon name="sorting-default" className={styles.icon} />
    )
  ) : null;
};

export default SortingIcon;
