import actionsStyles from '../../../ToolbarActions.module.css';
import { VIEW_TEACHER_CONTENT_BUTTON_HEIGHT } from '../../../duck';
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@lon/shared/components';
import {
  CONTENT_ACTIONS_TOOLBAR_HEIGHT,
  DESKTOP_CONTENT_PADDING,
  PAGE_HEADER_HEIGHT,
  SITE_FOOTER_HEIGHT,
  SITE_HEADER_HEIGHT,
  TOOLBAR_HEIGHT,
  sxLightScrollBar,
} from '@lon/shared/constants';
import { useMediaQuery } from '@lon/shared/hooks';
import { useOutsideClick } from '@lon/suit/hooks';

const StudentPageSelect: React.FC<{
  isStudentView?: boolean;
  selectedStudentViewId?: string;
  options: { title: string; id: string }[];
  setStudentView: (studentElementId: string) => void;
  setTeacherView: () => void;
}> = ({
  isStudentView,
  selectedStudentViewId,
  options,
  setStudentView,
  setTeacherView,
}) => {
  const { t } = useTranslation();
  const [isMobile] = useMediaQuery('(max-width: 767px)');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const menuButtonRef = useRef<HTMLElement>(null);
  const menuRef = useOutsideClick<HTMLDivElement>((event) => {
    if (
      isOpen &&
      (event.target === window ||
        !menuButtonRef.current?.contains(event.target as Node))
    ) {
      onClose();
    }
  });

  return (
    <Menu
      placement="bottom"
      gutter={4}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
    >
      <Tooltip
        variant="dark"
        label={
          isOpen
            ? t('toolbar.hideContentPagesTooltip')
            : t('toolbar.showContentPagesTooltip')
        }
        aria-hidden={true}
      >
        <MenuButton
          ref={menuButtonRef}
          isActive={isOpen}
          as={Button}
          variant="ghost"
          className={actionsStyles.action}
          leftIcon={<Icon name="schoolOutlined" size="lg" />}
          rightIcon={
            <Icon name={isOpen ? 'chevronUp' : 'chevronDown'} size="lg" />
          }
          _active={{
            backgroundColor: 'primary.800',
            color: 'white',
          }}
          {...(isMobile && {
            px: '12px',
            sx: {
              '.chakra-button__icon': {
                mr: 0,
              },
            },
          })}
        >
          {!isMobile && (
            <Text as="span" isTruncated variant="s2">
              {t('toolbar.showStudentPages')}
            </Text>
          )}
        </MenuButton>
      </Tooltip>
      <MenuList
        data-focus-visible-disabled
        w={{ base: '100vw', sm: '368px' }}
        backgroundColor="secondary.50"
        py={0}
        borderBottomRadius="md"
        ref={menuRef}
        onKeyDown={(event) => {
          if (
            ['Escape', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(event.code)
          ) {
            onClose();
          }
        }}
      >
        <Flex
          px="15px"
          pb="15px"
          borderRadius="md"
          direction="column"
          gap="2"
          sx={{
            '.chakra-menu__group__title': {
              color: 'primary.500',
              textTransform: 'uppercase',
              fontSize: '0.75rem',
              fontWeight: '400',
              m: '6',
            },
          }}
        >
          <MenuGroup title={t('toolbar.studentsMenuTitle')}>
            <Box
              maxH={`calc(100vh - (${
                SITE_HEADER_HEIGHT +
                PAGE_HEADER_HEIGHT +
                TOOLBAR_HEIGHT +
                CONTENT_ACTIONS_TOOLBAR_HEIGHT +
                SITE_FOOTER_HEIGHT +
                DESKTOP_CONTENT_PADDING +
                VIEW_TEACHER_CONTENT_BUTTON_HEIGHT
              }px + 96px))`}
              overflowY="auto"
              css={{
                ...sxLightScrollBar,
                '&::-webkit-scrollbar, &::-webkit-scrollbar-thumb': {
                  borderRadius: '10px',
                },
              }}
            >
              {options.map((option, index) => (
                <MenuItem
                  key={option.id}
                  p={0}
                  position="relative"
                  {...(index !== options.length - 1 && {
                    borderBottomWidth: '1px',
                    borderBottomStyle: 'solid',
                    borderBottomColor: 'secondary.100',
                  })}
                  {...(index === 0 && {
                    borderTopRadius: 'md',
                  })}
                  {...(index === options.length - 1 && {
                    borderBottomRadius: 'md',
                  })}
                  onClick={() => {
                    setStudentView(option.id);
                  }}
                  {...(option.id === selectedStudentViewId && {
                    pointerEvents: 'none',
                    cursor: 'not-allowed',
                  })}
                >
                  {option.id === selectedStudentViewId && (
                    <Box
                      position="absolute"
                      top="6.5px"
                      left={0}
                      width="4px"
                      height="calc(100% - 1rem)"
                      bg="primary.400"
                      zIndex={10}
                      borderTopRightRadius="6px"
                      borderBottomRightRadius="6px"
                    />
                  )}
                  <Tooltip
                    variant="dark"
                    label={t('toolbar.openStudentPage', {
                      name: option.title,
                      interpolation: { escapeValue: false },
                    })}
                    aria-hidden={true}
                  >
                    <Flex flex="1" px="6" py="10px">
                      <Text variant="s2" wordBreak="break-word" maxW="290px">
                        {option.title}
                      </Text>
                    </Flex>
                  </Tooltip>
                </MenuItem>
              ))}
            </Box>
          </MenuGroup>
          {isStudentView && (
            <Flex px="6" pt="4" pb="2">
              <Button
                variant="outline"
                size="sm"
                w="full"
                rightIcon={<Icon name="arrowRightOutlined" />}
                onClick={setTeacherView}
              >
                {t('toolbar.viewTeacherContentBtn')}
              </Button>
            </Flex>
          )}
        </Flex>
      </MenuList>
    </Menu>
  );
};

export default StudentPageSelect;
