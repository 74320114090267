export const generateQuestionHeaders = (reportId: string) => {
  const learnosityQuestions = document
    .getElementById(reportId)
    ?.querySelectorAll('.learnosity-question-container');

  if (!learnosityQuestions) {
    return;
  }

  [...learnosityQuestions].forEach((question, index) => {
    const container =
      question?.querySelector('.lrn_question') ||
      question?.querySelector('.lrn_response_wrapper');

    if (!container) {
      return;
    }

    const header = document.createElement('div');
    header.classList.add('learnosity-question-header');

    const questionTag = document.createElement('div');
    const questionTagContent = document.createElement('span');
    questionTag.appendChild(questionTagContent);
    questionTagContent.textContent = `Question ${index + 1}`;

    questionTag.classList.add('learnosity-tag');
    questionTag.classList.add('learnosity-tag-primary');
    header.appendChild(questionTag);

    container?.parentNode?.insertBefore(header, container);
  });
};
