import {
  FrameOptions,
  createEmbeddingContext,
} from 'amazon-quicksight-embedding-sdk';
import React from 'react';
import {
  GetDashboardEmbedUrlQuery,
  useGetDashboardEmbedUrlQuery,
} from '@lon/shared/requests';
import {
  GetDashboardEmbedUrlForAnonymousUserQuery,
  useGetDashboardEmbedUrlForAnonymousUserQuery,
} from '@lon/shared/requests';

type UseQuicksightReportParams = {
  districtId: string;
  schools?: string[];
  userId?: string;
  dateStart?: string;
  dateEnd?: string;
  state?: string;
};

type UseQuicksightReport = (opts: {
  dashboardId: string | undefined;
  ref: React.MutableRefObject<HTMLDivElement | null>;
  visualID?: string;
  sheetID?: string;
  isAnonymousUser?: boolean;
  params?: UseQuicksightReportParams;
  options?: Pick<FrameOptions, 'height'>;
}) => void;

const prepareParams = (params?: UseQuicksightReportParams): string => {
  let paramsArr = [];

  if (params?.districtId) {
    paramsArr.push(`p.districtId=${params.districtId}`);
  }

  if (params?.schools) {
    const schools = params.schools.map(
      (school: string) => `p.schoolId=${school}`
    );
    paramsArr = [...paramsArr, ...schools];
  }

  if (params?.userId) {
    paramsArr.push(`p.userId=${params.userId}`);
  }

  if (params?.dateStart) {
    paramsArr.push(`p.dateStart=${params.dateStart}`);
  }

  if (params?.dateEnd) {
    paramsArr.push(`p.dateEnd=${params.dateEnd}`);
  }

  if (params?.state) {
    paramsArr.push(`p.state=${params.state}`);
  }

  return paramsArr.join('&');
};

export const useQuicksightReport: UseQuicksightReport = ({
  dashboardId,
  visualID,
  sheetID,
  ref,
  isAnonymousUser = true,
  params,
  options,
}) => {
  const handleComplete = async (
    data: GetDashboardEmbedUrlForAnonymousUserQuery | GetDashboardEmbedUrlQuery
  ) => {
    const context = await createEmbeddingContext();
    let dashboardUrl = (data as any)?.[
      isAnonymousUser
        ? 'dashboardEmbedUrlForAnonymousUser'
        : 'dashboardEmbedUrl'
    ].EmbedUrl;
    const parameters = prepareParams(params);

    if (parameters) {
      dashboardUrl = `${dashboardUrl}#${parameters}`;
    }

    const payload = {
      ...options,
      url: dashboardUrl || '',
      resizeHeightOnSizeChangedEvent: true,
      container: ref.current as HTMLDivElement,
      width: '100%',
    };

    if (sheetID && visualID) {
      await context.embedVisual(payload);
    } else {
      await context.embedDashboard(payload);
    }

    return context;
  };

  useGetDashboardEmbedUrlForAnonymousUserQuery({
    fetchPolicy: 'no-cache',
    variables: {
      dashboardID: dashboardId || '',
      sheetID: sheetID,
      visualID: visualID,
    },
    skip: !dashboardId || !isAnonymousUser,
    onCompleted: handleComplete,
  });

  useGetDashboardEmbedUrlQuery({
    fetchPolicy: 'no-cache',
    variables: {
      dashboardID: dashboardId || '',
      sheetID: sheetID || '',
      visualID: visualID || '',
    },
    skip: !dashboardId || isAnonymousUser,
    onCompleted: handleComplete,
  });
};
