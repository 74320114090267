import React, { Dispatch, SetStateAction, createContext } from 'react';
import { AclPermission, WorkingLocationContext } from '@lon/shared/types';

export const AclContext = React.createContext<{
  value: AclPermission[];
  loading: boolean;
}>({
  value: [],
  loading: true,
});

export const CurrentSchoolContext = createContext<{
  currentSchoolId?: string;
  setCurrentSchoolId: (schoolId: string) => void;
}>({
  currentSchoolId: undefined,
  setCurrentSchoolId: (schoolId) => {},
});

export const WorkingLocation = createContext<WorkingLocationContext>({
  application: undefined,
  currentSchoolId: undefined,
  setCurrentSchoolId: (schoolId) => {},
  permissions: [],
  schoolIds: [],
  userType: undefined,
});

export const FormMeta = createContext<{
  needFormPrompt: React.MutableRefObject<boolean>;
}>({
  needFormPrompt: { current: false },
});

export const StudentGradeContext = createContext(
  {} as unknown as {
    isStudentBelowK3: boolean | null;
  }
);

export const PageContentContext = createContext<{
  pageContentRef: React.MutableRefObject<HTMLDivElement | null>;
}>({
  pageContentRef: { current: null },
});

export type TabArgs = {
  editingRows: string[];
  setEditingRows: Dispatch<SetStateAction<string[]>>;
  error?: boolean;
  setError?: Dispatch<SetStateAction<boolean>>;
};
export const TabContext = React.createContext<TabArgs>({
  editingRows: [],
  setEditingRows: () => {},
  error: false,
  setError: () => {},
});
