import { Flex, VisuallyHidden } from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Pagination } from '@lon/shared/components';
import {
  useDebounceValue,
  useGetSchoolIdsForPlanner as useGetSchoolIds,
  useQueryParams,
  useQueryWithPagination,
  useThemeStyleValue,
} from '@lon/shared/hooks';
import { useGetScopesQuery } from '@lon/shared/requests';
import { usePrevious } from '@lon/suit/hooks';
import { GetScopesQuery } from '@lon/suit/types';
import { RefetchContext, listConstants, listTypes } from './duck';
import { List } from './components';

const ScopesList: React.FC<listTypes.ScopesListProps> = ({
  type,
  gridGap,
  backgroundColor = 'blue.75',
  itemPath,
}) => {
  const [params] = useQueryParams();
  const { projectId } = useParams();
  const { t } = useTranslation();

  const debouncedSearch = useDebounceValue(params.search || '', 500);
  const [attempts, setAttempts] = useState<number>(0);
  const prevAttempts = usePrevious(attempts);
  const accessibleSchoolIds = useGetSchoolIds();

  const { data, loading, refetch } = useQueryWithPagination({
    queryHook: useGetScopesQuery,
    queryOptions: {
      variables: {
        schoolIds: accessibleSchoolIds,
        search: debouncedSearch,
        filter: {
          ...params.filters,
          projectId,
        },
      },
      skip: !accessibleSchoolIds?.length,
    },
    defaultPaginationSize: listConstants.DEFAULT_ITEMS_COUNT,
  });

  const refetchValues = useMemo(
    () => ({
      refetch,
      attempts: prevAttempts,
      setAttempts,
    }),
    [refetch, prevAttempts, setAttempts]
  );

  const scopes = (data as GetScopesQuery)?.scopes?.collection;

  return (
    <RefetchContext.Provider value={refetchValues}>
      <VisuallyHidden role="status" aria-live="polite">
        {loading ? t('scopes.loadMessage') : ''}
      </VisuallyHidden>
      <VisuallyHidden role="status" aria-live="polite">
        {!loading
          ? t('scopes.countOfResults', {
              count: data?.scopes.pageInfo?.totalCount || 0,
            })
          : ''}
      </VisuallyHidden>
      <Flex
        direction="column"
        grow="1"
        justify="space-between"
        backgroundColor={useThemeStyleValue(backgroundColor, 'secondary.950')}
        pb={{ md: 6, lg: 6, b1281: 0.5 }}
      >
        <List
          data={scopes}
          loading={loading}
          skeletonsCount={listConstants.DEFAULT_ITEMS_COUNT}
          type={type}
          gridGap={gridGap}
          itemPath={itemPath}
        />
        <Pagination
          total={data?.scopes.pageInfo?.totalCount || 0}
          paginationWrapperProps={{ marginTop: 'auto' }}
          initialPageSize={listConstants.DEFAULT_ITEMS_COUNT}
          paginationConfig={listConstants.paginationConfig}
        />
      </Flex>
    </RefetchContext.Provider>
  );
};

export default ScopesList;
