import {
  Box,
  Icon as ChakraIcon,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import clsx from 'clsx';
import { sanitize } from 'dompurify';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { closeOutlined } from '@lon/shared/assets';
import { Icon } from '@lon/shared/components';
import { sxLightScrollBar } from '@lon/shared/constants';
import { StudentGradeContext } from '@lon/shared/contexts';
import { IdPart } from '@lon/shared/enums';
import { useThemeStyleValue } from '@lon/shared/hooks';
import { useGetTeacherQuery } from '@lon/shared/requests';
import { types } from './duck';
import styles from './AssignmentNoteModal.module.css';

const AssignmentNoteModal: React.FC<types.AssignmentNoteModalProps> = ({
  studentName,
  teacherId,
  note,
  isStudent,
}) => {
  const { t } = useTranslation();
  const { isStudentBelowK3 } = React.useContext(StudentGradeContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [clicked, setClicked] = React.useState(false);

  const { data: teacherData, loading: teacherLoading } = useGetTeacherQuery({
    variables: {
      id: `${IdPart.Teachers}${teacherId}`,
    },
    skip: !(isOpen && isStudent && teacherId),
  });
  const teacherName = `${teacherData?.teacher?.firstName} ${teacherData?.teacher?.lastName}`;

  return (
    <>
      <Tooltip
        variant="dark"
        label={sanitize(
          t(
            `assignments.${
              isStudent ? 'studentNoteTooltip' : 'teacherNoteTooltip'
            }`,
            { name: studentName }
          )
        )}
      >
        <IconButton
          variant="empty"
          size="md"
          aria-label={t('assignmentNoteModal.showNote')}
          color={useThemeStyleValue('primary.800', 'white')}
          h={11}
          icon={
            <Icon
              name={isStudentBelowK3 ? 'commentK2' : 'comment'}
              className={clsx(styles.icon, {
                [styles.iconK2]: isStudentBelowK3,
              })}
            />
          }
          {...(isStudentBelowK3 && { borderRadius: '10px' })}
          onMouseDown={(e) => {
            if (e.currentTarget !== document.activeElement) {
              setClicked(true);
            }
          }}
          onKeyDown={() => {
            if (clicked) {
              setClicked(false);
            }
          }}
          onClick={(e) => {
            if (clicked) {
              e.currentTarget.blur();
            }
            onOpen();
          }}
        />
      </Tooltip>
      <Modal
        variant="rounded"
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent data-focus-visible-disabled maxWidth="600px">
          <ModalHeader flexDirection="column" p="0" borderBottom="none">
            <Flex
              w="full"
              align="center"
              justify="space-between"
              gap="6"
              py="4"
              px="8"
              borderBottom="1px solid #e1e7f0"
            >
              {isStudent
                ? t('assignmentNoteModal.studentHeader')
                : t('assignmentNoteModal.teacherHeader')}
              <IconButton
                aria-label={t('assignmentNoteModal.closeModal')}
                size="md"
                variant="inverted"
                onClick={onClose}
                icon={<ChakraIcon as={closeOutlined} w="24px" h="24px" />}
              />
            </Flex>
            <Box
              w="full"
              py="2"
              px="8"
              boxShadow="0px 2px 7px 0px rgba(43, 54, 70, 0.10)"
            >
              <Text variant="n1">
                {teacherLoading ? (
                  <Skeleton
                    my="5px"
                    h={isStudentBelowK3 ? '26px' : '16px'}
                    w="180px"
                    borderRadius={isStudentBelowK3 ? '10px' : 0}
                  />
                ) : (
                  sanitize(
                    t(
                      `assignmentNoteModal.${
                        isStudent ? 'studentSubHeader' : 'teacherSubHeader'
                      }`,
                      {
                        target: isStudent ? teacherName : studentName,
                        interpolation: {
                          escapeValue: false,
                        },
                      }
                    )
                  )
                )}
              </Text>
            </Box>
          </ModalHeader>
          <ModalBody css={sxLightScrollBar} py="8">
            <Box
              bgColor="secondary.50"
              borderRadius="40px"
              borderTopLeftRadius="0"
              p="8"
              whiteSpace="break-spaces"
            >
              <Text variant="s3">{note}</Text>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AssignmentNoteModal;
