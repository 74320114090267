import { SharedPages, TeacherPages } from '@suit/pages';
import { t } from 'i18next';
import React from 'react';
import { Route, createRoutesFromElements } from 'react-router-dom';
import { NotFound, ProtectedRoute } from '@lon/shared/components';
import { isFloridaReviewEnv, isReviewSite } from '@lon/shared/constants';
import { ApplicationEnum, DistrictPermission } from '@lon/shared/enums';
import { ErrorBoundary, TestError } from '@lon/shared/pages';
import { lazyWithRetry } from '@lon/shared/utils';
import { routes } from '@lon/suit/configs';

const App = lazyWithRetry(() => import('../app/App'), 'App');

const TeacherRouter = createRoutesFromElements(
  <Route
    path={routes.home}
    element={<App />}
    errorElement={<ErrorBoundary application={ApplicationEnum.TEACHER_SUIT} />}
  >
    <Route index element={<TeacherPages.Dashboard />} />
    <Route path={routes.additionalResources.help.root}>
      <Route index element={<SharedPages.HelpPage />} />
      <Route
        path={routes.additionalResources.help.details}
        element={<SharedPages.HelpPageDetails />}
      />
    </Route>

    <Route path={routes.additionalResources.streaming.root}>
      <Route index element={<TeacherPages.Streaming />} />
      <Route
        path={routes.additionalResources.streaming.show}
        element={<TeacherPages.StreamingContent />}
      />
    </Route>

    <Route path={routes.scopes.root}>
      <Route index element={<TeacherPages.Scopes />} />
      <Route
        path={routes.scopes.projectScopes}
        element={<TeacherPages.Scopes />}
      />
      <Route path={routes.scopes.show} element={<TeacherPages.Scope />}>
        <Route index element={<TeacherPages.Element />} />
        <Route
          path={routes.scopes.viewAll}
          element={<TeacherPages.ScopeSection />}
        />
        <Route
          path={routes.scopes.section}
          element={<TeacherPages.ScopeSection />}
        />
        <Route
          path={routes.scopes.element}
          element={<TeacherPages.Element />}
        />
      </Route>
    </Route>
    {!isReviewSite() && (
      <Route path={routes.navigation} element={<SharedPages.Navigation />} />
    )}
    <Route
      path={routes.teacherResources.root}
      element={<TeacherPages.Resources />}
    >
      <Route index element={<TeacherPages.ResourcesPage />} />
      <Route
        path={routes.teacherResources.resourceSections}
        element={<TeacherPages.ResourceSectionsList />}
      >
        <Route index element={<TeacherPages.ResourceSectionElementList />} />
        <Route
          path={routes.teacherResources.showResourceContent}
          element={<TeacherPages.ResourceContent />}
        />
      </Route>
    </Route>
    <Route
      path={routes.coding.root}
      element={
        <ProtectedRoute
          permission={DistrictPermission.TEACHER_SUIT}
          module="coding"
          operation="view"
        >
          <SharedPages.Coding />
        </ProtectedRoute>
      }
    />
    <Route
      path={routes.assessments.root}
      element={<TeacherPages.Assessments />}
    >
      <Route index element={<TeacherPages.AssessmentsList />} />
      <Route
        path={routes.assessments.show}
        element={<TeacherPages.Assessment />}
      />
    </Route>
    {!isReviewSite() && (
      <Route
        path={routes.classes.root}
        element={<TeacherPages.Classes />}
        handle={{
          crumb: () => t('breadcrumbs.classes'),
        }}
      >
        <Route
          index
          element={<TeacherPages.ClassesList />}
          handle={{
            crumb: () => t('breadcrumbs.list'),
          }}
        />
        <Route
          path={routes.classes.assignments.root}
          element={<TeacherPages.Assignments />}
        >
          <Route index element={<TeacherPages.AssignmentsList />} />
          <Route
            path={routes.classes.assignments.view}
            element={<TeacherPages.Assignment />}
          />
          <Route
            path={routes.classes.assignments.viewList}
            element={<TeacherPages.AssignmentMultiple />}
          />
          <Route
            path={routes.classes.assignments.gradeByQuestion}
            element={<TeacherPages.StudentAssignmentDetails />}
          />
        </Route>
        <Route
          path={routes.classes.students}
          index
          element={<TeacherPages.Students />}
          handle={{
            crumb: () => t('breadcrumbs.students'),
          }}
        />
        <Route
          path={routes.classes.studentAssignments}
          index
          element={<TeacherPages.StudentAssignments />}
        />
        <Route
          path={routes.classes.studentAssignmentDetails}
          index
          element={<TeacherPages.StudentAssignmentDetails />}
        />
        <Route
          path={routes.classes.districtAssessmentGrading}
          index
          element={<TeacherPages.StudentAssignmentDetails isDa />}
        />
        <Route
          path={routes.classes.reportDashboard.standard}
          index
          element={<TeacherPages.ReportDashboard.StandardReports />}
        />
        <Route
          path={routes.classes.reportDashboard.districtAssessments}
          index
          element={<TeacherPages.ReportDashboard.DistrictAssessmentsReports />}
        />
        <Route
          path={routes.classes.reportDashboard.districtAssessment}
          index
          element={<TeacherPages.ReportDashboard.DistrictAssessmentDetails />}
        />
        <Route
          path={routes.classes.reportDashboard.districtAssessmentGradeByQuestion}
          element={<TeacherPages.StudentAssignmentDetails isDa />}
        />
        <Route
          path={routes.classes.createStudent}
          index
          element={
            <ProtectedRoute
              permission={DistrictPermission.TEACHER_SUIT}
              module="students"
              operation="create"
            >
              <TeacherPages.CreateStudent />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.classes.editStudent}
          index
          element={<TeacherPages.EditStudent />}
        />
        <Route
          path={routes.classes.create}
          element={
            <ProtectedRoute
              permission={DistrictPermission.TEACHER_SUIT}
              module="classes"
              operation="create"
            >
              <TeacherPages.ClassSettings />
            </ProtectedRoute>
          }
          handle={{
            crumb: () => t('breadcrumbs.create'),
          }}
        />
        <Route
          path={routes.classes.show.root}
          element={<TeacherPages.Class />}
          handle={{
            crumb: (params: { classId: string }) => params.classId,
          }}
        >
          <Route
            path={routes.classes.show.roster.root}
            element={<TeacherPages.ClassRoaster />}
          >
            <Route
              index
              element={<TeacherPages.ClassRoasterTabs.Students />}
              handle={{
                crumb: () => t('breadcrumbs.students'),
              }}
            />
            <Route
              path={routes.classes.show.roster.manageStudents}
              element={<TeacherPages.ClassRoasterTabs.ManageStudents />}
              handle={{
                crumb: () => t('breadcrumbs.manageStudents'),
              }}
            />
            <Route
              path={routes.classes.show.roster.manageSubgroups.root}
              element={<TeacherPages.ClassRoasterTabs.ManageSubgroups />}
              handle={{
                crumb: () => t('breadcrumbs.manageSubgroups'),
              }}
            >
              <Route
                path={routes.classes.show.roster.manageSubgroups.subgroup}
                element={<TeacherPages.ClassRoasterTabs.SubgroupStudents />}
              />
            </Route>
          </Route>
          <Route
            path={routes.classes.show.settings}
            element={<TeacherPages.ClassSettings />}
            handle={{
              crumb: () => t('breadcrumbs.classSettings'),
            }}
          />
          <Route
            path={routes.classes.show.gradebook}
            element={<TeacherPages.GradeBook />}
          />
        </Route>
      </Route>
    )}
    {!isReviewSite() && (
      <Route path={routes.planner.root} element={<TeacherPages.Planner />}>
        <Route index element={<TeacherPages.Calendar />} />
        <Route
          path={routes.planner.pacingGuide}
          element={<TeacherPages.PacingGuide />}
        >
          <Route
            path={routes.planner.selectedPacingGuide}
            index
            element={<TeacherPages.PacingGuide />}
          />
        </Route>
        <Route path={routes.planner.plans} element={<TeacherPages.Plans />}>
          <Route
            path={routes.planner.selectedPlan}
            index
            element={<TeacherPages.Plans />}
          />
        </Route>
      </Route>
    )}
    <Route
      path={routes.additionalResources.root}
      element={<TeacherPages.AdditionalResources />}
    />
    {/* <Route
      path={routes.additionalResources.root}
      element={<TeacherPages.ComingSoon pageTitle="More" />}
    /> */}
    {isFloridaReviewEnv() && (
      <Route
        path={routes.previewStudentPlatform}
        element={<SharedPages.PreviewStudentPlatform />}
      />
    )}
    <Route path={routes.files.root}>
      <Route index element={<TeacherPages.Files />} />
      <Route path={routes.files.show} element={<TeacherPages.PdfPreview />} />
    </Route>
    <Route path={routes.testError} element={<TestError />} />
    <Route path="/not-found" element={<NotFound withoutSidebar />} />
    <Route
      path={routes.googleClassRoom.root}
      element={<SharedPages.GoogleClassroom />}
    />
    <Route path="*" element={<NotFound withoutSidebar />} />
  </Route>
);

export default TeacherRouter;
