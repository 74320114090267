import { Flex, Link, Text } from '@chakra-ui/react';
import React from 'react';
import { isProdCluster } from '@lon/shared/constants';
import { getEnvVariable } from '@lon/shared/utils';


export const stemDomain = getEnvVariable(
  'NX_STEMSCOPES_URL',
  'https://dev2019.stemscopes.com'
);

export const prodStemDomain = 'https://www.acceleratelearning.com';

const Footer = () => {
  return (
    <Flex
      borderRadius="6px"
      py={4}
      px={4}
      m={6}
      mt={0}
      alignItems="center"
      justifyContent="center"
      color="primary.800"
      boxShadow="0 5px 20px 12px rgba(43,54,70,.07)"
    >
      <Text mr={2}>© 2024</Text>
      <Link
        variant="primary"
        href={isProdCluster() ? prodStemDomain : `${stemDomain}/home.php`}
      >
        Accelerate Learning Inc.
      </Link>
      <Link
        variant="primary"
        ml={8}
        href={isProdCluster() ? prodStemDomain : `${stemDomain}/home.php`}
      >
        Terms and Conditions
      </Link>
    </Flex>
  );
};

export default Footer;
