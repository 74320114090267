import chmln from '@chamaeleonidae/chmln';
import Cookies from 'js-cookie';
import { isEmpty } from 'lodash-es';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '@lon/shared/hooks';
import { chamToken } from './constants';

export const useChameleon = () => {
  const chameleonRef = useRef(false);
  const [{ user }] = useAuth();

  useEffect(() => {
    if (isEmpty(user)) {
      return;
    }

    let role = '';
    user?.userRoles?.forEach((r) => {
      role = role += r.code;
    });

    if (!chameleonRef.current && !user.impersonated) {
      chameleonRef.current = true;

      chmln.init(chamToken, { fastUrl: 'https://fast.chameleon.io/' });

      chmln.identify(user?.userId || '', {
        email: user?.email || '',
        name: `${user?.firstName} ${user?.lastName}` || '',
        role: role,
        product: 'TempSubscriptionInfo',
        district: user?.districtId || '',
        state: user?.state || '',
      });
    }
  }, []);
};

export const useGoogleTranslateLanguageRefresher = () => {
  const location = useLocation();

  useEffect(() => {
    const langPref = getCookieValue('langPref');
    const googleTrans = getCookieValue('googtrans');

    if (langPref && googleTrans) {
      const googTransLang = googleTrans.split('/').pop();

      if (langPref === googTransLang) {
        setTimeout(() => {
          reloadGoogleTranslate(langPref);
        }, 1000);
      }
    }
  }, [location]);
};

const getCookieValue = (name: string) => {
  return Cookies.get(name);
};

const reloadGoogleTranslate = (langPref: string) => {
  const selectElement = document.querySelector(
    '#google_translate_element select'
  ) as HTMLSelectElement;

  if (selectElement) {
    selectElement.value = langPref;
    selectElement.dispatchEvent(new Event('change'));
  }
};
