import { updatePasswordUtils } from '../../../../../../../../../../components/src/modals/update-password-form/duck';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Text,
  VStack,
} from '@chakra-ui/react';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { FormProvider, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Icon,
  PasswordInput,
  PasswordRequirement,
} from '@lon/shared/components';
import { generatePassword } from '@lon/shared/utils';
import { useAuth } from '@lon/shared/hooks';
import { ShowPasswordMethod } from './duck/types';

const ResetPassword = () => {
  const [{ user }] = useAuth();
  const [expanded, setExpanded] = useState<number>(-1);
  const { t } = useTranslation();
  const [passwordErrors, setPasswordErrors] = useState<number[] | null>(null);

  const form = useFormContext();

  const passwordRef = useRef<ShowPasswordMethod>(null);

  const handleClickGenerate = () => {
    form.setValue('newPassword', generatePassword(16), {
      shouldDirty: true,
    });

    if (passwordRef.current?.showPassword) {
      passwordRef.current?.showPassword();
    }
    form.trigger('newPassword');
  };

  const formValuePassword = useWatch({ name: 'newPassword' });

  useEffect(() => {
    const passwordErrors = updatePasswordUtils.validateNewPassword(
      formValuePassword,
      16
    );
    formValuePassword && setPasswordErrors(passwordErrors);
  }, [formValuePassword]);

  useEffect(() => {
    if (user?.firstName && user?.lastName) {
      form.reset({
        firstName: user.firstName,
        lastName: user.lastName,
      });
    }
  }, [user]);

  const habdleStayWithCurrentPassword = () => {
    form.reset({});
  };

  return (
    <VStack
      align="left"
      w="full"
      py={6}
      justify="space-between"
      px={{ base: 6, lg: '150px' }}
      borderBottom="1px solid"
      borderColor="secondary.200"
    >
      <Text variant="h6" fontWeight={400}>
        {t('userSettings.credentials.security')?.toUpperCase()}
      </Text>

      <Accordion
        allowToggle
        onChange={(idx: number) => {
          setExpanded(idx);
        }}
      >
        <AccordionItem border="none">
          <Flex
            gap={5}
            direction={{ base: 'column', md: 'row' }}
            justify="space-between"
            w="full"
          >
            <Text
              variant="s3"
              fontStyle="italic"
              color="primary.400"
              whiteSpace="pre-line"
            >
              {t('userSettings.credentials.message')}
            </Text>
            <AccordionButton
              w="full"
              maxW="203px"
              alignSelf={{ base: 'start', md: 'initial' }}
              as={Button}
              variant="outline"
              size="sm"
              onClick={habdleStayWithCurrentPassword}
              leftIcon={
                <Icon name={expanded === -1 ? 'editOutlined' : 'undo'} />
              }
              _hover={{ backgroundColor: 'primary.600' }}
            >
              {t(
                `userSettings.credentials.${
                  expanded === -1 ? 'restPassword' : 'stayCurrent'
                }`
              )}
            </AccordionButton>
          </Flex>
          <AccordionPanel p={0} pt={6}>
            <Flex
              direction={{ base: 'column', md: 'row' }}
              gap={{ base: 6, md: 8 }}
            >
              <Box w={{ xs: 'full', md: '388px' }} p={6} bg="secondary.50">
                <Text variant="h6" pb="1.25rem">
                  {t('updatePassword.newPasswordRequirements')}
                </Text>
                {[1, 8, 3, 4, 5, 9].map((requirement) => (
                  <PasswordRequirement
                    key={requirement}
                    requirement={requirement}
                    type={
                      !formValuePassword
                        ? 'default'
                        : passwordErrors?.includes(requirement)
                        ? 'error'
                        : 'success'
                    }
                  />
                ))}
                <Text mt={3} variant="n3">
                  {t('updatePassword.newPasswordNote')}
                </Text>
              </Box>
              <Box flexGrow={1}>
                <FormProvider {...form}>
                  <PasswordInput
                    autoComplete="off"
                    label={t('updatePassword.currentPasswordLabel')}
                    formLabelProps={{
                      color: 'primary.800 !important',
                    }}
                    name="currentPassword"
                    isRequired
                    placeholder={t('updatePassword.currentPasswordPlaceholder')}
                    inputLayoutProps={{ mt: '4' }}
                  />
                  <Box position="relative">
                    <PasswordInput
                      autoComplete="off"
                      ref={passwordRef}
                      label={t('updatePassword.newPasswordLabel')}
                      formLabelProps={{
                        color: 'primary.800 !important',
                      }}
                      name="newPassword"
                      isRequired
                      placeholder={t('updatePassword.newPasswordPlaceholder')}
                      inputLayoutProps={{ mt: '4' }}
                    />
                    <Button
                      size="sm"
                      variant="ghost"
                      position="absolute"
                      top="-4px"
                      right={0}
                      p={0}
                      textDecoration="underline"
                      onClick={handleClickGenerate}
                    >
                      {t('updatePassword.generate')}
                    </Button>
                  </Box>
                  <PasswordInput
                    autoComplete="off"
                    label={t('updatePassword.confirmPasswordLabel')}
                    formLabelProps={{
                      color: 'primary.800 !important',
                    }}
                    name="repeatedPassword"
                    isRequired
                    placeholder={t('updatePassword.confirmPasswordPlaceholder')}
                    inputLayoutProps={{ mt: '5', mb: '5' }}
                  />
                </FormProvider>
              </Box>
            </Flex>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </VStack>
  );
};

export default ResetPassword;
