import { ErrorMessage } from '../error-message';
import { Flex, Image, Text } from '@chakra-ui/react';
import { FC, PropsWithChildren } from 'react';
import { useMediaQuery, useThemeStyleValue } from '@lon/shared/hooks';
import { types } from './duck';

const NoContent: FC<PropsWithChildren<types.NoContent>> = ({
  actions,
  title,
  description,
  bodyWrapperProps,
  contentProps,
  textWrapperProps,
  children,
  isMobile: isMobileProp,
}) => {
  const [isMobile] = useMediaQuery('(max-width: 767px)');
  const imagePath = useThemeStyleValue(
    'suit/assets/cloud.svg',
    'suit/assets/clouds_contrast.svg'
  );

  return (
    <ErrorMessage
      fullWidth
      showCloudBackground={isMobileProp ?? isMobile}
      title={<></>}
      bodyWrapperProps={bodyWrapperProps}
      body={
        <Flex
          data-testid="empty-list"
          position="relative"
          justify="center"
          maxW="500px"
          w="100%"
          py="10"
          border={isMobile ? 'none' : '1px solid #62B4DD'}
          borderRadius="20px 20px 20px 0px"
          mt="70px"
          px={{
            b744: 24,
          }}
          {...contentProps}
        >
          <Flex
            direction="column"
            align="center"
            position="relative"
            zIndex="1"
            gap="2.5"
            px="2"
            {...textWrapperProps}
          >
            {children ? (
              children
            ) : (
              <>
                <Text variant="h5">{title}</Text>
                <Text variant="n3">{description}</Text>
              </>
            )}
          </Flex>
          {(isMobileProp ?? !isMobile) && (
            <>
              <Image
                bottom="0"
                left="0"
                position="absolute"
                src={imagePath}
                alt=""
              />
              <Image
                bottom="-210px"
                left="-117px"
                position="absolute"
                src="suit/assets/rocket-empty-message.svg"
                alt=""
              />
            </>
          )}
        </Flex>
      }
      actions={actions}
    />
  );
};

export default NoContent;
