import { utils } from '../../../teacher/classes/pages/assignment/duck';
import {
  gradeColumn,
  nameColumnText,
  statusColumn,
} from '../../../teacher/classes/pages/assignment/duck/utils';
import { AssignmentHeader } from '../../components';
import Footer from '../../components/footer/Footer';
import { Box, Button, Flex, Tag, Text } from '@chakra-ui/react';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { getQuery } from '@lon/api';
import { Icon, Spinner } from '@lon/shared/components';
import { sxLightScrollBar } from '@lon/shared/constants';
import { WorkingLocation } from '@lon/shared/contexts';
import { IdPart } from '@lon/shared/enums';
import { useQueryParams } from '@lon/shared/hooks';
import { Table } from '@lon/shared/modules/table';
import {
  useGetAssignmentClassQuery,
  useGetAssignmentQuery,
} from '@lon/shared/requests';
import { routes } from '@lon/suit/configs';

const ShowAssignment = () => {
  const { t } = useTranslation();
  const { assignmentId } = useParams();
  const [queryParams] = useQueryParams();
  const navigate = useNavigate();
  const toolConsumerCode = (queryParams.get('tool_consumer_code') ||
    '') as string;
  const { currentSchoolId: schoolId } = useContext(WorkingLocation);
  const { data, loading } = useGetAssignmentQuery({
    variables: {
      // TODO remove replace when backend fix assignmentId
      id: assignmentId?.replace('_', '#'),
      schoolId,
    },
    skip: !schoolId,
  });

  const { data: classData, loading: classLoading } = useGetAssignmentClassQuery(
    {
      variables: {
        classId: `${IdPart.Classes}${data?.assignment?.classId}`,
      },
      skip: !data?.assignment?.classId,
    }
  );

  const assignment = useMemo(
    () => utils.getAssignmentData(data, classData),
    [classData, data]
  );

  const columns = useMemo(
    () => [nameColumnText, statusColumn, gradeColumn],
    []
  );

  if (loading || classLoading) {
    return <Spinner />;
  }

  return (
    <Flex direction="column" height="100vh">
      <AssignmentHeader
        loading={loading}
        elementName={
          data?.assignment?.assignmentSource?.element?.elementName || ''
        }
        sectionName={
          data?.assignment?.assignmentSource?.element?.sectionName || ''
        }
        scopeName={data?.assignment?.assignmentSource?.element?.scopeName || ''}
        action={
          toolConsumerCode === 'schoology' && (
            <Button
              size="sm"
              variant="solid"
              alignSelf="center"
              leftIcon={<Icon name="arr-double-left-outlined" />}
              onClick={() => {
                navigate(-1);
              }}
            >
              Assignment
            </Button>
          )
        }
      />
      <Box p={8} overflow="auto" sx={sxLightScrollBar} flexGrow="1">
        <Table
          data={assignment as any}
          loading={loading}
          columns={columns}
          enableClientSideSorting
          enableStickyColumns="first"
          enableVerticalBorders
          enableYScroll={false}
          headerProps={{
            bgColor: 'white',
            color: 'primary.800',
            whiteSpace: 'pre-wrap',
          }}
          renderGroupActions={(table) => (
            <Flex px={8} py={5} justify="space-between" align="center">
              <Box display="flex" gap={2} alignItems="center">
                <Text variant="h5" color="primary.800">
                  {t('assignments.tableTitle')}
                </Text>
                <Tag variant="primary" fontWeight={600}>
                  <Text variant="s2" color="primary.800">
                    {Object.keys(table.getRowModel().rowsById).length}
                  </Text>
                </Tag>
              </Box>
            </Flex>
          )}
          renderRowActions={({ row: { original } }) => {
            return (
              <Button
                size="sm"
                variant="ghost"
                leftIcon={<Icon name="eye-outlined" />}
                onClick={() => {
                  navigate(
                    `${generatePath(routes.lti.gradeAssignment, {
                      studentId: original?.studentId,
                      assignmentId: original?.assignmentId,
                      sessionId: original?.id,
                    })}?${getQuery({ ...queryParams })}`
                  );
                }}
              >
                <Text variant="bsu">View Results</Text>
              </Button>
            );
          }}
        />
      </Box>

      <Footer />
    </Flex>
  );
};

export default ShowAssignment;
