import {
  Button,
  Menu,
  MenuButton,
  MenuGroup,
  MenuList,
  Tooltip,
} from '@chakra-ui/react';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@lon/shared/components';
import { ActionsContext, ActionsProps, getActionItems } from './duck';

const Actions = forwardRef<HTMLButtonElement, ActionsProps>(
  ({ classItem, isSelectedItem }, ref) => {
    const { t } = useTranslation();
    const actions = getActionItems(!!classItem?.archived);

    return (
      <ActionsContext.Provider
        value={{
          classItem,
        }}
      >
        <Menu variant="actions" autoSelect={false}>
          <Tooltip
            variant="dark"
            label={t('classList.classActions.actionsTooltip')}
          >
            <MenuButton
              ref={ref}
              as={Button}
              tabIndex={isSelectedItem ? 0 : -1}
              size="sm"
              aria-label={t('classList.classActions.label', {
                name: classItem?.name,
              })}
              rightIcon={<Icon name="more-outlined" />}
              variant="ghost"
              gridArea="button"
              onKeyDown={(event) => {
                if (event.code === 'Space') {
                  event.preventDefault();

                  const target = event.currentTarget;

                  setTimeout(() => {
                    target.dispatchEvent(
                      new KeyboardEvent('keydown', {
                        key: 'Enter',
                        bubbles: true,
                      })
                    );
                  }, 100);
                }
              }}
            >
              {t('classList.classActions.title')}
            </MenuButton>
          </Tooltip>
          <MenuList data-focus-visible-disabled minW="285px">
            <MenuGroup title={t('classList.classActions.classRosterGroup')}>
              {actions.classRoaster.map(({ key, Component }) => (
                <Component key={`${key}-${classItem?._id}`} />
              ))}
            </MenuGroup>
            <MenuGroup title={t('classList.classActions.classSettingsGroup')}>
              {actions.classSettings.map(({ key, Component }) => (
                <Component key={key} />
              ))}
            </MenuGroup>
          </MenuList>
        </Menu>
      </ActionsContext.Provider>
    );
  }
);

export default Actions;
