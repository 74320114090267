import { MockedProvider } from '@apollo/client/testing';
import { ChakraProvider } from '@chakra-ui/react';
import { RenderOptions, render } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import { createMemoryHistory } from 'history';
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { initTranslationService, persistor, store } from '@lon/suit/configs';
import { types } from './duck';

initTranslationService();

export const Wrapper: React.FC<types.WrapperProps> = ({
  children,
  mocks,
  history,
}) => {
  return (
    <Router location={history.location} navigator={history}>
      <MockedProvider mocks={mocks} addTypename={false}>
        <HelmetProvider>
          <ChakraProvider>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                {children}
              </PersistGate>
            </Provider>
          </ChakraProvider>
        </HelmetProvider>
      </MockedProvider>
    </Router>
  );
};

export const renderWithProvider = (
  ui: JSX.Element,
  options?: RenderOptions & { mocks?: any[] }
) => {
  const history = createMemoryHistory();

  return {
    user: userEvent.setup(),
    history,
    ...render(ui, {
      wrapper: (props) => (
        <Wrapper {...props} history={history} mocks={options?.mocks} />
      ),
      ...options,
    }),
  };
};

export * from '@testing-library/react';

export { renderWithProvider as render };
