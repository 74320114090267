import { Flex, Text, useRadioGroup } from '@chakra-ui/react';
import { FC, isValidElement, useId } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { RadioCardsProps } from './duck';
import { RadioCard } from './components';

const RadioCards: FC<RadioCardsProps> = ({
  options,
  label,
  description,
  defaultValue,
  name = '',
  value,
  onChange,
  isDisabled,
  isFocusable,
  isNative,
  ...styles
}) => {
  const id = useId();
  const labelId = `group_label_${id}`;

  const { control } = useFormContext();
  const { field } = useController({ name, defaultValue, control });
  const { getRootProps, getRadioProps } = useRadioGroup({
    ...field,
    onChange: onChange || field.onChange,
    value: value || field.value,
    isDisabled,
    isFocusable,
    isNative,
  });

  const group = getRootProps();

  return (
    <Flex
      id={id}
      {...field}
      {...group}
      direction="column"
      aria-labelledby={labelId}
      {...styles}
    >
      {label &&
        (isValidElement(label) ? (
          label
        ) : (
          <Text variant="n1" as="span" id={labelId}>
            {label}
          </Text>
        ))}
      {description &&
        (isValidElement(description) ? (
          description
        ) : (
          <Text variant="s3" as="span" color="primary.400" mt={2} mb={3}>
            {description}
          </Text>
        ))}
      <Flex id={id} {...group}>
        {options.map(({ value, label }) => {
          const radio = getRadioProps({ value });

          return (
            <RadioCard key={value} {...radio}>
              {label}
            </RadioCard>
          );
        })}
      </Flex>
    </Flex>
  );
};

export default RadioCards;
