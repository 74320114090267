import { Box, Flex, Tag, Text, VStack } from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSystemAdminsQuery } from '@lon/shared/requests';
import { RootState } from '@lon/suit/configs';
import { Skeleton } from './components';

const Administrators = () => {
  const { user } = useSelector((state: RootState) => state.auth);
  const { t } = useTranslation();
  const { data, loading } = useSystemAdminsQuery({
    variables: {
      districtUuid: user.districtId,
    },
  });

  const admins = data?.systemAdmins?.collection?.map(
    (v) => `${v?.firstName} ${v?.lastName}`
  );

  return (
    <Box py={6} px={{ base: 6, lg: '150px' }}>
      <VStack align="left" w="full">
        <Text variant="h6" fontWeight={400}>
          {t('userSettings.credentials.admins')?.toUpperCase()}
        </Text>
        <Text
          variant="s3"
          fontStyle="italic"
          color="primary.400"
          whiteSpace="pre-line"
        >
          {t('userSettings.credentials.adminDescription')}
        </Text>
      </VStack>
      <Flex wrap="wrap" gap={2} mt={6}>
        {loading ? (
          <Skeleton />
        ) : (
          admins?.map((name) => (
            <Tag
              key={name}
              size="md"
              bg="primary.30"
              fontWeight={400}
              borderRadius="0.75rem"
              color="primary.700"
              cursor="default"
            >
              <Box pl={2}>{name}</Box>
            </Tag>
          ))
        )}
      </Flex>
    </Box>
  );
};

export default Administrators;
