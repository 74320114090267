import { ApplicationEnum } from '@lon/shared/enums';

export const getLastApplicationByUserId = (userId: string) => {
  const lastApplicationByUserId = JSON.parse(
    localStorage.getItem('lastApplicationByUserId') || '{}'
  );

  return lastApplicationByUserId[userId];
};

export const setLastApplicationByUserId = ({
  userId,
  application,
}: {
  userId: string;
  application: ApplicationEnum;
}) => {
  const lastApplicationByUserId = JSON.parse(
    localStorage.getItem('lastApplicationByUserId') || '{}'
  );

  localStorage.setItem(
    'lastApplicationByUserId',
    JSON.stringify({
      ...lastApplicationByUserId,
      [userId]: application,
    })
  );
};
