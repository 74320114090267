// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ThemeEnum } from '@lon/shared/enums';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { getThemeStyleValue } from '@lon/shared/utils';

export const getButton = (theme: ThemeEnum) => {
  return {
    defaultProps: {
      size: 'md',
      colorScheme: 'primary',
      variant: 'outline',
    },
    baseStyle: {
      fontSize: '1rem',
      lineHeight: '1.25rem',
      fontWeight: 400,
      boxSizing: 'border-box',
      color: 'white',
      borderRadius: '4px',
      background: 'transparent',
      backgroundColor: 'transparent',
      _active: {
        outline: 'none',
        boxShadow: 'none',
      },
      _focus: {
        '&[not(:focus-visible)]': {
          outline: 'none',
          boxShadow: 'none',
        },
      },
      _focusVisible: {
        outline: 'none',
        boxShadow: 'none',
      },
    },
    variants: {
      link: {
        minHeight: '16px',
        color: 'primary.800',

        _focusVisible: {
          boxShadow: '0 0 0 4px var(--chakra-colors-blue-600)',
        },
      },
      solid: {
        backgroundColor: getThemeStyleValue('blue.500', 'white')(theme),
        color: getThemeStyleValue('white', 'secondary.1000')(theme),
        _hover: {
          backgroundColor: getThemeStyleValue(
            'primary.600',
            'secondary.400'
          )(theme),

          _disabled: {
            opacity: 1,
            backgroundColor: 'secondary.300',
            color: 'secondary.100',
          },
        },

        _focusVisible: {
          backgroundColor: getThemeStyleValue('primary.800', 'white')(theme),
          border: `1px solid ${getThemeStyleValue(
            'var(--chakra-colors-primary-800)',
            'var(--chakra-colors-secondary-1000)'
          )(theme)}`,
          boxShadow: `0 0 0 4px ${getThemeStyleValue(
            'var(--chakra-colors-blue-600)',
            'var(--chakra-colors-teal-500)'
          )(theme)}`,
          textDecoration: 'none',
          zIndex: 1,
        },
        _active: {
          backgroundColor: getThemeStyleValue(
            'primary.700',
            'secondary.500'
          )(theme),
          color: getThemeStyleValue('white', 'secondary.1000')(theme),
        },
        _disabled: {
          opacity: 1,
          backgroundColor: 'secondary.300',
          color: 'secondary.100',
        },
      },
      outline: {
        backgroundColor: 'transparent',
        color: getThemeStyleValue('primary.800', 'white')(theme),
        border: `1px solid ${getThemeStyleValue(
          'var(--chakra-colors-primary-800)',
          'white'
        )(theme)}`,
        _hover: {
          backgroundColor: getThemeStyleValue(
            'primary.600',
            'secondary.800'
          )(theme),
          color: 'white',
          border: `1px solid ${getThemeStyleValue(
            'var(--chakra-colors-primary-600)',
            'var(--chakra-colors-secondary-800)'
          )(theme)}`,

          _disabled: {
            opacity: 1,
            border: `1px solid ${getThemeStyleValue(
              'var(--chakra-colors-basic-400)',
              'var(--chakra-colors-secondary-800)'
            )}`,
            backgroundColor: 'transparent',
            color: getThemeStyleValue('secondary.400', 'secondary.800')(theme),
          },
        },

        _focusVisible: {
          backgroundColor: getThemeStyleValue('primary.300', 'none')(theme),
          color: 'white',
          boxShadow: `0 0 0 4px ${getThemeStyleValue(
            'var(--chakra-colors-blue-600)',
            'var(--chakra-colors-teal-500)'
          )(theme)}`,
          borderColor: `${getThemeStyleValue(
            'var(--chakra-colors-primary-800)',
            'var(--chakra-colors-teal-900)'
          )(theme)}`,
          textDecoration: 'none',
        },
        _active: {
          backgroundColor: getThemeStyleValue(
            'primary.700',
            'secondary.700'
          )(theme),
          color: 'white',
          border: `1px solid ${getThemeStyleValue(
            'var(--chakra-colors-primary-700)',
            'var(--chakra-colors-secondary-700)'
          )(theme)}`,
        },

        _disabled: {
          opacity: 1,
          border: `1px solid ${getThemeStyleValue(
            'var(--chakra-colors-basic-400)',
            'var(--chakra-colors-secondary-800)'
          )}`,
          backgroundColor: 'transparent',
          color: getThemeStyleValue('secondary.400', 'secondary.800')(theme),
        },
      },
      solidDark: {
        backgroundColor: 'white',
        color: 'blue.500',
        _hover: {
          backgroundColor: 'primary.100',
          _disabled: {
            backgroundColor: 'primary.400',
            color: 'primary.100',
            opacity: 1,
          },
        },
        _focusVisible: {
          backgroundColor: 'primary.100',
          boxShadow: '0 0 0 4px var(--chakra-colors-blue-600)',
          textDecoration: 'none',
          zIndex: 2,
        },
        _active: {
          backgroundColor: 'primary.200',
        },
        _disabled: {
          backgroundColor: 'primary.400',
          color: 'primary.100',
          opacity: 1,
        },
      },
      empty: {
        backgroundColor: 'transparent',
        color: 'blue.800',
        _hover: {
          backgroundColor: 'primary.50',
          _disabled: {
            color: 'secondary.300',
          },
        },
        _focusVisible: {
          boxShadow: '0 0 0 4px var(--chakra-colors-blue-600)',
          textDecoration: 'none',
          zIndex: 2,
          backgroundColor: 'primary.50',
        },
        _active: {
          backgroundColor: 'primary.700',
          color: 'white',
        },
        _disabled: {
          color: 'secondary.300',
        },
      },
      outlineDark: {
        backgroundColor: 'blue.500',
        border: '1px solid var(--chakra-colors-white)',
        color: 'white',
        _hover: {
          backgroundColor: 'primary.700',
          color: 'white',
          border: '1px solid var(--chakra-colors-blue-500)',
          _disabled: {
            backgroundColor: 'blue.500',
            color: 'primary.200',
            border: '1px solid var(--chakra-colors-primary-200)',
          },
        },
        _focusVisible: {
          backgroundColor: 'blue.500',
          color: 'white',
          border: '1px solid var(--chakra-colors-blue-500)',
          boxShadow: '0 0 0 4px var(--chakra-colors-blue-600)',
          textDecoration: 'none',
        },
        _active: {
          backgroundColor: 'primary.400',
          color: 'white',
          border: '1px solid var(--chakra-colors-primary-400)',
        },
        _disabled: {
          backgroundColor: 'blue.500',
          color: 'primary.200',
          border: '1px solid var(--chakra-colors-primary-200)',
        },
      },
      ghost: {
        px: 2,
        py: 1,
        boxShadow: 'none',
        color: getThemeStyleValue('primary.800', 'white')(theme),

        _focusVisible: {
          boxShadow: getThemeStyleValue(
            '0 0 0 4px var(--calendar-colors-sky-500)',
            '0 0 0 4px var(--chakra-colors-teal-500)'
          )(theme),
          textDecoration: 'none',
          zIndex: 2,
        },

        _hover: {
          background: getThemeStyleValue('blue.150', 'secondary.800')(theme),
          borderColor: 'transparent',
        },

        _active: {
          backgroundColor: getThemeStyleValue(
            'primary.100',
            'secondary.700'
          )(theme),
        },
      },
      ghostDark: {
        px: 2,
        py: 1,
        boxShadow: 'none',
        color: 'white',

        _focusVisible: {
          boxShadow: '0 0 0 4px var(--chakra-colors-blue-600)',
          textDecoration: 'none',
          zIndex: 2,
        },
        _hover: {
          background: 'primary.700',
          borderColor: 'transparent',
        },
        _active: {
          background: 'primary.400',
          borderColor: 'transparent',
        },
        _disabled: {
          opacity: 1,
          color: 'primary.400',
        },
      },
      danger: {
        backgroundColor: 'danger.100',
        color: 'danger.800',
        border: '2px solid var(--chakra-colors-danger-800)',
        _hover: {
          backgroundColor: 'danger.400',
          color: 'danger.800',
          border: '2px solid var(--chakra-colors-danger-400)',
          _disabled: {
            backgroundColor: 'secondary.300',
            color: 'primary.400',
          },
        },
        _focusVisible: {
          backgroundColor: 'danger.400',
          color: 'danger.800',
          boxShadow: '0 0 0 4px var(--chakra-colors-blue-600)',
          border: '2px solid var(--chakra-colors-danger-800)',
          textDecoration: 'none',
        },
        _active: {
          backgroundColor: 'danger.400',
          color: 'danger.800',
          border: '2px solid var(--chakra-colors-danger-400)',
        },
        _disabled: {
          backgroundColor: 'secondary.300',
          color: 'primary.400',
        },
      },
      iconButton: {
        color: 'primary.200',
        backgroundColor: 'transparent',

        _hover: {
          backgroundColor: 'primary.50',
        },

        _focusVisible: {
          boxShadow: '0 0 0 2px var(--chakra-colors-blue-600)',
          backgroundColor: 'primary.50',
        },
      },
      groupButtonActive: {
        w: '38px',
        backgroundColor: getThemeStyleValue('primary.800', 'teal.500')(theme),
        fontSize: 'lg',
        color: getThemeStyleValue('white', 'secondary.1000')(theme),
        border: 'none',

        _hover: {
          backgroundColor: getThemeStyleValue('primary.400', 'teal.600')(theme),
        },

        _focusVisible: {
          boxShadow: getThemeStyleValue(
            '0 0 0 2px var(--chakra-colors-blue-600)',
            '0 0 0 2px var(--chakra-colors-teal-500)'
          )(theme),
          backgroundColor: getThemeStyleValue('primary.400', 'none')(theme),
          border: getThemeStyleValue(
            'none',
            '1px solid var(--chakra-colors-secondary-1000)'
          )(theme),
        },
      },
      groupButton: {
        w: '38px',
        backgroundColor: 'transparent',
        fontSize: 'lg',
        color: getThemeStyleValue('primary.800', 'white')(theme),
        border: getThemeStyleValue('none', '1px solid white')(theme),

        _hover: {
          backgroundColor: getThemeStyleValue(
            'primary.50',
            'secondary.800'
          )(theme),
          borderColor: getThemeStyleValue('primary.50', 'secondary.800')(theme),
        },

        _focusVisible: {
          boxShadow: `0 0 0 2px ${getThemeStyleValue(
            'var(--chakra-colors-blue-600)',
            'var(--chakra-colors-teal-500)'
          )(theme)}`,
          backgroundColor: getThemeStyleValue('primary.50', 'none')(theme),
          border: `${getThemeStyleValue(
            'none',
            '1px solid var(--chakra-colors-teal-900)'
          )(theme)}`,
        },
      },
      filterButton: {
        transition: 'none',
        w: '100%',
        minH: '48px',
        h: 'auto',
        paddingInlineStart: 2,
        paddingInlineEnd: 2,
        backgroundColor: 'white',
        borderRadius: 0,
        boxShadow: 'base',
        justifyContent: 'flex-start',
        fontWeight: 600,
        color: 'primary.800',
        border: '4px solid transparent',
        wordWrap: 'break-all',
        whiteSpace: 'normal',

        _hover: {
          border: '4px solid var(--chakra-colors-blue-600)',
        },

        _focusVisible: {
          backgroundColor: 'blue.50',
          border: '4px solid var(--chakra-colors-blue-600)',
        },

        _active: {
          outline: '5px solid',
          outlineColor: 'blue.100',
          backgroundColor: 'blue.50',
          outlineOffset: '-5px',
          textDecoration: 'none',
        },
      },
      sidebarButton: {
        backgroundColor: 'white',
        borderRadius: '4px',
        color: getThemeStyleValue('primary.800', 'secondary.1000')(theme),
        height: 'auto',
        userSelect: 'none',
        WebkitUserDrag: 'none',

        '&.active': {
          backgroundColor: getThemeStyleValue('primary.50', 'teal.500')(theme),

          _before: {
            position: 'absolute',
            my: '7px',
            top: '0',
            left: '0',
            zIndex: '1',
            borderRadius: '0 4px 4px 0',
            width: '4px',
            height: 'calc(100% - 14px)',
            opacity: getThemeStyleValue('1', '.6')(theme),
            backgroundColor: getThemeStyleValue(
              'primary.400',
              'secondary.1000'
            )(theme),

            content: `''`,
          },

          _hover: {
            backgroundColor: getThemeStyleValue(
              'primary.30',
              'teal.600'
            )(theme),
          },

          _active: {
            textDecoration: 'none',
            backgroundColor: getThemeStyleValue(
              'primary.700',
              'teal.600'
            )(theme),
            color: getThemeStyleValue('white', 'secondary.1000')(theme),
          },
        },

        _hover: {
          backgroundColor: getThemeStyleValue(
            'primary.30',
            'secondary.400'
          )(theme),
        },

        _focusVisible: {
          boxShadow: '0 0 0 4px var(--chakra-colors-blue-600)',
        },

        _active: {
          textDecoration: 'none',
          backgroundColor: getThemeStyleValue(
            'primary.700',
            'secondary.400'
          )(theme),
          color: getThemeStyleValue('white', 'secondary.1000')(theme),
        },
      },
      sidebarDarkButton: {
        backgroundColor: 'primary.700',
        borderRadius: '4px',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        _hover: {
          backgroundColor: 'primary.500',
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        },
        _focusVisible: {
          backgroundColor: 'primary.100',
          boxShadow: '0 0 0 4px var(--chakra-colors-blue-600)',
          textDecoration: 'none',
          borderRadius: 0,
          zIndex: 2,
        },
      },
      sidebarLightButton: {
        backgroundColor: 'primary.30',
        borderRadius: '4px',
        color: 'blue.500',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        _hover: {
          backgroundColor: 'primary.30',
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        },
        _focusVisible: {
          backgroundColor: 'primary.100',
          boxShadow: '0 0 0 4px var(--chakra-colors-blue-600)',
          borderRadius: 0,
          textDecoration: 'none',
          zIndex: 2,
        },
      },
      sidebarDarkSubMenuButton: {
        backgroundColor: 'primary.700',
        borderRadius: 0,
        _hover: {
          backgroundColor: 'primary.500',
          borderRadius: 0,
        },
        _focusVisible: {
          backgroundColor: 'primary.100',
          boxShadow: '0 0 0 4px var(--chakra-colors-blue-600)',
          borderRadius: 0,
          textDecoration: 'none',
          zIndex: 2,
        },
      },
      rlNavigationSolid: {
        color: 'white',
        backgroundColor: 'rgba(0,50,106,0.4)',
        border: '1px solid #fff',
        fontSize: '18px',
        fontWeight: 'bold',
        lineHeight: '18px',
        fontStyle: 'normal',
        borderRadius: 0,

        _hover: {
          backgroundColor: '#00326A',
        },

        _focusVisible: {
          backgroundColor: '#00326A',
          boxShadow: '0 0 0 4px var(--chakra-colors-blue-600)',
          textDecoration: 'none',
        },
      },
      rlSolid: {
        display: 'inline-block',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: 0,
        textTransform: 'uppercase',
        textDecoration: 'none',
        fontSize: '1.125rem',
        fontWeight: 'bold',
        cursor: 'pointer',
        padding: '10px 17px',
        '-webkit-transition': 'all 0.5s ease-out',
        '-o-transition': 'all 0.5s ease-out',
        transition: 'all 0.5s ease-out',
        backgroundColor: '#F2444D',
        borderColor: '#F2444D',
        color: '#FFFFFF',

        '&:hover': {
          textDecoration: 'none',
          backgroundColor: 'rgba(242, 68, 77, 0.5)',
        },
      },
      round: {
        backgroundColor: 'primary.600',
        color: 'white',
        borderRadius: '50px',
        _hover: {
          backgroundColor: 'primary.600',

          _disabled: {
            opacity: 1,
            boxShadow: 'sm',
            backgroundColor: 'basic.400',
            color: 'white',
          },
        },

        _focusVisible: {
          backgroundColor: 'primary.800',
          boxShadow: '0 0 0 4px var(--chakra-colors-blue-600)',
          textDecoration: 'none',
        },
        _active: {
          backgroundColor: 'primary.700',
          color: 'white',
        },
        _disabled: {
          opacity: 1,
          boxShadow: 'sm',
          backgroundColor: 'basic.400',
          color: 'white',
        },
      },
      text: {
        color: 'primary.800',
        p: 0,
        textDecoration: 'underline',
        _hover: {
          color: 'primary.500',
        },
        _focusVisible: {
          boxShadow: 'focus.blue',
          textDecoration: 'none',
        },
      },
      label: {
        color: 'primary.800',
        paddingY: 1,
        paddingX: 3,
        _focusVisible: {
          boxShadow: 'focus.blue',
          textDecoration: 'none',
        },
      },
      inverted: {
        color: 'primary.800',
        _hover: {
          color: 'primary.700',
        },
        _focusVisible: {
          boxShadow: '0 0 0 4px var(--chakra-colors-blue-600)',
          textDecoration: 'none',
        },
        _active: {
          color: 'primary.900',
        },
        _disabled: {
          color: 'basic.700',
        },
      },
      navLink: {
        display: 'flex',
        alignItems: 'center',
        transition: 'background-color var(--global-transition)',
        py: 4,
        color: getThemeStyleValue('white', 'secondary.1000')(theme),
        whiteSpace: 'nowrap',

        _focusVisible: {
          boxShadow: getThemeStyleValue(
            '0 0 0 4px var(--calendar-colors-sky-500)',
            '0 0 0 4px var(--chakra-colors-teal-500)'
          )(theme),
          border: getThemeStyleValue(
            'none',
            '1px solid var(--chakra-colors-secondary-1000)'
          )(theme),
        },
      },
      toggleMenuButton: {
        color: getThemeStyleValue('primary.800', 'white')(theme),

        _focusVisible: {
          boxShadow: getThemeStyleValue(
            '0 0 0 4px var(--calendar-colors-sky-500)',
            '0 0 0 4px var(--chakra-colors-teal-500)'
          )(theme),
          border: getThemeStyleValue(
            '1px solid var(--chakra-colors-primary-800)',
            '1px solid var(--chakra-colors-teal-900)'
          )(theme),
        },
        _hover: {
          color: getThemeStyleValue('#6D80A1', 'white')(theme),
          backgroundColor: getThemeStyleValue(
            'transparent',
            'secondary.800'
          )(theme),
        },
        _active: {
          backgroundColor: getThemeStyleValue(
            'transparent',
            'secondary.700'
          )(theme),
        },
      },
      fullWidthGhost: {
        w: 'full',
        py: '7',
        bg: 'secondary.50',
        boxShadow: 'none',
        color: 'primary.800',
      },
      warningMessage: {
        backgroundColor: '#9C3615',
        color: 'white',
        _hover: {
          backgroundColor: '#BB502F',
        },

        _focusVisible: {
          boxShadow: '0 0 0 4px var(--chakra-colors-blue-600)',
          textDecoration: 'none',
        },
      },
      errorMessage: {
        backgroundColor: 'danger.900',
        color: 'white',
        _hover: {
          backgroundColor: 'danger.800',

          _disabled: {
            backgroundColor: 'danger.900',
          },
        },

        _focusVisible: {
          boxShadow: '0 0 0 4px var(--chakra-colors-blue-600)',
          textDecoration: 'none',
        },
      },
    },
    sizes: {
      sm: {
        minHeight: '32px',
        minWidth: '32px',
        paddingX: 3,
        paddingY: 1.5,
        fontSize: 'sm',
        lineHeight: '5',
        '& svg': {
          width: '18px',
          height: '18px',
        },
      },
      md: {
        minHeight: '44px',
        minWidth: '44px',
        paddingX: 4,
        paddingY: 3,
        fontSize: 'md',
        lineHeight: '5',
      },
      lg: {
        minHeight: '50px',
        minWidth: '50px',
        paddingX: 6,
        paddingY: 3.5,
        fontSize: 'lg',
        lineHeight: '1.375rem',
      },
      xl: {
        minHeight: '56px',
        minWidth: '56px',
        paddingX: 8,
        paddingY: 4,
        fontSize: 'xl',
        lineHeight: '6',
      },
      sidebarSize: {
        minHeight: '38px',
        minWidth: '38px',
        paddingX: '9px',
        fontSize: 'sm',
        lineHeight: 'shorter',
      },
    },
  };
};
