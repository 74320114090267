import { isReviewSite } from '@lon/shared/constants';
import { routes } from '@lon/suit/configs';
import * as Types from './types';

export const teacherLinks: Types.GetLinks = (t) => [
  {
    path: routes.home,
    exact: true,
    text: t('header.home'),
    tooltip: t('header.openDashboardTooltip'),
    tooltipActive: t('header.activeDashboardTooltip'),
    backgroundColor: '#1E4176',
    hoverBgColor: '#1E365F',
    onClick: ({ event, path, location }) => {
      const { pathname } = location;
      if (pathname === path) {
        event.preventDefault();
      }
    },
  },
  {
    path: routes.scopes.root,
    exact: false,
    text: t('header.scopes'),
    tooltip: t('header.openScopesTooltip'),
    tooltipActive: t('header.activeScopesTooltip'),
    backgroundColor: '#04653D',
    hoverBgColor: '#0B462A',
    onClick: ({ event, path, location, params, setParams }) => {
      const { pathname } = location;
      const { pagination, ...rest } = params;
      if (pathname === path) {
        if (pagination?.page > 1) {
          setParams({
            ...rest,
            pagination: { itemsPerPage: pagination.itemsPerPage, page: 1 },
          });
        }
        event.preventDefault();
      }
    },
  },
  {
    path: routes.teacherResources.root,
    exact: false,
    text: t('header.resources'),
    tooltip: t('header.openTeacherResourceTooltip'),
    tooltipActive: t('header.activeTeacherResourceTooltip'),
    backgroundColor: '#225B90',
    hoverBgColor: '#1E486F',
  },
  {
    path: routes.planner.root,
    exact: true,
    text: t('header.planner'),
    tooltip: t('header.openPlannerTooltip'),
    tooltipActive: t('header.activePlannerTooltip'),
    backgroundColor: '#682769',
    hoverBgColor: '#4E174F',
    hide: isReviewSite(),
  },
  {
    path: routes.classes.root,
    text: t('header.classes'),
    tooltip: t('header.openClassesTooltip'),
    tooltipActive: t('header.activeClassesTooltip'),
    backgroundColor: '#A80000',
    hoverBgColor: '#860000',
    hide: isReviewSite(),
  },
  {
    path: routes.additionalResources.root,
    exact: true,
    text: t('header.more'),
    tooltip: t('header.openMoreTooltip'),
    tooltipActive: t('header.activeMoreTooltip'),
    backgroundColor: '#9C3615',
    hoverBgColor: '#7F2B07',
    hide: isReviewSite(),
  },
];

export const campusLeaderLinks: Types.GetLinks = (t) => [
  {
    path: routes.home,
    exact: true,
    text: t('header.home'),
    tooltip: t('header.openDashboardTooltip'),
    tooltipActive: t('header.activeDashboardTooltip'),
    backgroundColor: '#1E4176',
    hoverBgColor: '#1E365F',
    onClick: ({ event, path, location }) => {
      const { pathname } = location;
      if (pathname === path) {
        event.preventDefault();
      }
    },
  },
  {
    path: routes.scopes.root,
    exact: false,
    text: t('header.scopes'),
    tooltip: t('header.openScopesTooltip'),
    tooltipActive: t('header.activeScopesTooltip'),
    backgroundColor: '#04653D',
    hoverBgColor: '#0B462A',
    onClick: ({ event, path, location, params, setParams }) => {
      const { pathname } = location;
      const { pagination, ...rest } = params;
      if (pathname === path) {
        if (pagination?.page > 1) {
          setParams({
            ...rest,
            pagination: { itemsPerPage: pagination.itemsPerPage, page: 1 },
          });
        }
        event.preventDefault();
      }
    },
  },
  {
    path: routes.teacherResources.root,
    exact: false,
    text: t('header.resources'),
    tooltip: t('header.openLeaderResourceTooltip'),
    tooltipActive: t('header.activeTeacherResourceTooltip'),
    backgroundColor: '#225B90',
    hoverBgColor: '#1E486F',
  },
  {
    path: routes.planner.root,
    exact: true,
    text: t('header.planner'),
    tooltip: t('header.openPlannerTooltip'),
    tooltipActive: t('header.activePlannerTooltip'),
    backgroundColor: '#682769',
    hoverBgColor: '#4E174F',
    hide: isReviewSite(),
  },
  {
    path: routes.teachers.root,
    text: t('header.teachers'),
    tooltip: t('header.openTeachersTooltip'),
    tooltipActive: t('header.activeTeachersTooltip'),
    backgroundColor: '#A80000',
    hoverBgColor: '#860000',
    hide: isReviewSite(),
  },
  {
    path: routes.additionalResources.root,
    exact: true,
    text: t('header.more'),
    tooltip: t('header.openMoreTooltip'),
    tooltipActive: t('header.activeMoreTooltip'),
    backgroundColor: '#9C3615',
    hoverBgColor: '#7F2B07',
    hide: isReviewSite(),
  },
];

export const studentLinks: Types.GetLinks = (t) => [
  {
    path: routes.home,
    exact: true,
    text: t('header.home'),
    tooltip: t('header.openDashboardTooltip'),
    tooltipActive: t('header.activeDashboardTooltip'),
    backgroundColor: '#1E4176',
    hoverBgColor: '#1E365F',
  },
  {
    path: routes.notebook.root,
    exact: false,
    text: t('header.notebook'),
    tooltip: t('header.openNotebookTooltip'),
    tooltipActive: t('header.activeNotebookTooltip'),
    backgroundColor: '#04653D',
    hoverBgColor: '#0B462A',
  },
  {
    path: routes.student.resources,
    exact: false,
    text: t('header.resources'),
    tooltip: t('header.openStudentResourceTooltip'),
    tooltipActive: t('header.activeStudentResourceTooltip'),
    backgroundColor: '#225B90',
    hoverBgColor: '#1E486F',
  },
  {
    path: routes.grades.root,
    activePath: routes.grades.class,
    exact: false,
    text: t('header.grades'),
    tooltip: t('header.openGradesTooltip'),
    tooltipActive: t('header.activeGradesTooltip'),
    backgroundColor: '#A80000',
    hoverBgColor: '#860000',
  },
  {
    path: routes.student.playground,
    exact: false,
    text: t('header.playground'),
    tooltip: t('header.openPlaygroundTooltip'),
    tooltipActive: t('header.activePlaygroundTooltip'),
    backgroundColor: '#9C3615',
    hoverBgColor: '#7F2B07',
  },
];
